import { forwardRef } from 'react';
import classNames from 'classnames';
import numeral from 'numeral';

import { IconButton } from '../core/icon-button/IconButton';

import styles from './Count.module.scss';

export type CountProps = {
  /**
   * Optional class name for styling the count
   */
  className?: string;
  /**
   * The number to display
   */
  count: number | string;
  /**
   * Determines if the count should be interactive
   */
  mode?: 'non-interactive' | 'interactive';
  /**
   * The size of the count
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Determines if the count should be hidden from screen readers
   */
  'aria-hidden'?: boolean;
  /**
   * Determines if the count should be disabled
   */
  disabled?: boolean;
  /**
   * Callback function when the count is cleared
   */
  onClear?: () => void;
  /**
   * Controls color inversion, set to true for high contrast in light mode
   */
  reversed?: boolean;
};

const ClearIconButtonSizeMap = {
  small: 'xxxsmall',
  medium: 'xxsmall',
  large: 'xsmall',
} as const;

/**
 * The Count component is used to display a numerical value, often representing quantities or tallies,
 * and can include an interactive element for clearing or adjusting the count.
 */
export const Count = forwardRef<HTMLSpanElement, CountProps>(function Count(props, ref) {
  const {
    count,
    mode = 'non-interactive',
    size = 'large',
    reversed = false,
    disabled,
    onClear,
  } = props;

  const value = Number.isInteger(count) ? numeral(count).format('0,0a') : count;

  return (
    <span
      className={classNames(styles.count, props.className, reversed && styles.reversed)}
      ref={ref}
      data-count-size={size}
      aria-hidden={props['aria-hidden']}
    >
      {value}
      {mode === 'interactive' ? (
        <IconButton
          size={ClearIconButtonSizeMap[size]}
          className={classNames(styles.closeIcon)}
          icon="cross"
          tooltip="Clear"
          aria-label="Clear"
          disabled={disabled}
          onClick={onClear}
        />
      ) : null}
    </span>
  );
});
