import * as Sentry from '@sentry/react';
import { replaceIdsWithParameter } from 'common/utils/urlUtil';

const CLEAR_INTERVAL = 1000 * 60 * 10; // 10 minutes
const REPORT_THRESHOLD = 800;

let initialized = false;
let mapping = new Map<string, number>();

const MAP_API_PATH_TO_THRESHOLD: Record<string, number> = {
  '/opportunities/search': REPORT_THRESHOLD * 2,
};

export function recordApiCall(path: string) {
  if (!initialized) {
    return;
  }

  const pathWithoutQuery = path.split('?')[0]!;
  const normalizedPath = replaceIdsWithParameter(pathWithoutQuery);

  const existingCount = mapping.get(normalizedPath) ?? 0;
  const count = existingCount + 1;

  const reportThreshold = MAP_API_PATH_TO_THRESHOLD[normalizedPath] ?? REPORT_THRESHOLD;

  // Report every time the threshold is reached (800, 1600, 2400, etc.)
  if (count > 0 && count % reportThreshold === 0) {
    const error = new Error(`API calls are being made too frequently to ${normalizedPath}`);
    Sentry.withScope((scope) => {
      scope.setTag('path', normalizedPath);
      scope.setExtra('count', count);
      Sentry.captureException(error);
    });
  }

  mapping.set(normalizedPath, count);
}

export function initializeApiCallTracker() {
  if (initialized) {
    return;
  }

  initialized = true;

  const interval = setInterval(() => {
    mapping = new Map<string, number>();
  }, CLEAR_INTERVAL);

  window.addEventListener('beforeunload', () => {
    clearInterval(interval);
  });
}
