import {
  type ComponentProps,
  Fragment,
  type PropsWithChildren,
  type ReactNode,
  type UIEventHandler,
  useRef,
  useState,
  useCallback,
} from 'react';
import * as Ariakit from '@ariakit/react';
import classnames from 'classnames';

import { ModalHeader } from 'common/components/design-system/modal/components/ModalHeader';
import { ModalFooter } from 'common/components/design-system/modal/components/ModalFooter';
import { ProgressSteps } from 'common/components/design-system/progress-steps/ProgressSteps';
import { TabProvider } from 'common/components/design-system/core/tabs/Tabs';
import { ModalButton } from 'common/components/design-system/modal/components/ModalButton';
import { Button } from 'common/components/design-system/core/button/Button';

import { DialogContentWrapper } from 'common/components/design-system/utils/DialogContentWrapper';
import { extractChildren, getPortalElement } from 'common/components/design-system/utils';

import { apolloWrapperClasses } from 'common/components/ui/ResetWrapper';
import styles from './Modal.module.scss';

export interface ModalProps
  extends Omit<
    Ariakit.DialogProps,
    'modal' | 'backdrop' | 'className' | 'as' | 'getPersistentElements'
  > {
  /**
   * Whether the modal is open
   */
  open?: boolean;
  /**
   * Size of the modal
   * @default medium
   */
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  /**
   * Callback when the modal is closed
   */
  onClose?: () => void;
  /**
   * Store object created by useModal
   */
  store?: Ariakit.DialogStore;
  /**
   * When set to `false`, the content element will stay mounted in DOM when it's hidden.
   *
   * @default true
   */
  unmountOnHide?: boolean;
}

/**
 * Modal component
 *
 * @usage
 * ```jsx
 * <Modal open={true} onClose={() => {}}>
 *   <Modal.Header>
 *     <Modal.Header.Text>Modal header</Modal.Header.Text>
 *     <Modal.Header.Icon icon="info" />
 *     <Modal.Header.Count count={5} />
 *     <Modal.Header.Badge>Badge</Modal.Header.Badge>
 *     <Modal.Header.Info>Info</Modal.Header.Info>
 *     <Modal.Header.Subtext>Subtext</Modal.Header.Subtext>
 *     <Modal.Header.Tabs>
 *       <Tab>Tab 1</Tab>
 *       <Tab>Tab 2</Tab>
 *       <Tab>Tab 3</Tab>
 *       <Tab>Tab 4</Tab>
 *       <Tab>Tab 5</Tab>
 *     </Modal.Header.Tabs>
 *     <Modal.ProgressSteps />
 *   </Modal.Header>
 *   <Modal.Content>Test Modal</Modal.Content>
 *   <Modal.Footer>Footer</Modal.Footer>
 * </Modal>
 * ```
 *
 * @param open
 * @param size
 */
export const Modal = ({
  children,
  size = 'medium',
  ...modalProps
}: PropsWithChildren<ModalProps>) => {
  const { header, progressSteps, content, footer } = extractComponentChildren(children);
  const [contentScrolled, setIsContentScrolled] = useState(false);
  const [initialFocus, setInitialFocus] = useState<HTMLElement | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const onContentScroll: UIEventHandler<HTMLDivElement> = (event) => {
    if (!contentScrolled && (event.target as HTMLElement).scrollTop > 50) {
      setIsContentScrolled(true);
    }
    if (contentScrolled && (event.target as HTMLElement).scrollTop < 50) {
      setIsContentScrolled(false);
    }
  };

  const getModalPortalElement = useCallback((element) => getPortalElement(element, true), []);

  return (
    <TabProvider>
      <Ariakit.Dialog
        ref={modalRef}
        initialFocus={initialFocus}
        autoFocusOnShow={Boolean(initialFocus)}
        portalElement={getModalPortalElement}
        {...modalProps}
        unmountOnHide={modalProps.unmountOnHide ?? true}
        getPersistentElements={() => document.querySelectorAll('[data-disable-focus-trap=true]')}
        modal={true}
        backdrop={<div className={classnames(styles.backdrop, apolloWrapperClasses)} />}
        className={classnames(
          apolloWrapperClasses,
          styles.modal,
          size === 'small' && styles.small,
          size === 'large' && styles.large,
          size === 'xlarge' && styles.xLarge,
        )}
      >
        <DialogContentWrapper ref={modalRef} setInitialFocus={setInitialFocus}>
          <div className={classnames(contentScrolled && styles.shadow)}>{header}</div>
          <div onScroll={onContentScroll} className={styles.content} tabIndex={-1}>
            {progressSteps}
            {content}
          </div>
          {footer}
        </DialogContentWrapper>
      </Ariakit.Dialog>
    </TabProvider>
  );
};

Modal.Header = ModalHeader;
Modal.ProgressSteps = ProgressSteps;
Modal.Content = Fragment;
Modal.Footer = ModalFooter;

function extractComponentChildren(children: ReactNode) {
  return extractChildren(children, {
    header: Modal.Header,
    progressSteps: Modal.ProgressSteps,
    content: Modal.Content,
    footer: Modal.Footer,
  });
}

// Alias APIs from Ariakit
export const useModal = Ariakit.useDialogStore;
export const useModalContext = Ariakit.useDialogContext;

/**
 * ModalProvider only works with DialogDisclosure. This wrapper allows the use of Button instead. ModalButton is a
 * DialogDisclosure rendered as a Button.
 */
export const ModalProvider = ({
  children,
  ...props
}: PropsWithChildren<ComponentProps<typeof Ariakit.DialogProvider>>) => {
  const { modal, button } = extractChildren(children, {
    button: Button,
    modal: Modal,
  });

  return (
    <Ariakit.DialogProvider {...props}>
      <ModalButton {...button?.props} />
      {modal}
    </Ariakit.DialogProvider>
  );
};
