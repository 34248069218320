import type { BadgeVariantTypeLegacy, BadgeVariantTypeRedesigned, BadgeVariantType } from './types';

const VARIANT_MAP = Object.freeze({
  primary: 'ocean',
  positive: 'evergreen',
  caution: 'ember',
  negative: 'rust',
  info: 'tropic',
  secondary: 'pebble',
  tertiary: 'sakura',
  quaternary: 'iris',
  quinary: 'moss',
  neutral: 'sand',
  dark: 'sand-inverse',
} satisfies Record<BadgeVariantTypeLegacy, BadgeVariantTypeRedesigned>);

// Transform old (primary, secondary) to redesigned (ocean, evergreen) variant names.
export function resolveVariant(variant?: BadgeVariantType): BadgeVariantTypeRedesigned | undefined {
  // TODO: Remove support for legacy variants after App Redesign is launched
  if (variant && variant in VARIANT_MAP) {
    return VARIANT_MAP[variant as BadgeVariantTypeLegacy];
  }
  return variant as BadgeVariantTypeRedesigned | undefined;
}
