// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_iR5wt{display:flex;justify-content:center;z-index:1000;height:100vh;overflow-y:auto}.zp_eBz09{position:fixed;top:0;right:0;bottom:0;left:0;overflow:hidden;opacity:.5;background:#242d3e}.zp_t0qbM{display:flex;flex-direction:column;gap:20px;max-width:-moz-max-content;max-width:max-content;width:auto;max-width:443px;height:732px;margin:auto;padding:24px;border-radius:6px;background:var(--color-base-sand-10);z-index:2;transition:all .3s ease}@media only screen and (max-width: 768px){.zp_t0qbM{width:380px}}@media only screen and (max-width: 420px){.zp_t0qbM{width:100%;height:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widgetModal": "zp_iR5wt",
	"widgetModalOverlay": "zp_eBz09",
	"widgetModalContent": "zp_t0qbM"
};
export default ___CSS_LOADER_EXPORT___;
