import type { CalendarDataType } from 'app/containers/meetings/meetings.types';
import { getUsersCurrentTimeZone } from 'app/utils/TimeUtils';
import React, { createContext, useContext, useReducer, type ReactNode, useMemo } from 'react';
import type { WidgetDisplayBlockProps } from './components/WidgetDisplayBlock';
import type { MeetingTypeDetailsType } from './meetings-widget.types';

const allUiTypes = ['book-meeting', 'custom-message', 'error', null] as const;
const allBookingInfoStatusTypes = ['idle', 'error', 'success'] as const;

type WidgetState = {
  // widget state
  uiType: (typeof allUiTypes)[number];
  customMessage: WidgetDisplayBlockProps | null;
  meetingType: MeetingTypeDetailsType | null;
  routerLoading: boolean;

  // calendar state
  calendarData: CalendarDataType | null;
  calendarLoading: boolean;
  selectedBooking: {
    date: string | null;
    startTime: number | null;
    endTime: number | null;
    timeZone: string | null;
  };
  bookingInfo: {
    status: (typeof allBookingInfoStatusTypes)[number];
  };
};

type WidgetAction =
  // widget actions
  | {
      type: 'CLOSE_WIDGET';
    }
  | {
      type: 'ROUTER_SUBMIT_START';
    }
  | {
      type: 'ROUTER_SUBMIT_ERROR';
      payload: {
        data: WidgetDisplayBlockProps;
      };
    }
  | {
      type: 'ROUTER_SUBMIT_SUCCESS';
      payload:
        | {
            uiType: (typeof allUiTypes)['0'];
            data: WidgetState['meetingType'];
          }
        | {
            uiType: (typeof allUiTypes)['1'] | (typeof allUiTypes)['2'];
            data: WidgetState['customMessage'];
          }
        | {
            uiType: (typeof allUiTypes)['3'];
            data?: never;
          };
    }
  // calendar actions
  | {
      type: 'FETCH_MEETING_DATA_ERROR';
      payload: {
        data: WidgetDisplayBlockProps;
      };
    }
  | {
      type: 'FETCH_MEETING_DATA_SUCCESS';
      payload: {
        data: CalendarDataType;
      };
    }
  | {
      type: 'SET_CALENDAR_LOADING';
      payload: boolean;
    }
  | {
      type: 'UPDATE_SELECTED_BOOKING';
      payload: Partial<WidgetState['selectedBooking']>;
    }
  | {
      type: 'UPDATE_BOOKING_INFO';
      payload:
        | {
            status:
              | (typeof allBookingInfoStatusTypes)['0']
              | (typeof allBookingInfoStatusTypes)['2'];
            data?: never;
          }
        | {
            status: (typeof allBookingInfoStatusTypes)['1'];
            data: WidgetState['customMessage'];
          };
    };

const initialState: WidgetState = {
  uiType: null,
  customMessage: null,
  meetingType: null,
  routerLoading: false,

  calendarData: null,
  calendarLoading: true,
  selectedBooking: {
    date: null,
    startTime: null,
    endTime: null,
    timeZone: getUsersCurrentTimeZone(),
  },
  bookingInfo: {
    status: 'idle',
  },
};

const widgetReducer = (state: WidgetState, action: WidgetAction): WidgetState => {
  switch (action.type) {
    // widget actions
    case 'CLOSE_WIDGET':
      return initialState;

    case 'ROUTER_SUBMIT_START': {
      return {
        ...state,
        routerLoading: true,
      };
    }

    case 'ROUTER_SUBMIT_ERROR':
      return {
        ...state,
        uiType: 'custom-message',
        customMessage: action.payload.data,
        routerLoading: false,
      };

    case 'ROUTER_SUBMIT_SUCCESS': {
      const newUiType = action.payload.uiType;
      let data: Partial<WidgetState> = {};

      if (newUiType === 'custom-message' || newUiType === 'error') {
        data = {
          customMessage: action.payload.data,
        };
      }

      if (newUiType === 'book-meeting') {
        data = {
          meetingType: action.payload.data,
        };
      }

      return {
        ...state,
        uiType: newUiType,
        ...data,
        routerLoading: false,
      };
    }

    // calendar actions
    case 'FETCH_MEETING_DATA_ERROR':
      return {
        ...state,
        calendarLoading: false,
        customMessage: action.payload.data,
      };

    case 'FETCH_MEETING_DATA_SUCCESS':
      return {
        ...state,
        calendarLoading: false,
        calendarData: action.payload.data,
      };

    case 'SET_CALENDAR_LOADING': {
      return {
        ...state,
        calendarLoading: action.payload,
      };
    }

    case 'UPDATE_SELECTED_BOOKING': {
      return {
        ...state,
        selectedBooking: {
          ...state.selectedBooking,
          ...action.payload,
        },
      };
    }

    case 'UPDATE_BOOKING_INFO': {
      let data: Partial<WidgetState> = {};

      if (action.payload.status === 'error') {
        data = { customMessage: action.payload.data, uiType: 'custom-message' };
      }

      return {
        ...state,
        ...data,
        bookingInfo: {
          ...state.bookingInfo,
          status: action.payload.status,
        },
      };
    }

    default:
      throw new Error('Invalid action invoked');
  }
};

const WidgetContext = createContext<
  { state: WidgetState; dispatch: React.Dispatch<WidgetAction> } | undefined
>(undefined);

export const WidgetProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(widgetReducer, initialState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>;
};

export const useWidget = () => {
  const context = useContext(WidgetContext);
  if (!context) {
    throw new Error('useWidget must be used within a WidgetProvider');
  }
  return context;
};
