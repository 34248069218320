import { isExtensionServiceWorker } from 'chrome-extension/inject/core/lib/utils';
import qs from 'qs';

// ! IMPORTANT: make sure this is null before pushing
const EXTENSION_GOD_EMAIL = null;

export function getQueryStringParams() {
  let queryString = (!isExtensionServiceWorker && window.location.search) || '';
  queryString = queryString.replace('?', '');
  return qs.parse(queryString);
}

export function getGodEmail() {
  const params = getQueryStringParams();
  return EXTENSION_GOD_EMAIL || params.godemail;
}

export function isGodMode() {
  const params = getQueryStringParams();
  if (EXTENSION_GOD_EMAIL || 'godemail' in params) {
    return true;
  }
  return false;
}

export function isProductionBackend() {
  const isApolloHost =
    window.location.hostname === 'app.apollo.io' ||
    window.location.hostname.endsWith('.prod-gcp.apollo.io');
  if (isApolloHost) {
    return true;
  }

  if (process.env.IS_DEVELOPMENT) {
    // Used only on local environment when using `npm run proxy:backend`
    // The script will send this header in response of /auth/check endpoint
    return window.__PROXY_HOST__ === 'app.apollo.io';
  }

  return false;
}

export function transformAPIRequestDataForGodMode(data = {}) {
  data = data || {};
  if (isGodMode()) {
    data.godemail = getGodEmail();
  }
  return data;
}

/**
 * Returns the current URL with godemail removed
 * God mode URLs look like this:
 * https://app.apollo.io/?godemail=john%40doe.com#/settings
 *
 *
 */
export function getCurrentUrlWithoutGodMode(): string {
  const url = window.location.href;
  const queryParams = getQueryStringParams();

  if (!queryParams.godemail) {
    return url;
  }

  // The godemail query string that we'll remove from the URL
  const godemailQueryString = qs.stringify({ godemail: queryParams.godemail });

  // If godemail is the only param, we can remove the "?" too
  const hasOtherQueryParams = Object.keys(queryParams).length > 1;
  const stringToReplace = hasOtherQueryParams ? godemailQueryString : `?${godemailQueryString}`;

  return url.replace(stringToReplace, '');
}
