import { format } from 'date-fns';
import type {
  MeetingsWidgetFormResponseType,
  MeetingsWidgetOptionsType,
} from './meetings-widget.types';

export const meetingsWidgetSubmitOptions: MeetingsWidgetOptionsType = {};

export const emailRegex = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g;

const convertTo24hFormat = (time: number): string => {
  const [hours, minutes] = [Math.floor(time / 100), time % 100];
  const date = new Date(0, 0, 0, hours, minutes);

  return format(date, 'HH:mm a');
};

export const getWidgetDisplayTime = (
  date: string | null,
  start: number | null,
  end: number | null,
) => {
  const startTime = convertTo24hFormat(start ?? 0);
  const endTime = convertTo24hFormat(end ?? 0);
  let displayDate = '';

  if (date) {
    displayDate = format(new Date(date), 'EEEE, MMMM d, yyyy');
  }

  return `${startTime} to ${endTime} \n ${displayDate}`;
};

export const validateEmptyString = (val: unknown, error: string) => {
  if (typeof val !== 'string' || (typeof val === 'string' && !val.length)) {
    throw new Error(error);
  }
};

export const handleWidgetClose = () => {
  if (typeof window !== 'undefined' && 'ApolloMeetings' in window) {
    const apolloMeetings = window.ApolloMeetings;
    if (apolloMeetings && typeof apolloMeetings.closeWidget === 'function') {
      apolloMeetings.closeWidget();
    }
  }
};

export const getFormValuesFromElement = (
  formId: string | undefined,
): MeetingsWidgetFormResponseType[] => {
  let formElement = null;
  const formResponses: MeetingsWidgetFormResponseType[] = [];

  if (formId) {
    formElement = document.getElementById(formId);

    if (!formElement) {
      throw new Error(`Form element with given id - ${formId}, does not exist.`);
    }

    if (!(formElement instanceof HTMLFormElement)) {
      throw new Error(`Element with id - ${formId} is not a form element.'`);
    }
  } else {
    // no form id
    formElement = document.querySelector('form');

    if (!formElement) {
      throw new Error('No form element exists on this page.');
    }
  }

  const formData = new FormData(formElement);

  formData.forEach((value, name) => {
    if (typeof value !== 'string' && typeof value !== 'number') {
      throw new Error('Only "string" and "number" type values are allowed.');
    }

    formResponses.push({ mapId: name, answer: value });
  });

  return formResponses;
};

export const getFormValuesFromLeadObject = (
  lead: MeetingsWidgetOptionsType['lead'] | undefined,
): MeetingsWidgetFormResponseType[] => {
  const formResponses: MeetingsWidgetFormResponseType[] = [];

  if (!lead || typeof lead !== 'object') {
    throw new Error(
      'Please provide a "lead" object with fields setup in your router\'s form mapping.',
    );
  }

  Object.entries(lead).map(([key, value]) => {
    if (typeof value !== 'string' && typeof value !== 'number') {
      throw new Error('Only "string" and "number" type values are allowed.');
    }
    formResponses.push({ mapId: key, answer: value });
  });

  return formResponses;
};

export const createInjectableDom = (id: string) => {
  const divElement = document.createElement('div');
  divElement.id = id;
  divElement.style.cssText = `
    display: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    inset: 0;
    z-index: 999;
  `;
  document.body.appendChild(divElement);

  return divElement;
};

export const defaultMeetingsWidgetSubmitOptions: MeetingsWidgetOptionsType = {
  closeOnOutside: true,
  map: true,
  formId: undefined,
  lead: null,
  preventRedirect: false,
  onError: () => {},
  onRouted: () => {},
  onSuccess: () => {},
  onRedirect: () => {},
};
