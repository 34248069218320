import { keyPathMirror as keyMirror } from 'key-path-mirror';

export default keyMirror({
  DISPATCH_ACTION: null,

  SEGMENT_ANALYTICS_IDENTIFY: null,
  SEGMENT_ANALYTICS_TRACK: null,
  SEGMENT_ANALYTICS_SET_OPT_OUT: null,

  FETCH_IN_BACKGROUND_PAGE: null,

  SET_AMPLITUDE_USER_PROPERTIES: null,
});
