// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_gWdVo{width:8px;height:8px;border-radius:50%;flex:0 0 auto;background:var(--color-interactives-badge-ocean-dot)}.zp_gWdVo.zp_Qizts{background:var(--color-interactives-badge-ocean-dot)}.zp_gWdVo.zp_ZfSDq{background:var(--color-interactives-badge-evergreen-dot)}.zp_gWdVo.zp_n6aSL{background:var(--color-interactives-badge-ember-dot)}.zp_gWdVo.zp_TlyRz{background:var(--color-interactives-badge-rust-dot)}.zp_gWdVo.zp_UvFek{background:var(--color-interactives-badge-tropic-dot)}.zp_gWdVo.zp_alF6o{background:var(--color-interactives-badge-pebble-dot)}.zp_gWdVo.zp_JNh6y{background:var(--color-interactives-badge-sakura-dot)}.zp_gWdVo.zp_GU4FO{background:var(--color-interactives-badge-iris-dot)}.zp_gWdVo.zp_T6jhO{background:var(--color-interactives-badge-moss-dot)}.zp_gWdVo.zp_Yhxr0{background:var(--color-interactives-badge-sand-dot)}.zp_gWdVo.zp_sMG2J{background:var(--color-interactives-badge-sand-inverse-dot)}.zp_gWdVo.zp_f4s1S{width:4px;height:4px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeDot": "zp_gWdVo",
	"oceanDot": "zp_Qizts",
	"evergreenDot": "zp_ZfSDq",
	"emberDot": "zp_n6aSL",
	"rustDot": "zp_TlyRz",
	"tropicDot": "zp_UvFek",
	"pebbleDot": "zp_alF6o",
	"sakuraDot": "zp_JNh6y",
	"irisDot": "zp_GU4FO",
	"mossDot": "zp_T6jhO",
	"sandDot": "zp_Yhxr0",
	"sand-inverseDot": "zp_sMG2J",
	"xsmallSize": "zp_f4s1S"
};
export default ___CSS_LOADER_EXPORT___;
