import type { AnchorHTMLAttributes } from 'react';
import { startCase, camelCase, last } from 'lodash-es';
import { getZpAppUrl } from 'common/utils/urlUtil';
import { isZp } from 'app/utils/TeamUtils';
import { isExtensionServiceWorker } from 'chrome-extension/inject/core/lib/utils';
import { cypressMockHtmlUrls } from 'chrome-extension/inject/core/lib/extensionCypressUtils';

import type { User } from '$types/entities/user';
import type { Team } from '$types/entities/team';
import { SITE } from 'chrome-extension/constants';

const isKeyOfCypressMockHtmlUrls = (url: string): url is keyof typeof cypressMockHtmlUrls => {
  return url in cypressMockHtmlUrls;
};

const localStorageKeyMapping = {
  linkedin: 'updated_Linkedin_url',
  salesforce: 'updated_salesforce_url',
  hubspot: 'updated_hubspot_url',
};

export function getCurrentUrl() {
  /** example: https://localhost:4001/cypress/fixtures/linkedin/regular_linkedin_profile.html <-- */

  let url = (!isExtensionServiceWorker && window.location.href) || '';

  const iframeSupportArray = Object.keys(localStorageKeyMapping);
  const surface = iframeSupportArray.find((subtext) => url.includes(subtext));
  if (url.includes('chrome-extension://') && surface) {
    const urlParams = new URLSearchParams(url.split('?')[1]);
    const urlValue = urlParams.get('url') ?? '';
    if (surface in localStorageKeyMapping) {
      const key = localStorageKeyMapping[surface as keyof typeof localStorageKeyMapping];
      url = localStorage.getItem(key) ?? urlValue;
    }
  }

  if (url.endsWith('/')) {
    url = url.slice(0, url.length - 1);
  }
  const urlArr = url.split('/');
  const urlLastPart = urlArr[urlArr.length - 1]?.replace(/\?.*$/, '') ?? ''; // remove query params
  return isKeyOfCypressMockHtmlUrls(urlLastPart) ? cypressMockHtmlUrls[urlLastPart] : url;
}

export function matchDomain(domain: string, url = getCurrentUrl(), matchSubDomain = false) {
  if (matchSubDomain) {
    return url.match(new RegExp('^https?://([^/]*.)?' + domain));
  } else {
    return url.match(new RegExp('^https?://' + domain));
  }
}

export function getCurrentSite(url = getCurrentUrl()): string {
  if (matchDomain('mail.google.com', url) ?? url.includes('sidebar-main')) {
    return SITE.GMAIL;
  } else if (matchDomain('linkedin.com', url, true) ?? url.includes('linkedin-sidebar')) {
    return SITE.LINKEDIN;
  } else if (
    !matchDomain('www.salesforce.com', url) && // This is the homepage, we should show Apollo Everywhere here
    (matchDomain('salesforce.com', url, true) ??
      matchDomain('lightning.force.com', url, true) ??
      url.includes('salesforce-sidebar'))
  ) {
    return SITE.SALESFORCE;
  } else if (
    !matchDomain('www.hubspot.com', url) && // Homepage for Apollo Everywhere
    (matchDomain('hubspot.com', url, true) ?? url.includes('hubspot-sidebar'))
  ) {
    return SITE.HUBSPOT;
  } else if (matchDomain('calendar.google.com/calendar/', url) ?? url.includes('gCal-sidebar')) {
    return SITE.GOOGLE_CALENDAR;
  } else if (matchDomain('www.owler.com', url)) {
    return SITE.OWLER;
  } else if (
    matchDomain('zenprospect.com', url, true) ??
    matchDomain('apollo.io', url, true) ??
    matchDomain('localhost', url) ??
    matchDomain('127.0.0.1', url) ??
    matchDomain('azurezp.com', url, true) ??
    matchDomain('apollogcp.com', url, true)
  ) {
    return SITE.APOLLO;
  } else if (url.startsWith('chrome://')) {
    // This means tab is chrome related tab like settings, new tab, extension or etc.
    return SITE.CHROME;
  }

  return SITE.APOLLO_EVERYWHERE;
}

export const convertToTitleCase = (name: string) => startCase(camelCase(name)); // converts 'apollo_everywhere' to 'Apollo Everywhere'

export function getCurrentExtensionSource(): string {
  return convertToTitleCase(getCurrentSite());
}

/**
 *
 */
export function isLinkedin(): boolean {
  return getCurrentSite() === 'linkedin';
}

export function isLoggedInToLinkedin() {
  const signInBtn = document.querySelector('a[href*="https://www.linkedin.com/login"]');
  const signInBtnTopics = document.querySelector('a[href*="https://www.linkedin.com/uas/login"]');
  const signInBtnLearning = document.querySelector(
    'a[href*="https://www.linkedin.com/learning-login"]',
  );
  const signInForm = document.querySelector('.authwall-sign-in-form');
  const salesNavSignInForm = document.querySelector('form.login__form');
  const signUpForm = document.querySelector('section.join-form__form-body');

  return !(
    signInForm ??
    signInBtn ??
    signInBtnTopics ??
    signInBtnLearning ??
    salesNavSignInForm ??
    signUpForm
  );
}

/**
 *
 */
export function isGmail(): boolean {
  return getCurrentSite() === 'gmail';
}

/**
 *
 */
export function isApolloEverywhere(): boolean {
  return getCurrentSite() === 'apollo_everywhere';
}

/**
 *
 */
export function isGoogleCalendar(): boolean {
  return getCurrentSite() === 'google_calendar';
}

/**
 *
 */
export function isSalesforce(): boolean {
  return getCurrentSite() === 'salesforce';
}

/**
 *
 */
export function isHubspot(): boolean {
  return getCurrentSite() === 'hubspot';
}

/**
 *
 */
export function getSiteSource(): string | null {
  if (isLinkedin()) {
    return 'chrome_extension_linkedin';
  } else if (isApolloEverywhere()) {
    return 'chrome_extension_everywhere';
  } else if (isGoogleCalendar()) {
    return 'chrome_extension_calendar';
  } else if (isGmail()) {
    return 'chrome_extension_email';
  }

  return null;
}

/**
 *
 */
export function isCalendarAddon(url = getCurrentUrl()): boolean {
  const requiredStrings = ['calendar.google.com/calendar/', 'origin=', 'mail.google.com'];
  return requiredStrings.every((requiredString) => url.includes(requiredString));
}

/**
 * Returns true if running in Cypress.
 *
 */
export function isCypressTestMode(): boolean {
  return Boolean(window.Cypress);
}

export function isWindowMode(location = window.location) {
  try {
    const params = new URL(location.href).searchParams;
    const windowMode = params.get('window_mode');
    return windowMode === '1';
  } catch (e) {
    return false;
  }
}

export function isExtensionBeta(location = window.location) {
  try {
    const params = new URL(location.href).searchParams;
    const beta = params.get('extension_beta');
    return beta === '1';
  } catch (e) {
    return false;
  }
}

export function getExtensionKey(location = window.location) {
  try {
    const params = new URL(location.href).searchParams;
    return params.get('extension_key');
  } catch (e) {
    return null;
  }
}

export function getExtensionParams(location = window.location) {
  let params = 'window_mode=1';
  const key = getExtensionKey(location);
  if (key) {
    params += `&extension_key=${key}`;
  }
  if (isExtensionBeta(location)) {
    params += '&extension_beta=1';
  }
  return params;
}

export type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & { to?: string };
type PickedLinkProps = Pick<LinkProps, 'href' | 'target' | 'to'>;

/**
 * Returns the appropriate link props for window/non-window mode.
 * We want to have absolute links in window mode (extension) and relative links in non-window mode.
 * Also, the links should open in a new tab on window mode.
 *
 * @param link
 *
 */
export function getLinkProps(link: string): LinkProps {
  const windowMode = isWindowMode();
  const linkProps: LinkProps = {};
  if (windowMode || process.env.IS_EXTENSION) {
    linkProps.href = getZpAppUrl(link);
    linkProps.target = '_blank';
    // noopener is not a concern here as the links are limited to our app.
  } else {
    linkProps.to = link;
  }
  return linkProps;
}

/**
 * Returns link props for our design system components such as Link and Button
 * @param link
 * @returns {PickedLinkProps}
 */
export function getPickedLinkProps(link: string): PickedLinkProps {
  const { href, target, to } = getLinkProps(link);
  return {
    href,
    target,
    to,
  };
}

export const shouldShowNewCalendarUX = (
  currentTeam: Team,
  splitTestActive: boolean,
  isBetaUser: boolean,
) => {
  const isCurrentTeamZp = isZp(currentTeam);
  return isGoogleCalendar() && (splitTestActive || isCurrentTeamZp || isBetaUser);
};

export const getBetaTestGroup = (
  currentUser: User,
  currentTeam: Team,
  splitTestActive: boolean,
  isBetaUser: boolean,
) => {
  const isCurrentTeamZp = isZp(currentTeam);
  // TODO: Move Beta users constant to the backend
  if (isBetaUser) {
    return 'GCalExtensionBeta';
  } else if (isCurrentTeamZp) {
    return 'GCalExtensionInternal';
  } else if (splitTestActive) {
    return 'GCalExtensionTest';
  } else {
    return 'GCalExtensionControl';
  }
};

export const getDomain = (email: string) => {
  if (!email) {
    return null;
  }
  return last(email.split('@'));
};

export const isInternalMeeting = (guests: { email: string }[], currentUser: User) => {
  return guests.every((guest) => getDomain(guest.email) === getDomain(currentUser.email));
};

export const isSameCompanyAsCurrentUser = (currentUserEmail: string, otherUserEmail: string) => {
  return getDomain(currentUserEmail) === getDomain(otherUserEmail);
};

export const isElementInIframe = () => {
  return window !== window.parent;
};

export const isAutoOpenEnabled = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('autoOpenApollo') === 'true';
};

export const MESSAGE_TOOLBAR_SHADOW_ID = 'message-toolbar-shadow';
export const MESSAGE_VIEW_TOOLBAR_ID = 'message-view-toolbar';
export const LINKEDIN_INJECTED_ELEMENTS_BULK = 'linkedin-injected-elements-bulk';

export const getShadowElementParent = (): HTMLElement | undefined => {
  return (
    document
      .querySelector(`[id^="${MESSAGE_TOOLBAR_SHADOW_ID}"]`)
      ?.shadowRoot?.querySelector('div') ??
    document.getElementById(MESSAGE_VIEW_TOOLBAR_ID)?.shadowRoot?.querySelector('div') ??
    document.getElementById(LINKEDIN_INJECTED_ELEMENTS_BULK)?.shadowRoot?.querySelector('div')
  );
};
