import { createContext, forwardRef, useContext, useRef, type PropsWithChildren } from 'react';
import {
  mergeProps,
  useToggleButton,
  type AriaToggleButtonProps,
  usePress,
  useFocusRing,
} from 'react-aria';
import { useToggleState } from 'react-stately';
import classNames from 'classnames';

// Styles
import styles from './PaginationDots.module.scss';

type CommonProps<T> = T & {
  /**
   * The unique identifier for the dot
   *
   */
  id?: string;
  /**
   * The value of the dots - active step
   *
   */
  value: string;
  /**
   * Determines if the dots are disabled
   *
   */
  isDisabled?: boolean;
};

type CommonData = CommonProps<{
  onChange: (value: string) => void;
}>;

export type PaginationDotProps = CommonProps<Required<Pick<AriaToggleButtonProps, 'aria-label'>>>;

export type PaginationDotsProps = { className?: string } & PropsWithChildren<CommonData>;

const PaginationDotContext = createContext<CommonData | null>(null);

export const PaginationDot = forwardRef<HTMLButtonElement, PaginationDotProps>(
  function PaginationDotBase(props, _) {
    const {
      onChange,
      isDisabled: rootDisabled,
      value: rootValue,
    } = useContext(PaginationDotContext)!;

    const { isDisabled, value } = props;

    const state = useToggleState({
      ...props,
      isSelected: rootValue === value,
      onChange: () => onChange(value),
    });
    const buttonRef = useRef(null);

    const { buttonProps } = useToggleButton(
      {
        ...props,
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        isDisabled: rootDisabled || isDisabled,
      },
      state,
      buttonRef,
    );

    const { isFocusVisible, focusProps } = useFocusRing(buttonProps);
    const { pressProps, isPressed } = usePress({});

    return (
      <button
        className={styles.dot}
        type="button"
        ref={buttonRef}
        data-pressed={isPressed}
        data-focus-visible={isFocusVisible}
        {...mergeProps(buttonProps, focusProps, pressProps)}
      />
    );
  },
);
/**
 * The PaginationDots indicator displays as a dot placed at the bottom of the content, showing the position of the current screen in a list of screens.
 * This visual indicator helps users understand their progress through a series of content and provides a simple mechanism to navigate directly to a specific page.
 */
export const PaginationDots = forwardRef<HTMLDivElement, PaginationDotsProps>(
  function PaginationDotGroupBase(props, ref) {
    const { children, ...state } = props;

    return (
      <div
        ref={ref}
        className={classNames([styles.paginationDots, styles.group, props.className])}
        role="group"
      >
        <PaginationDotContext.Provider value={state}>{children}</PaginationDotContext.Provider>
      </div>
    );
  },
);
