import React, { Component, type ComponentProps, type MouseEvent } from 'react';
import classnames from 'classnames';
import { type IndexLinkProps, Link as ReactRouterLink } from 'react-router';
import styles from 'common/components/ui/Link.module.scss';
import { concatPaths, getZpAppUrl } from 'common/utils/urlUtil';
import TooltipOverlayTrigger from 'common/components/ui/TooltipOverlayTrigger';

import { LinkPrefixContext } from 'app/context/LinkPrefixContext';

/* 20180503JP
  For the most part, this component just passes props to react-router's Link.
  However, we do some fancy footwork with the context in order to generate nested
  urls, e.g. in the case of task execution mode where we actually want links to
  point to things like /tasks/:taskId/accounts/:accountId instead of just the
  root /accounts/:accountId, even though our `to` attribute specifies the root.
  We handle this case by injecting a linkPrefix through context and prepending it
  to the specified `to` attribute.
  See app/components/task-execution/TaskExecutionWrapper.jsx

  There are several cases where router.push() is used to navigate programmatically
  rather than using a <Link /> element. In order for these to work properly when
  nested in task execution mode, you should use navigateTo() and pass the context.
  See common/utils/urlUtil.js.
*/

type LinkCoreProps = Partial<IndexLinkProps> & {
  muted?: boolean;
  title?: string;
  action?: unknown;
  to?: string;
  onMouseUp?: (event: MouseEvent<HTMLAnchorElement>) => void;
  href?: string | null;
  target?: string | null;
  rel?: string | null;
  openInNewTab?: boolean;
  hover?: boolean;
  active?: boolean;
  onlyActiveOnIndex?: boolean;
  stopPropagation?: boolean;
  className?: string;
  red?: boolean;
  disabled?: boolean;
  linkRef?: React.LegacyRef<HTMLAnchorElement>;
  disableHoverStyles?: boolean;
};

class LinkCore extends Component<LinkCoreProps> {
  static contextType = LinkPrefixContext;

  static defaultProps = {
    stopPropagation: false,
  };

  render() {
    const { linkPrefix } = this.context;
    const {
      muted,
      title,
      action,
      activeClassName,
      onlyActiveOnIndex,

      onClick,
      // This is for links that go outside of ZP/Apollo (as opposed to to)
      onMouseUp,
      // This is for links that go outside of ZP/Apollo (as opposed to to)
      href,
      target,
      // This prop forcefully opens the internal link in a new tab
      openInNewTab,

      hover,
      active,

      stopPropagation,

      className,
      red,
      disabled,

      linkRef,
      disableHoverStyles,

      ...rest
    } = this.props;

    let {
      // This is for internal app links (as opposed to href)
      to,
    } = this.props;

    const classes = classnames(
      'zp-link',
      styles.default,

      muted && styles.muted,
      title && styles.title,
      action && styles.action,

      hover && styles.hover,
      active && styles.active,
      red && styles.red,
      disabled && styles.disabled,
      disableHoverStyles && styles.noHoverStyles,
      className,
    );

    if (to && !target) {
      to = concatPaths(linkPrefix, to);
    }

    const internalLinkProps = {
      to,
      href,
      target,
      onClick,
      onMouseUp,
      rel: rest.rel,
    };

    const originalOnMouseUp = internalLinkProps.onMouseUp;
    internalLinkProps.onMouseUp = (e) => {
      e.stopPropagation();
      if (originalOnMouseUp) {
        originalOnMouseUp(e);
      }
    };

    if ((process.env.IS_EXTENSION || openInNewTab) && (to || href)) {
      internalLinkProps.to = null;
      internalLinkProps.href = href || getZpAppUrl(to);
      internalLinkProps.target = '_blank';
      internalLinkProps.rel = 'noopener';
    }

    // 20180501JP: Hack alert - this might break if we upgrade react-router.
    if (stopPropagation) {
      const originalOnClick = internalLinkProps.onClick;
      internalLinkProps.onClick = (e) => {
        e.stopPropagation();
        if (originalOnClick) {
          originalOnClick(e);
        }
      };
    }

    // ! Note: internalLinkProps are overridden above for all links on the extension
    if (internalLinkProps.to && !this.props.disabled) {
      return (
        <ReactRouterLink
          activeClassName={activeClassName}
          onlyActiveOnIndex={onlyActiveOnIndex}
          className={classes}
          {...rest}
          {...internalLinkProps}
          innerRef={linkRef}
        />
      );
    }

    if (this.props.disabled) {
      internalLinkProps.to = null;
      // internalLinkProps.onClick = null;
      internalLinkProps.href = null;
      internalLinkProps.target = null;
      internalLinkProps.onClick = (e: { stopPropagation: () => void }) => {
        e.stopPropagation();
      };
    }

    // ! Note: internalLinkProps are overridden above for all links on the extension
    return <a className={classes} {...internalLinkProps} {...rest} ref={linkRef} />;
  }
}

type LinkProps = Partial<IndexLinkProps> & {
  disableHoverStyles?: boolean;
  tip?: ComponentProps<typeof TooltipOverlayTrigger>['tooltipContent'];
  tooltipPlacement?: ComponentProps<typeof TooltipOverlayTrigger>['placement'];
  tooltipArrow?: ComponentProps<typeof TooltipOverlayTrigger>['withArrow'];
  tooltipDelayShow?: ComponentProps<typeof TooltipOverlayTrigger>['delayShow'];
  tooltipInteractive?: ComponentProps<typeof TooltipOverlayTrigger>['interactive'];
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  openInNewTab?: boolean;
  emphasized?: boolean;
} & Pick<ComponentProps<typeof TooltipOverlayTrigger>, 'tooltipNoWrap'>;

export default class Link extends Component<LinkProps & LinkCoreProps> {
  static defaultProps = {
    tip: null,
    tooltipPlacement: 'bottom',
    tooltipArrow: false,
    tooltipDelayShow: 250,
    tooltipNoWrap: false,
    tooltipInteractive: false,
    openInNewTab: false,
  };
  render() {
    const {
      tip,
      tooltipPlacement,
      tooltipArrow,
      tooltipDelayShow,
      tooltipNoWrap,
      tooltipInteractive,
      ...rest
    } = this.props;

    if (tip) {
      return (
        <TooltipOverlayTrigger
          tooltipContent={tip}
          interactive={tooltipInteractive}
          tooltipNoWrap={tooltipNoWrap}
          placement={tooltipPlacement || 'bottom'}
          withArrow={tooltipArrow}
          delayShow={tooltipDelayShow}
        >
          <LinkCore {...rest} />
        </TooltipOverlayTrigger>
      );
    }

    return <LinkCore {...rest} />;
  }
}
