// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_jZxE5{border-bottom:1px dashed var(--color-ui-border-dark);margin-bottom:2px;cursor:help}[dir=\"ltr\"] .tippy-box[data-theme=\"tooltip-1.0\"] .tippy-content{text-align:left}[dir=\"rtl\"] .tippy-box[data-theme=\"tooltip-1.0\"] .tippy-content{text-align:right}.tippy-box[data-theme=\"tooltip-1.0\"] .tippy-content{font-family:var(--system-ui);font-style:normal;font-weight:normal;letter-spacing:normal;line-break:auto;line-height:1.5;text-decoration:none;text-shadow:none;text-transform:none;white-space:normal;word-break:normal;word-spacing:normal;word-wrap:normal;font-size:12px;font-weight:var(--typeface-base-weight-medium-500);line-height:16px;text-align:center}.tippy-box[data-theme=\"tooltip-1.0\"] .tippy-arrow{color:var(--color-base-sand-80) !important}.zp_AqzuH{background:var(--color-base-sand-80) !important;outline:0 !important}.zp_AqzuH{display:inline-flex;align-items:center;justify-content:center;border-radius:var(--border-radius-4);padding:0 6px;max-width:360px}.zp_AqzuH,.zp_AqzuH>*{white-space:pre-wrap}@media(max-width: 360px){.zp_AqzuH{max-width:calc(100% - 20px) !important}.zp_AqzuH{margin-left:10px}.zp_AqzuH *{word-wrap:break-word !important}.zp_AqzuH>div{max-width:100% !important}}@media(max-width: 360px){[class*=ext-iframe] .zp_AqzuH{max-width:none !important;max-width:initial !important}}@media(max-width: 360px){[class*=ext-iframe] .zp_AqzuH{max-width:none !important;max-width:initial !important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"underline": "zp_jZxE5",
	"tooltip": "zp_AqzuH"
};
export default ___CSS_LOADER_EXPORT___;
