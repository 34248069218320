import React, { useCallback, useEffect, useState } from 'react';
import { DialogContext, type DialogContextType } from './dialogContext';

import { usePrevious } from 'common/hooks';
import { getCurrentSite } from 'chrome-extension/inject/core/lib/locationUtils';
import { SITE } from 'chrome-extension/constants';
import { isListPage } from 'chrome-extension/inject/salesforce/lib/SalesforceDOMUtils';
import { isHubspotListPage } from 'chrome-extension/inject/hubspot/lib/HubspotDOMUtils';

let _openDialog: <T>(component: React.ComponentType<T>, props: T | object) => void;

/**
 * A util function to programmatically open a dialog.
 * A modern replacement for showDialog().
 * Use only when the dialog component needs just `router` from the context.
 * Access `currentUser` and `currentTeam` from redux store if required.
 * Preferable to use `useDialog` hook for other cases.
 *
 * Important:
 *
 * 1. Props propagate to the component only ONCE.
 *    There's no way to update the props after the dialog is mounted.
 *
 * 2. React context will NOT be available to the component or it's children.
 *    The exception is the contexts that are provided to DialogContainer (RootContext).
 *
 * 3. The component or it's children will NOT receive any re-provided context values.
 *    It will only have access to the context values that are provided to DialogContainer.
 *    (Example: `<RootContext.Provider value={...}>` will have no effect on the values received by the component)
 */
export function openDialog<T>(
  component: React.ComponentType<T>,
  props: Omit<T, 'onHide'> & {
    onHide?: (...args: unknown[]) => void; // Make onHide optional
  },
): void {
  if (!_openDialog) {
    throw new Error('openDialog was called before DialogContainer was mounted.');
  }
  _openDialog<T>(component, props || {});
}

type DialogItem = {
  id: string | number;
  props: DialogContextType & Record<string, unknown>;
  DialogComponent: React.ComponentType<unknown>;
};

/**
 * DO NOT USE. A single-use component meant to be used at app root.
 */
export default function DialogContainer() {
  const [dialogs, setDialogs] = useState<DialogItem[]>([]);
  const prevDialogLength = usePrevious(dialogs.length);

  const openDialogLocal = useCallback(
    (DialogComponent, props) => {
      const id = Date.now() + Math.random();

      const updatedProps = Object.assign({}, props, {
        onHide: (...args: unknown[]) => {
          setDialogs((allDialogs) => allDialogs.filter((dialog) => dialog.id !== id));

          // eslint-disable-next-line react/prop-types
          const originalOnHide = props?.onHide;

          if (typeof originalOnHide === 'function') {
            originalOnHide(...args);
          }
        },
      });

      setDialogs((existingDialogs) => {
        return existingDialogs.concat({ id, DialogComponent, props: updatedProps });
      });
    },
    [setDialogs],
  );

  const toggleIframeForExtension = (container = 'sidebar') => {
    window.parent.postMessage(
      {
        type: 'toggleIframeWidth',
        payload: container,
      },
      '*',
    );
    window.postMessage(
      {
        type: 'toggleIframeWidth',
        payload: container,
      },
      '*',
    );
  };

  useEffect(() => {
    const currentSite = getCurrentSite();
    if (
      process.env.IS_EXTENSION &&
      [SITE.GMAIL, SITE.LINKEDIN, SITE.SALESFORCE, SITE.HUBSPOT, SITE.GOOGLE_CALENDAR].includes(
        currentSite,
      )
    ) {
      let container = 'sidebar';
      if (
        (currentSite === SITE.SALESFORCE && isListPage()) ||
        (currentSite === SITE.HUBSPOT && isHubspotListPage())
      ) {
        container = 'toolbar';
      }
      if ((!prevDialogLength || prevDialogLength === 0) && dialogs.length > 0) {
        toggleIframeForExtension(container);
      } else if (prevDialogLength && prevDialogLength > 0 && dialogs.length === 0) {
        toggleIframeForExtension(container);
      }
    }
  }, [dialogs, prevDialogLength]);

  useEffect(() => {
    _openDialog = openDialogLocal;
  }, [openDialogLocal]);

  return (
    <>
      {dialogs.map((dialog) => {
        const { id, DialogComponent, props } = dialog;
        return (
          <DialogContext.Provider key={id} value={props}>
            <DialogComponent {...props} key={id} />
          </DialogContext.Provider>
        );
      })}
    </>
  );
}
