import { keyPathMirror as keyMirror } from 'key-path-mirror';

export default keyMirror({
  // 20180103JP: Namespace these.
  HANDLE_EMAIL_CLICK: null,
  IFRAME_MESSENGER_IS_READY: null,
  RECEIVE_TRACKING_DOMAIN: null,
  SEND_TO_POPUP: null,
  ACTIVATE_EXTENSION: null,
  GET_SCREEN_PROPERTIES: null,
  EXTENSION_WINDOW_REMOVED: null,
  REFRESH_TAB: null,
  UPDATE_SIDEBAR_FROM_WMODE: null,
  UPDATE_DATA_SIDEBAR: null,
  MANAGE_FEATURES: null,
  MANAGE_EXCLUSIONS: null,
  RESET_EXTENSION: null,
  CHECK_EXTENSION_LOADED: null,

  EVERYWHERE: {
    AUTHORIZE: null,
    CALL: null,
    DISPLAY_ACCOUNT: null,
    DISPLAY_ORGANIZATION: null,
    DISPLAY_CONTACT: null,
    DISPLAY_OPPORTUNITY: null,
    INITIALIZE: null,
    LOAD_ENTITIES: null,
    NAVIGATE: null,
    SET_IFRAME_SIDEBAR_EXPAND_STATUS: null,
    SET_LOADING: null,
    SET_MODALITY: null,
    SET_ERROR: null,
    SET_EXTERNAL_EMAIL_ADDRESS: null,
    PAGE_REFRESH: null,
    GET_ACTIVE_TAB_ID: null,
    GET_TAB_ID_TO_REFRESH_ON_LOGIN: null,
    UPDATE_VOICE_SETTINGS: null,
    CLICK_GOOGLE_CALENDAR_ITEM: null,
    CHECK_VALID_TAB_FOR_CALL: null,
    UPDATE_CONTACT_STAGE: null,
    UPDATE_CONTACT: null,
    SYNC_USER_SETTINGS: null,
    RELOAD_EXTENSION: null,
  },

  LINKEDIN: {
    BULK_PROSPECT_FROM_LINKEDIN_SEARCH_PAGE_COMPLETED: null,
    SEARCH_CONTACTS_PARSED: null,
    SELECT_ALL_LINKEDIN_SEARCH_PAGE_CONTACT_IDS: null,
    SELECT_LINKEDIN_SEARCH_PAGE_CONTACT_ID: null,
    SET_PROFILE_CONTACT: null,
    UNSELECT_ALL_LINKEDIN_SEARCH_PAGE_CONTACT_IDS: null,
    UNSELECT_LINKEDIN_SEARCH_PAGE_CONTACT_ID: null,
    SET_ALL_SEARCH_CONTACTS_LOADED: null,
    SET_AUTO_EXPAND_STATUS: null,
  },

  SALESFORCE: {
    SET_SALESFORCE_SIDEBAR_VIEW: null,
  },

  APOLLO: {
    OPEN_FREE_LEADS_SIGNUP_IN_PARENT: null,
    EXTENSION_VERSION: null,
    SET_USER_DATA: null,
  },

  ONBOARDING: {
    START_LINKEDIN_FLOW: null,
    CLOSE_TOOLTIP_ON_SEARCH: null,
  },

  STATE_CACHING: {
    INITIALIZE: null,
    CACHE_STATE: null,
    GET_CACHED_STATE: null,
    CLEAR_CACHED_STATE: null,
    GET_LAST_REFRESHED_TS: null,
  },
});
