import { type V4Options, v4 as uuidv4 } from 'uuid';

let GUID_PREFIX: string | number;
let numberCache;
let stringCache;
let GUID_KEY: PropertyKey;
let GUID_DESC;

function uuid(options?: V4Options) {
  return uuidv4(options);
}

/**
 * @deprecated
 */
function generateGuid(obj, prefix) {
  if (!prefix) {
    prefix = GUID_PREFIX;
  }
  const ret = prefix + uuid();
  if (obj) {
    if (obj[GUID_KEY] === null) {
      obj[GUID_KEY] = ret;
    } else {
      GUID_DESC.value = ret;
      Object.defineProperty(obj, GUID_KEY, GUID_DESC);
    }
  }
  return ret;
}

/**
 * @deprecated
 */
function guidFor(obj: string | number | ObjectConstructor | ArrayConstructor) {
  if (obj && obj[GUID_KEY]) {
    return obj[GUID_KEY];
  }
  if (obj === undefined) {
    return '(undefined)';
  }
  if (obj === null) {
    return '(null)';
  }
  let ret;
  const type = typeof obj;
  switch (type) {
    case 'number':
      ret = numberCache[obj];
      if (!ret) {
        numberCache[obj] = `nu${obj}`;
        ret = numberCache[obj];
      }
      return ret;
    case 'string':
      ret = stringCache[obj];
      if (!ret) {
        stringCache[obj] = `st${uuid()}`;
        ret = stringCache[obj];
      }
      return ret;
    case 'boolean':
      return obj ? '(true)' : '(false)';
    default:
      if (obj === Object) {
        return '(Object)';
      }
      if (obj === Array) {
        return '(Array)';
      }
      ret = GUID_PREFIX + uuid();
      if (obj[GUID_KEY] === null) {
        obj[GUID_KEY] = ret;
      } else {
        GUID_DESC.value = ret;
        Object.defineProperty(obj, GUID_KEY, GUID_DESC);
      }
      return ret;
  }
}

GUID_PREFIX = 'o';
numberCache = [];
stringCache = {};
GUID_KEY = `__o${Number(new Date())}`;
GUID_DESC = {
  writable: true,
  configurable: true,
  enumerable: false,
  value: null,
};

export { uuid, generateGuid };
export default guidFor;
