import type {
  BookEventRequestDataType,
  CalendarDataType,
  FetchCalendarDataResponseType,
} from 'app/containers/meetings/meetings.types';
import apiClient from 'common/lib/apiClient';
import type {
  MeetingWidgetGuestDetailsType,
  MeetingWidgetMeetingTypeSettingType,
  MeetingsWidgetFormResponseType,
} from './meetings-widget.types';

export const getMeetingCalendarData = ({
  calendarUrl,
  meetingTypeUrl,
  timeZone,
  callbackOptions: { onError, onSuccess },
}: {
  calendarUrl: string;
  meetingTypeUrl: string;
  timeZone: string;
  callbackOptions: {
    onError: (type?: 'not_found' | 'rate_limit') => void;
    onSuccess: (calendarData: CalendarDataType) => void;
  };
}) => {
  apiClient
    .fetch('/meetings/user_meeting_availability', {
      method: 'get',
      data: {
        userCalendarLink: calendarUrl,
        meetingTypeCalendarLink: meetingTypeUrl,
        timeZone,
        'x-app-id': window.ApolloMeetings?._appConfig.appId,
      },
      credentials: 'omit',
      skipCamalization: true,
    })
    .then((response: FetchCalendarDataResponseType = { error: '', error_code: '' }) => {
      if ('error_code' in response) {
        if (response.error_code === 'RESOURCE_NOT_FOUND') {
          onError('not_found');
          return;
        }

        if (response.error_code === 'API_RATE_LIMIT_REACHED') {
          onError('rate_limit');
          return;
        }

        onError();
        return;
      }

      if ('error' in response) {
        onError();
        return;
      }

      onSuccess(response);
    })
    .catch(() => {
      onError();
    });
};

type CreateBookingResponseType = {
  errorCode?: string;
  id?: string;
  error?: unknown;
  json?: {
    errorCode?: string;
  };
};

export const createBooking = (
  data: BookEventRequestDataType,
  callbackOptions: {
    onError: (type?: 'not_found' | 'related_accounts') => void;
    onSuccess: () => void;
  },
) => {
  return apiClient
    .fetch(`/calendar_events?x-app-id=${window.ApolloMeetings?._appConfig.appId}`, {
      method: 'post',
      data,
      credentials: 'omit',
    })
    .then((responseJSON: CreateBookingResponseType) => {
      if (responseJSON.errorCode === 'RESOURCE_NOT_FOUND') {
        callbackOptions.onError('not_found');
        return;
      }

      if (responseJSON.id) {
        callbackOptions.onSuccess();
        return;
      }

      if (
        responseJSON.json?.errorCode === 'RELATED_EMAIL_ACCOUNTS' ||
        responseJSON.json?.errorCode === 'INTERNAL_DOMAIN'
      ) {
        callbackOptions.onError('related_accounts');
      } else {
        callbackOptions.onError();
      }
    })
    .catch(() => {
      callbackOptions.onError();
    });
};

type SubmitInboundRouterSuccessResponseType = {
  matchedAction:
    | {
        actionType: 'meeting_type';
        meetingTypeSetting: MeetingWidgetMeetingTypeSettingType;
      }
    | {
        actionType: 'redirect_to_url';
        /** redirect URL */
        value: string;
      }
    | {
        actionType: 'custom_message';
        header?: string;
        value?: string;
      }
    | {
        actionType: '';
      };
} & {
  guestUserDetails: MeetingWidgetGuestDetailsType;
};

export const submitInboundRouter = async ({
  data,
  callbackOptions: { onError, onSuccess },
}: {
  data: {
    schedulingLink: string;
    queryParams: object;
    guestDetails: {
      formResponses: MeetingsWidgetFormResponseType[];
    };
  };
  callbackOptions: {
    onError: (
      params?:
        | { type?: 'not_found' | 'rate_limit' }
        | {
            type?: 'missing_required_questions';
            questions: string[];
          },
    ) => void;
    onSuccess: (data: SubmitInboundRouterSuccessResponseType) => void;
  };
}) => {
  try {
    const res = await apiClient.fetch(
      `/meetings/inbound_router/guest_inbound_router/submit_external?x-app-id=${window.ApolloMeetings?._appConfig.appId}`,
      {
        method: 'post',
        data,
        credentials: 'omit',
      },
    );

    if (res?.status === 'failed' || res?.error) {
      throw res?.json || res?.error;
    }

    onSuccess(res);
  } catch (error) {
    if (error && typeof error === 'object') {
      if ('error_code' in error) {
        if (error.error_code === 'API_RATE_LIMIT_REACHED') {
          onError({ type: 'rate_limit' });
          return;
        }

        if (error.error_code === 'MISSING_REQUIRED_ANSWERS') {
          let missingQuestions: string[] = [];

          if ('mandatory_questions' in error && Array.isArray(error.mandatory_questions)) {
            missingQuestions = error.mandatory_questions;
          }

          onError({
            type: 'missing_required_questions',
            questions: missingQuestions,
          });
          return;
        }
      }

      if ('error' in error && typeof error.error === 'string') {
        if (error.error.toUpperCase() === 'NOT FOUND') {
          onError({ type: 'not_found' });
          return;
        }
      }
    }
    onError();
  }
};

export const fetchRouterSettings = ({
  schedulingLink,
  appId,
  callbackOptions: { onError, onSuccess },
}: {
  schedulingLink: string;
  appId: string;
  callbackOptions: {
    onSuccess: (externalConfig) => void;
    onError: (type?: 'not_found_router' | 'not_found_appId') => void;
  };
}) => {
  return apiClient
    .fetch('/meetings/inbound_router/guest_inbound_router/router_settings', {
      method: 'get',
      data: {
        schedulingLink,
        appId,
        'x-app-id': appId,
      },
      credentials: 'omit',
      skipCamalization: true,
    })
    .then((res: unknown) => {
      if (res && typeof res === 'object') {
        if ('error' in res && typeof res.error === 'string') {
          if (res.error.toUpperCase().includes('ROUTER')) {
            onError('not_found_router');
            return;
          }

          if (res.error.toUpperCase().includes('APP')) {
            onError('not_found_appId');
            return;
          }
        }

        onSuccess(res);
      }
    });
};

export const fetchFormDetails = async (data = {}) => {
  try {
    const res = await apiClient.fetch(
      `form_enrichment/enrich_inbound_router_form?x-app-id=${window.ApolloMeetings?._appConfig.appId}`,
      {
        method: 'post',
        data,
        credentials: 'omit',
      },
    );

    if (res?.status === 'failed' || res?.error) {
      return data.onError?.(res);
    }

    data.onSuccess?.({
      requestFulfilled: res.requestFulfilled,
      guestResponseMapping: Object.values(res?.guestResponseMapping || {}),
    });
  } catch (error) {
    data.onError?.(error);
  }
};
