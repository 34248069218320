/* eslint-disable leadgenie/prevent-context-provider-for-design-system-components */
/* eslint-disable react/jsx-props-no-spreading */
import { Button } from '@ariakit/react';
import { type ComponentProps, type ForwardedRef, type PropsWithChildren, forwardRef } from 'react';
import classNames from 'classnames';

import { UnstyledLink } from '../link/UnstyledLink';

import styles from './UnstyledButton.module.scss';

type UnstyledButtonProps = PropsWithChildren<
  {
    /** Optional class name for styling the button. */
    className?: string;

    /**
     * Whether button should stay accessible when disabled. It allows adding tooltips and improves discoverability.
     *
     * @default `true`
     */
    accessibleWhenDisabled?: boolean;

    /** Whether the button is disabled. */
    disabled?: boolean;

    /** Type attribute of button */
    type?: HTMLButtonElement['type'];

    /** Defines a string to identify current element’s purpose, when it can’t be inferred from the element type. */
    role?: HTMLButtonElement['role'];

    /**
     * If set to `true`, pressing the enter key while this element is focused will
     * trigger a click on the element, regardless of whether it's a native button
     * or not. If this prop is set to `false`, pressing enter will not initiate a
     * click.
     * @default true
     */
    clickOnEnter?: boolean;

    /**
     * If set to `true`, pressing and releasing the space key while this element
     * is focused will trigger a click on the element, regardless of whether it's
     * a native button or not. If this prop is set to `false`, space will not
     * initiate a click.
     * @default true
     */
    clickOnSpace?: boolean;

    /**
     * Defines a string value that labels the current element.
     */
    'aria-label'?: string;

    /**
     * Identifies the element (or elements) that labels the current element.
     */
    'aria-labelledby'?: string;

    /**
     * Identifies the element (or elements) that describes the object.
     */
    'aria-describedby'?: string;

    /**
     * Identifies the element (or elements) that provide a detailed, extended description for the object.
     */
    'aria-details'?: string;
  } & Omit<ComponentProps<typeof Button>, 'as'> &
    Pick<ComponentProps<typeof UnstyledLink>, 'to' | 'href' | 'target'> &
    (ComponentProps<'button'> | ComponentProps<typeof UnstyledLink>)
>;

export const UnstyledButton = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  UnstyledButtonProps
>(function UnstyledButton(props, ref) {
  const { className, ...restProps } = props;

  const linkProps = props as ComponentProps<typeof UnstyledLink>;
  if (linkProps.to || linkProps.href) {
    return (
      <UnstyledLink
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        {...linkProps}
        className={classNames(styles.button, className)}
      />
    );
  }

  return (
    <Button
      ref={ref as ForwardedRef<HTMLButtonElement>}
      {...restProps}
      className={classNames(styles.button, className)}
    />
  );
});
