/* eslint-disable react/jsx-props-no-spreading */
import React, { type ComponentProps, forwardRef } from 'react';
import { UnstyledButton } from './UnstyledButton';
import classNames from 'classnames';

import Icon from '../../icon/Icon';
import { Count } from '../../count/Count';
import { TooltipRenderer } from '../../tooltip/TooltipRenderer';
import { LoadingAnimation } from '../../loading-animation/LoadingAnimation';

import styles from './Button.module.scss';

export type ButtonProps = {
  /**
   * Visual variant of CTA
   * @default primary
   */
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'growth'
    | 'destroy'
    | 'destroy-tertiary'
    | 'reversed'
    | 'ai-primary'
    | 'ai-secondary';

  /**
   * Size of CTA
   *
   * - `small` (32px)
   * - `medium` (40px)
   * @default medium
   */
  size?: 'small' | 'medium';
  /**
   * Indicates whether the button is in Loading state.
   */
  loading?: boolean;
  /**
   * A tooltip for the CTA. Can accept a string for default styling or a Tooltip component for more control.
   * @type string
   * @example
   * tooltip="Default Tooltip"
   *
   * @example
   * tooltip={<Tooltip withArrow>Custom Tooltip</Tooltip>}
   */
  tooltip?: ComponentProps<typeof TooltipRenderer>['tooltip'];

  /**
   * A numeric value or component for displaying a count within the CTA.
   * @type number | React.ReactElement<typeof ButtonCount>
   * @example
   * count={5}
   *
   * @example
   * count={<CTACount />}
   */
  count?: number | React.ReactElement<typeof ButtonCount>;

  /**
   * Icon to be displayed on the left side of the CTA text
   * @type string extends AllIcons | React.ReactElement<typeof ButtonIcon>
   * @example
   * icon="add"
   *
   * @example
   * icon={<CTAIcon />}
   */
  icon?: ComponentProps<typeof Icon>['icon'] | React.ReactElement<typeof ButtonIcon>;

  /**
   * Icon to be displayed on the right side of the CTA text
   * @type string extends AllIcons | React.ReactElement<typeof ButtonIcon>
   * @example
   * rightIcon="arrow-right"
   *
   * @example
   * rightIcon={<CTAIcon />}
   */
  rightIcon?: ComponentProps<typeof Icon>['icon'] | React.ReactElement<typeof ButtonIcon>;
} & ComponentProps<typeof UnstyledButton>;

/**
 * The Button component serves as a user interface element designed to trigger actions when clicked or interacted with. Its primary purpose is to allow users to initiate actions such as submitting forms, navigating to different sections of the application, or triggering specific functions.

- **Clickable Interaction:** Users can interact with the Button component by clicking on it using a mouse, tapping on it with a touchscreen device, or pressing the Enter key when the button is focused.

- **Visual Feedback:** Upon interaction, the Button component typically provides visual feedback to indicate that the action has been initiated. This feedback may include changes in color, size, or animation to signify the button press.

- **Action Triggering:** When clicked, the Button component triggers predefined actions or events within the system. These actions can range from submitting a form to initiating a complex process or navigating to a different page.

- **Customization:** The Button component often allows for customization of its appearance, including styles, colors, and text, to ensure consistency with the overall design language of the application.
 */
export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  function Button(props, ref) {
    const {
      variant = 'primary',
      size = 'medium',
      icon,
      rightIcon,
      count,
      tooltip,
      children,
      className,
      loading,
      disabled,
      accessibleWhenDisabled,
      ...restProps
    } = props;

    return (
      <TooltipRenderer tooltip={tooltip}>
        <UnstyledButton
          ref={ref}
          className={classNames(
            styles.button,
            variant === 'primary' && styles.primary,
            variant === 'secondary' && styles.secondary,
            variant === 'tertiary' && styles.tertiary,
            variant === 'growth' && styles.growth,
            variant === 'destroy' && styles.destroy,
            variant === 'destroy-tertiary' && styles.destroyTertiary,
            variant === 'reversed' && styles.reversed,
            variant === 'ai-primary' && styles.aiPrimary,
            variant === 'ai-secondary' && styles.aiSecondary,
            size === 'small' && styles.small,
            size === 'medium' && styles.medium,
            className,
          )}
          data-cta-variant={variant}
          data-cta-size={size}
          disabled={loading ? true : disabled}
          accessibleWhenDisabled={accessibleWhenDisabled ?? true}
          {...restProps}
        >
          <>
            {loading ? (
              <div aria-busy={loading}>
                <LoadingAnimation variant="small" />
              </div>
            ) : (
              icon && <IconRenderer icon={icon} />
            )}
            <ButtonCaption>{children}</ButtonCaption>
            {count || count === 0 ? <CountRenderer count={count} disabled={disabled} /> : null}
            {rightIcon ? <IconRenderer icon={rightIcon} /> : null}
          </>
        </UnstyledButton>
      </TooltipRenderer>
    );
  },
);

type IconRendererProps = {
  icon: ButtonProps['icon'];
};

export function IconRenderer({ icon }: IconRendererProps): JSX.Element | null {
  if (!icon) {
    return null;
  }

  if (typeof icon === 'string') {
    return <ButtonIcon icon={icon} />;
  }

  if (React.isValidElement(icon)) {
    if (icon.type !== ButtonIcon) {
      throw new Error('Icon component can only be ButtonIcon or Button.Icon');
    }

    return icon;
  }

  return null;
}

type CountRendererProps = {
  count: ButtonProps['count'];
  disabled?: boolean;
};

function CountRenderer({ count, disabled }: CountRendererProps) {
  if (!count && count !== 0) {
    return null;
  }

  if (typeof count === 'number') {
    return <ButtonCount count={count} disabled={disabled} />;
  }

  if (React.isValidElement(count)) {
    if (count.type !== ButtonCount) {
      throw new Error('Count component can only be ButtonCount or Button.Count');
    }

    return count;
  }

  return null;
}

type ButtonCaptionProps = React.PropsWithChildren<{}>;

export function ButtonCaption(props: ButtonCaptionProps) {
  return <span className={styles.caption}>{props.children}</span>;
}

type ButtonIconProps = ComponentProps<typeof Icon>;
export function ButtonIcon(props: ButtonIconProps) {
  return (
    <Icon
      size="medium"
      {...props}
      className={classNames(props.className, styles.icon)}
      aria-hidden
    />
  );
}

type ButtonRightIconProps = ComponentProps<typeof Icon>;
export function ButtonRightIcon(props: ButtonRightIconProps) {
  return (
    <Icon
      size="medium"
      {...props}
      className={classNames(props.className, styles.icon)}
      aria-hidden
    />
  );
}

interface ButtonCountProps extends Pick<ComponentProps<typeof Count>, 'count'> {
  disabled?: boolean;
}
export function ButtonCount(props: ButtonCountProps) {
  return <Count mode="non-interactive" size="small" {...props} aria-hidden />;
}
