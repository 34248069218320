import React, { type ComponentProps } from 'react';
import { Tooltip } from './Tooltip';

type TooltipRendererProps = React.PropsWithChildren<{
  /**
   * The tooltip content (ReactNode) or the Tooltip itself (`<Tooltip ... />`)
   */
  tooltip: ComponentProps<typeof Tooltip>['content'] | React.ReactElement<typeof Tooltip>;
  trigger?: ComponentProps<typeof Tooltip>['trigger'];
}> &
  ComponentProps<typeof Tooltip>;

export function TooltipRenderer({ tooltip, children, ...props }: TooltipRendererProps) {
  if (React.isValidElement(tooltip) && tooltip.type === Tooltip) {
    return React.cloneElement(tooltip, { ...props, ...tooltip.props }, children);
  }

  return (
    <Tooltip content={tooltip} {...props}>
      {children as React.ReactElement}
    </Tooltip>
  );
}
