import React, { useCallback, useEffect } from 'react';
import { Modal, useModal } from 'common/components/design-system/modal/Modal';
import { loadCloudflareTurnstileScript } from 'app/utils/MiscUtils';

declare global {
  interface Window {
    turnstile?: { render: (selector: string, options: Record<string, unknown>) => void };
  }
}

const WIDGET_SITE_KEY = '0x4AAAAAAAks-gkSyQwOYoux';
const MODAL_STYLE: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  color: 'var(--color-negative-40)',
};

export const SecurityChallengeDialog = ({
  onSuccessCallback,
}: {
  onSuccessCallback: () => void;
}) => {
  const modal = useModal();

  const renderTurnstileWidget = useCallback(async () => {
    await loadCloudflareTurnstileScript();
    if (window?.turnstile) {
      window.turnstile.render('#securityChallenge', {
        sitekey: WIDGET_SITE_KEY,
        theme: 'light',
        callback(preClearanceObtained: boolean) {
          if (preClearanceObtained) {
            onSuccessCallback?.();
            modal.hide();
          } else {
            console.error('Unable to obtain pre-clearance');
          }
        },
      });
    }
  }, [modal, onSuccessCallback]);

  useEffect(() => {
    modal.show();
    try {
      renderTurnstileWidget();
    } catch (_) {
      console.error('Unable to render Turnstile widget');
    }
  }, [modal, renderTurnstileWidget]);

  return (
    <Modal store={modal} size="small">
      <Modal.Content>
        <div style={MODAL_STYLE} id="securityChallenge" />
      </Modal.Content>
    </Modal>
  );
};
