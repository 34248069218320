import { isExtensionServiceWorker } from 'chrome-extension/inject/core/lib/utils';

/**
 * @param {Object} data existing request data
 */
export function dataWithVersionSha(
  data: Record<string, unknown> = {},
): /** A copy of the passed in object along with a version_sha field set. version_sha references the commit sha of the frontend code. */ Record<
  string,
  unknown
> {
  // TOOD(maxshort): This is for dev convenience when we change to older
  // branches so we don't have to rebuild the bundle. This SHOULD BE REMOVED
  // BY MAY 2019.
  if (typeof GIT_COMMIT_SHA === 'undefined') {
    return data;
  }
  return {
    ...data,
    // Webpack fills in GIT_COMMIT_SHA
    // eslint-disable-next-line no-undef
    version_sha: GIT_COMMIT_SHA,
  };
}

/**
 * @param data {Object} data
 */
export function dataWithFullstorySession(
  data: Record<string, unknown> = {},
): /** Copy of data with key fullstory_session if available or the same object if no full story session was available. */ Record<
  string,
  unknown
> {
  // Full story recommends only accessing this after load. We don't watch the load
  // event specifically because we also have to handle cases where FS doesn't
  // load due to e.g. an ad blocker. So, the rule is "send if available."
  const sessionOrFalsey =
    !isExtensionServiceWorker &&
    window.FS &&
    typeof window.FS.getCurrentSession === 'function' &&
    window.FS.getCurrentSession();

  if (!sessionOrFalsey) {
    return data;
  }
  return {
    ...data,
    fullstory_session: sessionOrFalsey,
  };
}
