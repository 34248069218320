// reference: https://stackoverflow.com/questions/68735839/passing-formdata-file-object-from-content-script-to-background-script-in-chrome/68736674#68736674
export async function serialize(src: Blob) {
  const cls = Object.prototype.toString.call(src).slice(8, -1);
  switch (cls) {
    case 'FormData': {
      return {
        cls,
        value: await Promise.all(
          Array.from(src.keys(), async (key) => [
            key,
            await Promise.all(src.getAll(key).map(serialize)),
          ]),
        ),
      };
    }
    case 'Blob':
    case 'File':
      return new Promise((resolve) => {
        const { name, type, lastModified } = src;
        const reader = new FileReader();
        reader.onload = () =>
          resolve({
            cls,
            name,
            type,
            lastModified,
            value: reader.result.slice(reader.result.indexOf(',') + 1),
          });
        reader.readAsDataURL(src);
      });
    default:
      return src == null
        ? undefined
        : {
            cls: 'Json',
            value: JSON.stringify(src),
          };
  }
}

// reference: https://stackoverflow.com/questions/68735839/passing-formdata-file-object-from-content-script-to-background-script-in-chrome/68736674#68736674
export function deserialize(src) {
  switch (src.cls) {
    case 'FormData': {
      const fd = new FormData();
      src.value.forEach(([key, items]) => {
        items.forEach((item) => {
          fd.append(key, deserialize(item));
        });
      });
      return fd;
    }
    case 'Blob':
    case 'File': {
      const { type, name, lastModified } = src;
      const binStr = atob(src.value);
      const arr = new Uint8Array(binStr.length);
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      const data = [arr.buffer];
      return src.cls === 'File'
        ? new File(data, name, { type, lastModified })
        : new Blob(data, { type });
    }
    case 'Json':
    default:
      return JSON.parse(src.value);
  }
}
