import type { PropsWithChildren } from 'react';
import styles from './WidgetModal.module.scss';
import classNames from 'classnames';
import { meetingsWidgetSubmitOptions } from 'meetings-widget/meetings-widget.utils';

type WidgetModalProps = PropsWithChildren<{
  open?: boolean;
  contentClassName?: string;
}>;

const WidgetModal = ({ children, contentClassName = '', open = false }: WidgetModalProps) => {
  const handleOverlayClick = () => {
    if (meetingsWidgetSubmitOptions.closeOnOutside) {
      window.ApolloMeetings?.closeWidget();
    }
  };

  if (!open) {
    return null;
  }

  return (
    <div className={classNames(styles.widgetModal)}>
      <div className={styles.widgetModalOverlay} onClick={handleOverlayClick} />
      <div className={classNames(styles.widgetModalContent, contentClassName)}>{children}</div>
    </div>
  );
};

export default WidgetModal;
