import React, { type ComponentProps, forwardRef } from 'react';
import classNames from 'classnames';
import type { RequireExactlyOne } from 'type-fest';

import LegacyIcon from './LegacyIcon';

import type { AllIcons } from '$types/icon';

import styles from './Icon.module.scss';

type BaseIconProps = {
  icon?: AllIcons;
  customSrc?: string;
} & Omit<ComponentProps<typeof LegacyIcon>, 'name' | 'customSrc'>;

type IconProps = RequireExactlyOne<BaseIconProps, 'icon' | 'customSrc'>;

const Icon = forwardRef<HTMLElement, IconProps>(function Icon(
  { icon, customSrc, className, ...restProps },
  ref,
) {
  // assertValidDOMAttributes(restProps);

  if (typeof icon !== 'undefined') {
    return (
      <LegacyIcon
        className={classNames(styles.icon, className)}
        {...restProps}
        name={icon}
        ref={ref}
      />
    );
  }
  return (
    <LegacyIcon
      className={classNames(styles.icon, className)}
      {...restProps}
      customSrc={customSrc}
      ref={ref}
    />
  );
});

export default Icon;
