/**
 * Figma: https://www.figma.com/file/HnYrd6FfB4O1VUV9GuuWe6/Components?type=design&node-id=7%3A101&mode=design&t=RHVXEqaqjOaJ5K7w-1
 */

import { useRef } from 'react';
// eslint-disable-next-line leadgenie/no-import-extensions
import type { Instance } from 'tippy.js';
import classNames from 'classnames';

import { Count } from '../count/Count';
import { IconButton } from '../core/icon-button/IconButton';
import Icon from '../icon/Icon';
import type { BadgeProps } from './Badge';
import { BadgeDot } from './BadgeDot';
import { TooltipRenderer } from '../tooltip/TooltipRenderer';
import { Tooltip } from '../tooltip/Tooltip';
import { resolveVariant } from './utils';

import styles from './BadgeContent.module.scss';

export const BadgeContent = function BadgeContent({
  variant,
  interactive = false,
  leftIconName,
  label,
  showCloseButton,
  count,
  onRemove,
  tooltip,
  showBadgeDot,
  disableTextTruncate,
}: Omit<BadgeProps, 'id'>) {
  const instanceRef = useRef<Instance | null>(null);

  const resolvedVariant = resolveVariant(variant);

  function onCreate(instance: Instance) {
    instanceRef.current = instance;
  }

  function onTrigger() {
    instanceRef.current?.disable();
  }

  function onUntrigger() {
    instanceRef.current?.enable();
    instanceRef.current?.show();
  }

  const content = (
    <div
      className={classNames(
        styles.badge,
        resolvedVariant && styles[resolvedVariant],
        interactive && styles.interactive,
        disableTextTruncate && styles.disableBadgeTruncate,
      )}
    >
      {leftIconName ? <Icon icon={leftIconName} className={styles.icon} /> : null}
      {showBadgeDot ? <BadgeDot variant={resolvedVariant} label={label} tooltip={tooltip} /> : null}
      {label ? <span className={styles.label}>{label}</span> : null}
      {typeof count !== 'undefined' ? <Count count={count} size="small" /> : null}
      {showCloseButton ? (
        <IconButton
          icon="times"
          size="xxsmall"
          onClick={onRemove}
          className={styles.iconButton}
          aria-label={`Remove ${label}`}
          tooltip={
            <Tooltip
              content={`Remove ${label}`}
              delay={[120, 0]}
              onTrigger={onTrigger}
              onUntrigger={onUntrigger}
            />
          }
        />
      ) : null}
    </div>
  );

  return tooltip ? (
    <TooltipRenderer tooltip={tooltip} onCreate={onCreate}>
      {content}
    </TooltipRenderer>
  ) : (
    content
  );
};
