import React, { Component, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import Tippy, { type TippyProps } from '@tippyjs/react';

import styles from 'common/components/ui/Tooltip.module.scss';
import 'tippy.js/dist/tippy.css';

const TOOLTIP_THEME = 'tooltip-1.0';

type TooltipProps = {
  options?: TippyProps;
  className?: string;
  showUnderline?: boolean;
  children?: TippyProps['children'];
};

/**
 * @deprecated This component is deprecated. Please use `Tooltip` from DS2.0 instead.
 *
 * https://app-master.preview.staging-gcp.apollo.io/docs/?path=/docs/design-system-2-0-components-tooltip--docs
 */
export default class Tooltip extends Component<TooltipProps> {
  static defaultProps = {
    options: {},
    className: '',
    showUnderline: false,
    children: null,
  };

  render() {
    const { options, className, showUnderline, children } = this.props;

    return (
      <Tippy
        theme={TOOLTIP_THEME}
        {...options}
        className={classnames(
          'apolloio-css-vars-reset',
          styles.tooltip,
          className,
          showUnderline && styles.underline,
        )}
        onCreate={(instance) => {
          instance?.reference?.setAttribute('data-has-tooltip', 'true');
          options?.onCreate?.(instance);
        }}
        onDestroy={(instance) => {
          instance.reference.removeAttribute('data-has-tooltip');
        }}
      >
        {children}
      </Tippy>
    );
  }
}

type CleanTooltipProps = {
  options?: TippyProps;
  className?: string;
  showUnderline?: boolean;
  reference?: unknown;
  /**
   * Custom PropType to check for a single child.
   */
  children?: unknown;
  /**
   * Introducing this as an optional prop so that components can also directly use CleanTooltip without maintaining a ref.
   */
  setCleanTooltipRef?: React.Dispatch<React.SetStateAction<null>>;
};

/**
 * A tooltip component which does not wrap the child component in a <span> element and does not require to passing a forwardRef.
 * It gets the child's reference by inserting an adjacent element.
 * This adjacent element is removed from the DOM when the sibling's ref is available.
 *
 * @param options
 * @param className
 * @param showUnderline
 * @param children
 *
 * @constructor
 */
export function CleanTooltip({
  options,
  className,
  showUnderline,
  children,
  setCleanTooltipRef,
}: CleanTooltipProps): JSX.Element {
  /**
   * Reference for the temporary span element.
   */
  const spanRef = useRef(null);
  const [childRef, setChildRef] = useState(null);

  /**
   * Mount the temporary span element.
   * retrieve and store the target element's reference.
   */
  useEffect(() => {
    if (spanRef.current) {
      const tooltipRef = spanRef.current.previousElementSibling;
      setChildRef(tooltipRef);
      setCleanTooltipRef?.(tooltipRef);
    }
    return () => setChildRef(null);
  }, [setCleanTooltipRef]);

  return (
    <React.Fragment>
      {children}
      {childRef ? (
        <Tippy
          theme={TOOLTIP_THEME}
          {...options}
          className={classnames(
            'apolloio-css-vars-reset',
            styles.tooltip,
            className,
            showUnderline && styles.underline,
          )}
          reference={childRef}
          onCreate={(instance) => {
            instance?.reference?.setAttribute('data-has-tooltip', 'true');
            options?.onCreate?.(instance);
          }}
          onDestroy={(instance) => {
            instance.reference.removeAttribute('data-has-tooltip');
          }}
        />
      ) : (
        <span ref={spanRef} style={{ display: 'none' }} />
      )}
    </React.Fragment>
  );
}

CleanTooltip.defaultProps = {
  options: {},
  className: '',
  showUnderline: false,
  reference: null,
  children: null,
};
