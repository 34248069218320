import { extractChildren } from 'common/components/design-system/utils';
import type { PropsWithChildren, ReactNode } from 'react';
import { Button } from 'common/components/design-system/core/button/Button';
import { ButtonGroup } from 'common/components/design-system/core/button/ButtonGroup';
import { PaginationDots } from 'common/components/design-system/pagination-dots/PaginationDots';

import styles from './ModalFooter.module.scss';

type ModalFooterProps = PropsWithChildren<{}>;

export const ModalFooter = (props: ModalFooterProps) => {
  const { children } = props;
  const { leftButton, buttonGroup, pagination, text } = extractComponentChildren(children);
  return (
    <div className={styles.footer}>
      <div className={styles.left}>{leftButton}</div>
      {pagination}
      <div className={styles.right}>
        {text}
        {buttonGroup && <ButtonGroup {...buttonGroup.props} size="small" />}
      </div>
    </div>
  );
};

ModalFooter.Pagination = PaginationDots;
ModalFooter.Text = ModalFooterText;
ModalFooter.LeftButton = Button;

function ModalFooterText({ children }: PropsWithChildren<{}>) {
  return <div className={styles.text}>{children}</div>;
}

function extractComponentChildren(children: ReactNode) {
  return extractChildren(children, {
    leftButton: Button,
    pagination: ModalFooter.Pagination,
    buttonGroup: ButtonGroup,
    text: ModalFooter.Text,
  });
}
