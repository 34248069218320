import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  type MutableRefObject,
  type ReactElement,
  forwardRef,
  useEffect,
} from 'react';

import { getDialogFirstFocusableChild } from 'common/components/design-system/utils';

/**
 * This is required because Ariakit mounts the Dialog component first with a dummy reference element without its actual content.
 * When the content is mounted this component sets the first focusable child by parsing an element dialogRef.
 */
export const DialogContentWrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ setInitialFocus: Dispatch<SetStateAction<HTMLElement | null>> }>
>(function DrawerContentWrapper({ children, setInitialFocus }, dialogRef) {
  useEffect(() => {
    const firstFocusableChild = getDialogFirstFocusableChild(
      (dialogRef as MutableRefObject<HTMLDivElement>).current,
    );
    setInitialFocus(firstFocusableChild);
  }, [dialogRef, setInitialFocus]);

  return children as ReactElement;
});
