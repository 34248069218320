import React, { type ComponentProps } from 'react';
import classnames from 'classnames';
import type { RequireExactlyOne } from 'type-fest';
import TooltipOverlayTrigger from 'common/components/ui/TooltipOverlayTrigger';
import { loadMaterialIconsStylesheet } from 'common/components/ui/Icon/material-icons'; // TODO: Do we need to support this??

import 'fonts/apollo-icons/apollo-icons.css';

import { APOLLO_ICONS_CODEPOINTS } from 'fonts/apollo-icons/apollo-icons';
import ApolloColoredIcons from 'common/components/ui/ApolloColoredIcons';

import type { AllIcons } from '$types/icon';

import styles from './LegacyIcon.module.scss';

/**
 * Returns the apollo-icon name of the a given icon if it exists in apollo-icon
 * @param {string} name
 *
 */
function getApolloIconName(name?: string): string | undefined {
  if (name && name in APOLLO_ICONS_CODEPOINTS) {
    return `apollo-icon-${name}`;
  }
}

export type IconColorStyle =
  | 'action-danger'
  | 'canvas-dark'
  | 'toast-success'
  | 'toast-warning'
  | 'toast-error'
  | 'status-success'
  | 'status-error'
  | 'blue'
  | 'light'
  | 'orange'
  | 'orange2'
  | 'gray4'
  | 'yellow';

type IconCoreProps = RequireExactlyOne<
  {
    name?: AllIcons;
    /**
     * tiny = 10px
     *
     * small = 12px
     *
     * small-medium = 14px
     *
     * medium = 18px
     *
     * medium-large = 30px
     *
     * large = 100px
     */
    size?: 'tiny' | 'small' | 'small-medium' | 'medium' | 'medium-large' | 'large';
    colorStyle?: IconColorStyle;
    className?: string;
    customSrc?: string;
    style?: React.CSSProperties;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    onMouseUp?: (e: React.MouseEvent<HTMLElement>) => void;
  },
  'name' | 'customSrc'
>;

function getIconName(name?: string) {
  switch (name) {
    case 'zp-icon-home':
      return 'home';
    case 'zp-icon-analytics':
      return 'chart-bar';
    case 'zp-icon-companies':
      return 'domain';
    case 'zp-icon-people':
      return 'account-multiple';
    case 'zp-icon-sequences':
      return 'send';
    case 'zp-icon-playbooks':
      return 'book-open-variant';
    case 'zp-icon-templates':
      return 'file-document-box';
    case 'zp-icon-outbox':
      return 'mail';
    case 'zp-icon-calls':
      return 'phone';
    case 'zp-icon-tasks':
      return 'checkbox-marked-outline';
    case 'zp-icon-settings':
      return 'settings';
    default:
      return name;
  }
}

const LegacyIconCore = React.forwardRef<HTMLElement, IconCoreProps>(function LegacyIconCore(
  props: IconCoreProps,
  ref,
) {
  const {
    className,
    colorStyle, // TODO: Consider removing this prop, or generalize to accept all colors
    size,
    name,
    customSrc, // TODO: Consider removing this prop after all custom icons are rolled back into icon library
    onClick, // TODO: Consider removing this prop
    onMouseUp, // TODO: Consider removing this prop
    ...rest
  } = props;

  React.useEffect(() => {
    if (name && !getApolloIconName(name)) {
      loadMaterialIconsStylesheet();
    }
  }, [name]);

  const apolloIconName = getApolloIconName(name);
  const internalName = getIconName(name);

  const isColoredApolloIcon = name && name in ApolloColoredIcons;
  const isApolloIcon = Boolean(apolloIconName) || isColoredApolloIcon;

  const classes = classnames(
    'zp-icon',
    !isApolloIcon && 'mdi',
    !isApolloIcon && Boolean(internalName) && `mdi-${internalName}`,

    isApolloIcon && 'apollo-icon',
    isColoredApolloIcon && 'apollo-colored-icon',
    !isColoredApolloIcon && apolloIconName,

    styles.default,
    colorStyle && styles[`color-style-${colorStyle}`],
    size === 'tiny' && styles.tiny,
    size === 'small' && styles.small,
    size === 'small-medium' && styles['small-medium'],
    size === 'medium' && styles.medium,
    size === 'medium-large' && styles['medium-large'],
    size === 'large' && styles.large,
    onClick && styles.clickable,
    className,
  );

  if (customSrc) {
    return <img src={customSrc} style={props.style} alt="" />;
  }

  const handleMouseUp = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onMouseUp?.(e);
  };

  if (isColoredApolloIcon) {
    return (
      <i className={classes} onMouseUp={handleMouseUp} ref={ref} onClick={onClick} {...rest}>
        {ApolloColoredIcons[name]}
      </i>
    );
  } else {
    return (
      <i className={classes} onMouseUp={handleMouseUp} ref={ref} onClick={onClick} {...rest} />
    );
  }
});

type IconProps = {
  tip?: ComponentProps<typeof TooltipOverlayTrigger>['tooltipContent'];
  interactiveTooltip?: ComponentProps<typeof TooltipOverlayTrigger>['interactive'];
  alt?: string;
  tipPlacement?: ComponentProps<typeof TooltipOverlayTrigger>['placement'];
} & IconCoreProps &
  Pick<
    ComponentProps<typeof TooltipOverlayTrigger>,
    'tooltipClassName' | 'tooltipWrapperClassName' | 'tooltipNoWrap'
  >;

const LegacyIcon = React.forwardRef<HTMLElement, IconProps>(function LegacyIcon(
  props: IconProps,
  ref,
) {
  const {
    tip,
    tooltipClassName,
    tipPlacement = 'bottom',
    tooltipNoWrap,
    interactiveTooltip,
    tooltipWrapperClassName,
    ...rest
  } = props;

  if (!props.name && !props.customSrc) {
    return null;
  }

  const icon = <LegacyIconCore {...rest} ref={ref} />;

  if (tip) {
    return (
      <TooltipOverlayTrigger
        placement={tipPlacement}
        tooltipContent={tip}
        tooltipClassName={tooltipClassName}
        tooltipNoWrap={tooltipNoWrap}
        interactive={interactiveTooltip}
        tooltipWrapperClassName={tooltipWrapperClassName}
      >
        {icon}
      </TooltipOverlayTrigger>
    );
  }

  return icon;
});

LegacyIcon.defaultProps = {
  interactiveTooltip: false,
};

export default LegacyIcon;
