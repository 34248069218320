/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { cloneDeep } from 'lodash-es';
import { isBefore } from 'date-fns';

import type { Team } from '$types/entities/team';

import { getCurrentSite, getCurrentUrl } from 'chrome-extension/inject/core/lib/locationUtils';
import { logExtensionErrorToSentry } from 'chrome-extension/inject/core/lib/sentryUtils';
import { encodeClassName } from 'chrome-extension/inject/core/lib/utils';
import { isTeamPaying } from 'app/utils/TeamUtils';
import {
  configBackup,
  type LinkedInSelectorsConfig,
  type StringKeyValue,
} from 'common/selectors/configManagementBackup';

export const getLinkedinPageModality = () => (isProfile() ? 'contact' : 'account');

/**
 * Current use case: make all LI overlays' z-index less than our Modal
 *  whenever a Modal is mounted and remove the overrides on Modal unmount.
 */

export const overrideLIOverlaysZIndex = ({
  zIndex = '19999',
  maybeRemoveOverride = false,
} = {}) => {
  const apolloOverlay = document.querySelector(encodeClassName('apollo-overlay-container'));
  const lushaOverlay = document.querySelector('#LU__extension_wrapper');

  if (maybeRemoveOverride) {
    if (apolloOverlay instanceof HTMLElement) {
      apolloOverlay.style.zIndex = '';
    }
    if (lushaOverlay instanceof HTMLElement) {
      lushaOverlay.style.zIndex = '';
    }
  } else {
    if (apolloOverlay instanceof HTMLElement) {
      apolloOverlay.style.zIndex = zIndex;
    }
    if (lushaOverlay instanceof HTMLElement) {
      lushaOverlay.style.zIndex = zIndex;
    }
  }
};

/**
 * This is used for old code which filters "LinkedIn Member" rows.
 *  Moving forward, we should find unique properties in the DOM
 *    and check those instead of looking at the text.
 */
const isLinkedinMember = {
  'LinkedIn Member': true,
  'عضو LinkedIn': true,
  'Člen LinkedIn': true,
  'LinkedIn-medlem': true,
  'LinkedIn Mitglied': true,
  'Miembro de LinkedIn': true,
  'Membre de LinkedIn': true,
  'Anggota LinkedIn': true,
  'Utente LinkedIn': true,
  LinkedInメンバー: true,
  'LinkedIn 회원': true,
  'Ahli LinkedIn': true,
  'LinkedIn-lid': true,
  'Użytkownik LinkedIn': true,
  'Usuário do LinkedIn': true,
  'Membru LinkedIn': true,
  'Участник LinkedIn': true,
  'Medlem på LinkedIn': true,
  'สมาชิก LinkedIn': true,
  'Miyembro ng LinkedIn': true,
  'LinkedIn Üyesi': true,
  领英会员: true,
  'LinkedIn 會員': true,
};

export function isKeyofIsLinkedinMember(
  name: string | undefined | null,
): name is keyof typeof isLinkedinMember {
  return !!name && name in isLinkedinMember;
}

export const NEW_SEARCH_PERSON_ROW_SELECTOR = '.reusable-search__result-container';

export function cleanHTML(strCode: string) {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = `<b>${strCode}</b>`;

  //The code section on linkedin is deceptive. It doesn't always match with what the users see.
  //DO NOT use this section for parsing!!
  tempDiv.querySelectorAll('script, meta, style, link, code, g').forEach((el) => el.remove());

  return tempDiv.innerHTML;
}

//sales navigaotor results
export function salesforceNavigatorSearchLoading(selectors: StringKeyValue) {
  const { WRAPPER_SELECTOR, RESULT_ELEMENT, CONTAINER_SELECTOR } = selectors;
  const wrapper = document.querySelector(WRAPPER_SELECTOR!);
  if (wrapper && !wrapper.classList.contains('hidden')) {
    //sales navigaotor results
    return true;
  }

  // new sales navigator results page (08.28.2018)
  const resultsEl = document.getElementById(RESULT_ELEMENT!);
  if (resultsEl?.classList.contains(CONTAINER_SELECTOR!)) {
    return true;
  }
  return false;
}

export function newSearchLoading(linkedinSelectors: LinkedInSelectorsConfig) {
  // 20180222JP: LI has at least two search locations with different markup.
  // The "index search" at https://www.linkedin.com/search/results/index
  // The "people search" at https://www.linkedin.com/search/results/people
  const commonSearchSelectors =
    linkedinSelectors.common_search ?? configBackup.extension_selectors.linkedin.common_search;
  const hasNoNewPeopleSearchResults = !document.querySelector(
    commonSearchSelectors.NEW_SEARCH_PERSON_ROW_SELECTOR ?? NEW_SEARCH_PERSON_ROW_SELECTOR,
  );
  if (isNewSearch() && hasNoNewPeopleSearchResults) {
    return true;
  }

  return false;
}

export function customProfileLoading(selectors: StringKeyValue) {
  return (
    profileDLoading(selectors!) ||
    profileFLoading(selectors!) ||
    profileHLoading(selectors) ||
    profileILoading(selectors)
  );
}

export function profileDLoading(selectors: StringKeyValue) {
  const { PROFILE_D_SELECTOR, PROFILE_D_LOADING } = selectors;
  if (isProfileD(PROFILE_D_SELECTOR!) && !document.querySelector(PROFILE_D_LOADING!)) {
    return true;
  }
  return false;
}

export function profileFLoading(selectors: StringKeyValue) {
  const { PROFILE_F_SELECTOR, PROFILE_F_LOADING } = selectors;
  if (isProfileF(PROFILE_F_SELECTOR!) && !document.querySelector(PROFILE_F_LOADING!)) {
    return true;
  }
  return false;
}

export function profileHLoading(selectors: StringKeyValue) {
  const {
    PROFILE_H_SELECTOR,
    PROFILE_H_LOADING_V1,
    PROFILE_H_LOADING_V2,
    PROFILE_H_LOADING_V3,
    PROFILE_H_LOADING_V4,
  } = selectors;
  /** This profile type has multiple versions of their Experience aka Companies container */
  if (
    isProfileH(PROFILE_H_SELECTOR!) &&
    !document.querySelector(PROFILE_H_LOADING_V1!) &&
    !document.querySelector(PROFILE_H_LOADING_V2!) &&
    !document.querySelector(PROFILE_H_LOADING_V3!) &&
    !!document.querySelector(PROFILE_H_LOADING_V4!)
  ) {
    return true;
  }
  return false;
}

export function profileILoading(selectors: StringKeyValue) {
  const {
    PROFILE_I_SELECTOR,
    PROFILE_I_LOADING_V1,
    PROFILE_I_LOADING_V2,
    PROFILE_I_LOADING_V3,
    PROFILE_I_LOADING_V4,
  } = selectors;
  /** This profile type has multiple versions of their Experience aka Companies container */
  if (
    isProfileI(PROFILE_I_SELECTOR!) &&
    !document.querySelector(PROFILE_I_LOADING_V1!) &&
    !document.querySelector(PROFILE_I_LOADING_V2!) &&
    !document.querySelector(PROFILE_I_LOADING_V3!) &&
    !!document.querySelector(PROFILE_I_LOADING_V4!)
  ) {
    return true;
  }
  return false;
}

// 20180531JP: LI throws up a ton of spinners, including ones that are not visible.
//  This approach checks to see if the main info has loaded, i.e. the top card and
//  positions. However, further sections below like recommendations may not be
//  loaded until the user scrolls them into view.
//
//  Remember that this needs to work for both direct and indirect views.
export function profileSalesNavigatorCLoading(selectors: StringKeyValue) {
  const { PROFILE_SN_LOADING } = selectors;
  if (!isSalesNavigatorProfile()) {
    return false;
  }
  return !!document.querySelector(PROFILE_SN_LOADING!);
}

// For tracking
/**
 *
 *
 */
export function getLinkedinVersion(
  baseUrl?: string,
): 'Sales Navigator' | 'Recruiter' | 'Regular' | 'None' {
  const url = baseUrl ?? getCurrentUrl().toLowerCase();
  const site = getCurrentSite(url);
  if (url.includes('linkedin.com/sales')) {
    return 'Sales Navigator';
  } else if (url.includes('linkedin.com/talent')) {
    return 'Recruiter';
  } else {
    if (site === 'linkedin') {
      return 'Regular';
    }
    return 'None';
  }
}

// For tracking
export function getLinkedinPage() {
  if (isFeed()) {
    return 'Feed Page';
  } else if (isSearch()) {
    return 'Search Results Page';
  } else if (isProfile()) {
    return 'Profile Page';
  } else if (isCompany()) {
    return 'Company Page';
  } else {
    return 'Other';
  }
}

/** For sentry logging */
export function getLinkedinSearchPage() {
  if (isNewSearch()) {
    return 'Regular LinkedIn Search Page';
  } else if (isCompanyPeople()) {
    return 'Regular LinkedIn Company People Page';
  } else if (isSalesNavigatorSearch()) {
    return 'Sales Navigator Search Page';
  } else if (isSalesLeadList()) {
    return 'Sales Navigator Leads List';
  } else if (isRecruiterSearchPage()) {
    return 'Recruiter Search Page';
  } else if (isRecruiterPipeline()) {
    return 'Recruiter Pipeline Page';
  } else {
    return 'PAGE NOT HANDLED - investigate soon';
  }
}

// For tracking (aka extensionPage - event property)
// 01-28-2021: Currently disabled because we are reaching our daily limit
// https://app.asana.com/0/1199685488717575/1199686634427378
export function getLinkedinOverlayPage() {
  /**
   * There is another name called "More Settings"
   *  but it is only triggered in LinkedinOverlay.
   */
  if (isSearch()) {
    return 'Bulk Prospect';
  } else if (isProfile()) {
    return 'Single Prospect';
  } else {
    return 'Placeholder';
  }
}

export function isFeed() {
  return isLinkedinFeed() || isSalesNavFeed();
}

export function isSearch() {
  return (
    isNewSearch() ||
    isSalesNavigatorSearch() ||
    isCompanyPeople() ||
    isSalesLeadList() ||
    isRecruiterTalentSearch()
  );
}

export function isProfile(url = getCurrentUrl()) {
  return (
    isInProfile(url) ||
    isSalesNavigatorProfile(url) ||
    isPubProfile(url) ||
    isRecruiterPersonProfile(url) ||
    isRecruiterPersonProfilePopUp(url)
  );
}

export function isCompany(url = getCurrentUrl()) {
  return (
    isSalesNavCompany(url) ||
    url.toLowerCase().includes('linkedin.com/company/') ||
    url.toLowerCase().includes('linkedin.com/school/')
  );
}

export function isLinkedinFeed() {
  return getCurrentUrl().toLowerCase().includes('linkedin.com/feed');
}

export function isSalesNavFeed() {
  return getCurrentUrl().toLowerCase().includes('linkedin.com/sales/homepage');
}

export function isSalesNavCompany(url = getCurrentUrl()) {
  return url.toLowerCase().includes('linkedin.com/sales/company');
}

export function isSalesNavigatorSearch() {
  return (
    getCurrentUrl().toLowerCase().includes('linkedin.com/sales/search') &&
    !getCurrentUrl().toLowerCase().includes('linkedin.com/sales/search/company')
  );
}

export function isSalesNavigatorSearchA(SEARCH_A_RESULT_CONTAINER: string) {
  return isSalesNavigatorSearch() && !!document.querySelector(SEARCH_A_RESULT_CONTAINER!);
}

// Newest Sales Navigator search page layout as of 01/04/2022
export function isSalesNavigatorSearchB(SEARCH_B_CONTAINER_SELECTOR: string) {
  return isSalesNavigatorSearch() && !!document.querySelector(SEARCH_B_CONTAINER_SELECTOR!);
}

// Newest Sales Navigator search page layout as of 11/15/2023
export function isSalesNavigatorSearchC(SEARCH_C_CONTAINER_SELECTOR: string) {
  return isSalesNavigatorSearch() && !!document.querySelector(SEARCH_C_CONTAINER_SELECTOR!);
}

export function isNewSearch() {
  return getCurrentUrl().toLowerCase().includes('linkedin.com/search/results/');
}

export function isIndexSearch() {
  return (
    getCurrentUrl().toLowerCase().includes('linkedin.com/search/results/index') ||
    getCurrentUrl().toLowerCase().includes('linkedin.com/search/results/all')
  );
}

export function isPeopleSearch() {
  return getCurrentUrl().toLowerCase().includes('linkedin.com/search/results/people');
}

export function isSalesNavigatorProfile(url = getCurrentUrl()) {
  return (
    url.toLowerCase().includes('linkedin.com/sales/profile') ||
    url.toLowerCase().includes('linkedin.com/sales/people') ||
    url.toLowerCase().includes('linkedin.com/sales/lead')
  );
}

export function isInProfile(url = getCurrentUrl()) {
  return url.toLowerCase().includes('linkedin.com/in/');
}

export function isProfileD(PROFILE_D_SELECTOR: string) {
  return isInProfile() && !!document.querySelector(PROFILE_D_SELECTOR!);
}

export function isProfileF(PROFILE_F_SELECTOR: string) {
  return isInProfile() && !!document.querySelector(PROFILE_F_SELECTOR!);
}

export function isProfileH(PROFILE_H_SELECTOR: string) {
  return isInProfile() && !!document.querySelector(PROFILE_H_SELECTOR!);
}

export function isProfileI(PROFILE_I_SELECTOR: string) {
  return isInProfile() && !!document.querySelector(PROFILE_I_SELECTOR!);
}

export function isPubProfile(url = getCurrentUrl()) {
  return url.toLowerCase().includes('linkedin.com/pub/');
}

export function isCompanyPeople(url = getCurrentUrl()) {
  const regex = /linkedin.com\/company\/.+\/people/g;
  return regex.test(url);
}

export function isSalesLeadList() {
  return getCurrentUrl().toLowerCase().includes('linkedin.com/sales/lists/people/');
}

export function isRecruiterSearchPage() {
  return getCurrentUrl().toLowerCase().includes('linkedin.com/talent/search');
}

export function isRecruiterPipeline() {
  return getCurrentUrl().toLowerCase().includes('linkedin.com/talent/hire');
}

export function isRecruiterSearch() {
  return isRecruiterSearchPage() || isRecruiterPipeline();
}

export function isRecruiterTalentSearch() {
  return isRecruiterSearch() && !isRecruiterPersonProfilePopUp();
}

export function isRecruiterPersonProfile(url = getCurrentUrl()) {
  return url.toLowerCase().includes('linkedin.com/talent/profile/');
}

export function isRecruiterPersonProfilePopUp(url = getCurrentUrl()) {
  return isRecruiterSearch() && url.toLowerCase().includes('/profile/');
}

function getOrganizationLinkedinUidFromUrl(linkedinUrl: string | null) {
  if (!linkedinUrl) {
    return;
  }

  return linkedinUrl.split('/company/')[1]?.split('/')[0]?.split('?')[0];
}

export function getInnerText(element: Element | null) {
  let text: string = '';
  try {
    if (element instanceof HTMLElement) {
      text = element.innerText.trim();
    }
  } catch (e) {
    // ignore error
  }

  return text;
}

export function obtainLinkedinPeopleJSONFromSearch(linkedinSelectors: LinkedInSelectorsConfig) {
  const output: {
    name?: string | null;
    activeIndex?: number;
    [key: string]: unknown;
  }[] = [];
  let pageName = '';
  let fieldHtml: NodeListOf<Element> | Element | undefined | null;
  if (isCompanyPeople()) {
    let organizationLinkedinUid: string | undefined;
    const {
      ROW_SELECTOR,
      COMPANY_PEOPLE_CARD,
      NAME_SELECTOR,
      HEADLINE_SELECTOR,
      LINK_SELECTOR,
      PHOTO_SELECTOR,
    } = linkedinSelectors.regular_company_people;
    const maybeOrgIdContainer = document.querySelector(ROW_SELECTOR!);
    if (maybeOrgIdContainer instanceof HTMLAnchorElement && maybeOrgIdContainer.href) {
      try {
        const url = new URL(maybeOrgIdContainer.href);
        organizationLinkedinUid = getOrganizationLinkedinUidFromUrl(url.searchParams.get('body'));
      } catch (e) {
        // ignore invalid url
      }
    }

    const newOrgIdContainer = document.querySelector('[href*="/search/results/people"]');
    if (newOrgIdContainer instanceof HTMLAnchorElement) {
      const url = new URL(newOrgIdContainer.href);

      if (url.searchParams.has('currentCompany')) {
        const currentCompany = url.searchParams.get('currentCompany');
        /**
         * * Sample url:
         * /search/results/people/?currentCompany=%5B%22165158%22%5D&origin=COMPANY_PAGE_CANNED_SEARCH
         *
         * * Actual org uid:
         * ...currentCompany=%5B%22----->165158<-----%22%5D&origin...
         *
         * The value of currentCompany is in the format ["123"] or [123], where 123 is the Org ID
         */
        organizationLinkedinUid = currentCompany?.split(/\["?/)[1]?.split(/"?\]/)[0];
      }
    }
    const items = document.querySelectorAll(COMPANY_PEOPLE_CARD!);
    pageName = 'Company People';
    fieldHtml = items;
    items.forEach((element) => {
      const name = getInnerText(element.querySelector(NAME_SELECTOR!));
      const headline = getInnerText(element.querySelector(HEADLINE_SELECTOR!));

      const link = element.querySelector(LINK_SELECTOR!);
      if (link instanceof HTMLAnchorElement) {
        const href = link?.href;

        const photo = element.querySelector(PHOTO_SELECTOR!);
        let photoUrl = null;

        if (photo instanceof HTMLImageElement) {
          photoUrl = photo?.src;
          photoUrl = !photoUrl || photoUrl.startsWith('data:') ? null : photoUrl;
        }

        output.push({
          href,
          name,
          headline,
          photoUrl,
          organizationLinkedinUid,
        });
      }
    });
  } else if (isSalesLeadList()) {
    const {
      ROW_SELECTOR,
      NAME_SELECTOR,
      PHOTO_LINK_SELECTOR,
      HEADLINE_SELECTOR,
      PRESENT_RAW_ADDRESS,
      PHOTO_SELECTOR,
      ORGANIZATION_NAME_SELECTOR,
    } = linkedinSelectors.salesnav_lead;
    pageName = 'Sales Lead List';
    fieldHtml = document.querySelectorAll(ROW_SELECTOR!);
    fieldHtml.forEach((element) => {
      const name = getInnerText(element.querySelector(NAME_SELECTOR!));

      const imageLink = element.querySelector(PHOTO_LINK_SELECTOR!);

      let href = null;
      if (imageLink instanceof HTMLAnchorElement) {
        href = imageLink?.href;
      }

      const title = getInnerText(element.querySelector(HEADLINE_SELECTOR!));
      const presentRawAddress = getInnerText(element.querySelector(PRESENT_RAW_ADDRESS!));

      const image = element.querySelector(PHOTO_SELECTOR!);
      let photoUrl = null;
      if (image instanceof HTMLImageElement) {
        photoUrl = image.src;
        photoUrl = photoUrl.startsWith('data:') ? null : photoUrl;
      }

      let organizationName = getInnerText(element.querySelector(ORGANIZATION_NAME_SELECTOR!));

      /** Examples: "Interco (+3)", "GL Enterprises , LLC (+2)" */
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (organizationName?.includes(' (+')) {
        organizationName = organizationName.split(' (+')[0]!;
      }

      output.push({
        href,
        name,
        title,
        presentRawAddress,
        photoUrl,
        organizationName,
      });
    });
  } else if (isRecruiterTalentSearch()) {
    pageName = 'Recruiter Talent Search';
    const {
      ROW_SELECTOR,
      LINK_SELECTOR,
      NAME_SELECTOR,
      EXPERIENCE_LIST_SELECTOR,
      EXPERIENCE_ITEM_SELECTOR,
      PRESENT_RAW_ADDRESS,
      PHOTO_SELECTOR,
      HEADLINE_SELECTOR,
    } = linkedinSelectors.recruiter_search;
    fieldHtml = document.querySelectorAll(ROW_SELECTOR!);
    fieldHtml.forEach((element) => {
      const link = element.querySelector(LINK_SELECTOR!);
      let href = null;
      if (link instanceof HTMLAnchorElement) {
        href = link.href;
      }
      const name = getInnerText(element.querySelector(NAME_SELECTOR!));
      let title;
      let organizationName;
      let headline;

      const experienceList = element.querySelectorAll(EXPERIENCE_LIST_SELECTOR!);
      const currentExperience = experienceList[0];
      let latestCompanyText = getInnerText(currentExperience?.querySelector('span') ?? null);
      // Below Condition is required because in the new LI Recruiter it's showing the years of experience like
      // "7+ years of Software Engineer experience"
      if (
        experienceList.length > 1 &&
        currentExperience?.querySelectorAll(EXPERIENCE_ITEM_SELECTOR!).length === 0
      ) {
        latestCompanyText = getInnerText(experienceList[1]?.querySelector('span') ?? null);
      }
      const presentRawAddress = getInnerText(element.querySelector(PRESENT_RAW_ADDRESS!));

      const image = element.querySelector(PHOTO_SELECTOR!);
      let photoUrl = null;
      if (image instanceof HTMLImageElement) {
        photoUrl = image.src;
        photoUrl = photoUrl?.startsWith('data:') ? null : photoUrl;
      }

      if (latestCompanyText) {
        title = latestCompanyText?.split(' at ')[0]?.trim();
        organizationName = latestCompanyText.split(' at ')[1]?.trim();
        output.push({
          href,
          name,
          title,
          organizationName,
          presentRawAddress,
          photoUrl,
        });
      } else {
        headline = getInnerText(element.querySelector(HEADLINE_SELECTOR!));
        output.push({
          href,
          name,
          headline,
          presentRawAddress,
          photoUrl,
        });
      }
    });
  } else if (isPeopleSearch() || isIndexSearch()) {
    const regularSearchSelectors =
      linkedinSelectors.regular_people_search ??
      configBackup.extension_selectors.linkedin.regular_people_search;
    const commonSearchSelectors =
      linkedinSelectors.common_search ?? configBackup.extension_selectors.linkedin.common_search;
    pageName = 'People Search/Index Search';
    fieldHtml = document.querySelectorAll(
      commonSearchSelectors.NEW_SEARCH_PERSON_ROW_SELECTOR ?? NEW_SEARCH_PERSON_ROW_SELECTOR,
    );
    fieldHtml.forEach((element) => {
      const hasPost = element.querySelector(regularSearchSelectors.HAS_POST!);
      const hasPeopleLink = element.querySelector('[href*="/in/"]');

      /**
       * Only parse rows with people link and no post since LinkedIn's new layout uses
       *  common styles regardless of search result type (People, Job, Company, etc.)
       */
      if (!hasPost && hasPeopleLink) {
        const name = getInnerText(element.querySelector(regularSearchSelectors.NAME_SELECTOR!));

        const headline = getInnerText(
          element.querySelector(regularSearchSelectors.HEADLINE_SELECTOR!),
        );

        const presentRawAddress = getInnerText(
          element.querySelector(regularSearchSelectors.PRESENT_RAW_ADDRESS!),
        );

        const link = element.querySelector(regularSearchSelectors.LINK_SELECTOR!);
        let href;
        if (link instanceof HTMLAnchorElement) {
          href = link.href;
        }

        let photoUrl;
        let image = element.querySelector(regularSearchSelectors.IMAGE_SELECTOR!);
        if (image instanceof HTMLImageElement) {
          photoUrl = image.src;
        }

        if (!photoUrl) {
          // Not getting all EntityPhoto-circle since they might not be related to photoUrl
          image = element.querySelector(regularSearchSelectors.IMAGE_SELECTOR_V2!);
          if (image instanceof HTMLImageElement) {
            photoUrl = image.src;
          }
        }

        /** No name with [aria-hidden="true"] == "LinkedIn Member" */
        output.push({
          href,
          name,
          headline,
          presentRawAddress,
          photoUrl,
        });
      }
    });
  } else {
    let activeIndex: number | undefined;
    let href: string | string[] | null | undefined;
    let name: string | undefined | null;
    let presentRawAddress: string | undefined;
    let photoUrl: string | string[] | undefined | null;
    let organizationName: string | undefined;
    let organizationLinkedinUid: string | undefined;
    let title: string | undefined;

    const {
      SEARCH_A_RESULT_CONTAINER,
      SEARCH_A_RESULT_ITEM,
      SEARCH_A_PROFILE_NAME,
      SEARCH_NAME_SELECTOR,
      PHOTO_A_SELECTOR,
      ORGANIZATION_A_SELECTOR,
      ORGANIZATION_NAME_A_SELECTOR,
      HIGHLIGHT_A_SELECTOR,
      TITLE_A_SELECTOR,
      ADDRESS_A_SELECTOR,
      SEARCH_B_CONTAINER_SELECTOR,
      SEARCH_C_CONTAINER_SELECTOR,
      SEARCH_B_ROW_SELECTOR,
      SEARCH_B_NAME_SELECTOR,
      SEARCH_B_NAME_TEXT_SELECTOR,
      PHOTO_B_SELECTOR,
      IMAGE_B_SELECTOR,
      ORGANIZATION_B_SELECTOR,
      ORGANIZATION_NAME_B_SELECTOR,
      ORGANIZATION_HREF_B_SELECTOR,
      TITLE_B_SELECTOR,
      TITLE_B_TEXT_SELECTOR,
      TITLE_C_TEXT_SELECTOR,
      ADDRESS_B_SELECTOR,
    } = linkedinSelectors.salesnav;
    if (isSalesNavigatorSearchA(SEARCH_A_RESULT_CONTAINER!)) {
      const resultContainerEl = document.querySelector(SEARCH_A_RESULT_CONTAINER!);
      pageName = 'Salesnavigator Search A';
      fieldHtml = resultContainerEl;
      resultContainerEl?.querySelectorAll(SEARCH_A_RESULT_ITEM!).forEach((element, i) => {
        const nameUrlEl = element.querySelector(SEARCH_NAME_SELECTOR!);
        if (!nameUrlEl) {
          return;
        }

        activeIndex = undefined;
        const checkboxEl = element.querySelector('input[type=checkbox]');
        if (checkboxEl instanceof HTMLInputElement) {
          activeIndex = checkboxEl?.checked ? i : undefined;
        }

        href = nameUrlEl.getAttribute('href');
        href = href ? `https://www.linkedin.com${href}` : undefined;

        name = element.querySelector(SEARCH_A_PROFILE_NAME!)?.textContent?.trim() ?? undefined;

        const photoEl = element.querySelector(PHOTO_A_SELECTOR!);
        photoUrl = photoEl ? photoEl.getAttribute('src') : undefined;

        const orgNameEl = element.querySelector(ORGANIZATION_A_SELECTOR!);
        organizationName = orgNameEl?.textContent?.trim() ?? undefined;

        let goTo: string | Element | null = orgNameEl
          ? orgNameEl.querySelector(ORGANIZATION_NAME_A_SELECTOR!)
          : null;
        goTo = goTo?.textContent?.trim() ?? null;

        if (organizationName && goTo && organizationName.includes(goTo)) {
          organizationName = organizationName?.split(goTo)?.[0]?.trim() ?? undefined;
        }

        let rawData;
        if (orgNameEl instanceof HTMLElement) {
          rawData = orgNameEl.dataset.entityHovercardId; // e.g. "urn:li:fs_salesCompany:57866"
        }

        organizationLinkedinUid = rawData?.split(':')?.[3] ?? undefined;

        const highlightEls = element.querySelectorAll(HIGHLIGHT_A_SELECTOR!);
        const titleEl = Array.from(highlightEls).find(
          (el) => !el.classList.contains(TITLE_A_SELECTOR!),
        );
        title = titleEl?.textContent?.trim() ?? undefined;

        const miscItems = element.querySelectorAll(ADDRESS_A_SELECTOR!);
        const addressEl = Array.from(miscItems).find((el) => el?.textContent?.includes(' Area'));
        presentRawAddress = addressEl?.textContent?.trim() ?? undefined;

        /** No checkbox in Sales Nav == not employed / we are unable to parse */
        if (checkboxEl) {
          output.push({
            activeIndex,
            href,
            name,
            presentRawAddress,
            organizationName,
            organizationLinkedinUid,
            title,
            photoUrl:
              photoUrl && !photoUrl.includes('ghosts/person/ghost_person') ? photoUrl : null,
          });
        }
      });

      return output.filter((item) => !isKeyofIsLinkedinMember(item.name));
    }

    let containerSelector = '';

    if (isSalesNavigatorSearchB(SEARCH_B_CONTAINER_SELECTOR!)) {
      containerSelector = SEARCH_B_CONTAINER_SELECTOR!;
    } else if (isSalesNavigatorSearchC(SEARCH_C_CONTAINER_SELECTOR!)) {
      containerSelector = SEARCH_C_CONTAINER_SELECTOR!;
    }

    if (containerSelector) {
      const salesNavigatorBContainer = document.querySelector(containerSelector);
      pageName = 'Salesnavigator Search B';
      fieldHtml = salesNavigatorBContainer?.querySelectorAll(SEARCH_B_ROW_SELECTOR!);
      fieldHtml?.forEach((element) => {
        const nameUrlEl = element.querySelector(SEARCH_B_NAME_SELECTOR!);

        const checkboxEl = element.querySelector('input[type=checkbox]');
        if (!nameUrlEl || !checkboxEl) {
          // If there is no link or checkbox, the lead is private or not loaded yet
          return;
        }

        href = nameUrlEl.getAttribute('href');
        if (href && !href.includes('linkedin.com')) {
          href = `https://www.linkedin.com${href}`;
        }

        name = element.querySelector(SEARCH_B_NAME_TEXT_SELECTOR!)?.textContent?.trim();

        photoUrl = element.querySelector(PHOTO_B_SELECTOR!)?.getAttribute('src');

        if (!photoUrl) {
          photoUrl = element.querySelector(IMAGE_B_SELECTOR!)?.getAttribute('src');
        }

        const orgNameEl = element.querySelector(ORGANIZATION_B_SELECTOR!);
        organizationName = orgNameEl?.textContent?.trim();

        if (!organizationName) {
          element.querySelector(ORGANIZATION_NAME_B_SELECTOR!)?.childNodes.forEach((el) => {
            if (
              el.nodeType === Node.TEXT_NODE &&
              el.textContent?.replaceAll(/\n/g, '').trim() !== ''
            ) {
              organizationName = el.textContent?.trim();
            }
          });
        }

        const orgHref =
          orgNameEl?.getAttribute(ORGANIZATION_HREF_B_SELECTOR!) ??
          orgNameEl?.getAttribute('href') ??
          '';
        const orgHrefArr = orgHref.split('?');
        const orgPath = orgHrefArr[0]; // href minus the parameters, ex: "/sales/company/33194934"
        organizationLinkedinUid =
          orgPath && orgPath.length > 0 ? orgPath?.substring(15) : undefined; // substring removes "/sales/company/"

        // There are two child spans - the first one is a separator--middot, the other is the actual title with no attributes
        title = element.querySelector(TITLE_B_SELECTOR!)?.textContent?.trim();

        if (!title) {
          // Similar layout here except the title comes first and then the separator
          title = element.querySelector(TITLE_B_TEXT_SELECTOR!)?.textContent?.trim();
        }

        if (!title) {
          // Same format above but different DOM
          title = element.querySelector(TITLE_C_TEXT_SELECTOR!)?.textContent?.trim();
        }

        presentRawAddress = element.querySelector(ADDRESS_B_SELECTOR!)?.textContent?.trim();

        output.push({
          href,
          name,
          photoUrl,
          organizationName,
          organizationLinkedinUid,
          title,
          presentRawAddress,
        });
      });
    }
  }
  const emptyKeys: string[] = [];
  const count: { [key: string]: number } = {};
  type KeyType = {
    [key: string]: unknown;
  };
  // obtaining all empty keys from Output array.
  let tempOutput: KeyType[] = cloneDeep(output);
  tempOutput = tempOutput.map((key: KeyType) => {
    const updatedKey = { ...key };
    delete updatedKey.organizationLinkedinUid;
    delete updatedKey.href;
    delete updatedKey.photoUrl;
    return updatedKey;
  });

  tempOutput.map((item) => {
    Object.keys(item).map((key) => {
      if (!item[key] && key !== 'href' && key !== 'photourl') {
        emptyKeys.push(key);
      }
    });
  });
  // counting the repeted keys value
  emptyKeys.forEach((i) => {
    count[i] = (count[i] ?? 0) + 1;
  });
  // checking if any of the repeated key is equal to the main array length,
  // if so then sending sentry error.
  Object.keys(count).map((finalItem) => {
    if (count[finalItem] === tempOutput.length) {
      let extraFieldHtml: Element[] = [];
      if (fieldHtml instanceof Array) {
        extraFieldHtml = fieldHtml;
      }
      logExtensionErrorToSentry(
        {
          error: '[BD] Error while parsing the html in ' + pageName,
          extraFields: {
            fieldHtml: Array.from(extraFieldHtml).map((element: Element) => element.innerHTML),
            finalItem,
            pageName,
          },
        },
        //do not remove the second parameter,
        // it's being used for throttling and will impact huge on sentry if removed without making changes in core sentry event
        {
          pageName,
          finalItem: [finalItem],
        },
      );
    }
  });
  return output.filter((item) => {
    return !isKeyofIsLinkedinMember(item.name);
  });
}

/** For new regular LI layout */
export function shouldRenderBulkSelect(
  newInjectedElements: boolean = false,
  linkedinSelectors: LinkedInSelectorsConfig,
) {
  if (newInjectedElements) {
    return false;
  }
  const commonSearchSelectors =
    linkedinSelectors.common_search ?? configBackup.extension_selectors.linkedin.common_search;
  const newSearchPeopleRows = document.querySelectorAll(
    commonSearchSelectors.NEW_SEARCH_PERSON_ROW_SELECTOR ?? NEW_SEARCH_PERSON_ROW_SELECTOR,
  );
  const newSearchPeopleRowsArr = Array.from(newSearchPeopleRows);

  /** Do not render if title-bars exist like Company / Job / School / etc. */
  if (!!document.querySelector(commonSearchSelectors.NON_PEOPLE_TITLE!) && !isPeopleSearch()) {
    return false;
  }

  /** Ensures there are no Company / Job / School / etc. rows */
  const everyRowHasPeopleLink = newSearchPeopleRowsArr.every((element) => {
    const name = element.querySelector(commonSearchSelectors.ROW_NAME!)?.textContent?.trim() ?? '';

    /**
     * Count "LinkedIn Member" row names as People since in the new layout
     *  sometimes the row does not have a people link and clicking on the name
     *  will open a popup saying "You don't have access to this profile". This popup
     *  shows when the profile is private and you don't have any mutual connections.
     *
     * The second check is similar to "LinkedIn Member". It's a CTA for upgrading to Premium.
     *  We don't parse it, but we want the bulk select injection to continue if it exists.
     */
    return (
      isKeyofIsLinkedinMember(name) ||
      !!element.querySelector(commonSearchSelectors.LINKEDIN_MEMBER!) ||
      !!element.querySelector(commonSearchSelectors.LINKEDIN_PREMIUM!) ||
      !!element.querySelector('[href*="/in/"]')
    );
  });

  /** Don't render bulk select if one row has a post (posts contain people links) */
  const oneRowHasPost = newSearchPeopleRowsArr.some((element) => {
    return !!element.querySelector(commonSearchSelectors.ROW_RESULT_CONTAINER!);
  });

  return !oneRowHasPost && everyRowHasPeopleLink;
}

export function shouldInjectBulkSelect(linkedinSelectors: LinkedInSelectorsConfig) {
  return (
    isPeopleSearch() ||
    (isIndexSearch() && shouldRenderBulkSelect(false, linkedinSelectors)) ||
    isCompanyPeople() ||
    isSalesNavigatorSearch() ||
    isSalesLeadList() ||
    isRecruiterTalentSearch()
  );
}

export function hasBulkSelectPanel(selector: LinkedInSelectorsConfig) {
  const { BULK_PANEL_SELECTOR_A, BULK_PANEL_SELECTOR_B, BULK_PANEL_SELECTOR_C } =
    selector.common_functions;
  return (
    !!document.querySelector(BULK_PANEL_SELECTOR_A!) ||
    !!document.querySelector(BULK_PANEL_SELECTOR_B!) ||
    !!document.querySelector(BULK_PANEL_SELECTOR_C!)
  );
}

export function shouldInjectBulkSelectElements(
  currentTeam: Team | undefined,
  linkedinSearchRemoveButtons: boolean,
  extensionLinkedinInjectedElements?: boolean,
) {
  if (!currentTeam) {
    return false;
  }

  if (extensionLinkedinInjectedElements) {
    return true;
  }

  const splitTestCutoffDate = '2024-03-04T12:16:20.717Z';

  const isTeamCreatedBeforeCutoffDate = isBefore(
    new Date(currentTeam.createdAt),
    new Date(splitTestCutoffDate),
  );

  const isPaidTeam = isTeamPaying(currentTeam);
  if (currentTeam?.canAccessBulkSelectOnExtension && !linkedinSearchRemoveButtons) {
    return true;
  } else if (isTeamCreatedBeforeCutoffDate && !linkedinSearchRemoveButtons && isPaidTeam) {
    return true;
  } else if (linkedinSearchRemoveButtons || !isPaidTeam) {
    return false;
  } else if (currentTeam?.canAccessBulkSelectOnExtension) {
    return true;
  } else {
    return false;
  }
}

export const getProfilePageImage = () => {
  const profileImage = document
    ?.querySelector(
      '.pv-top-card__non-self-photo-wrapper .pv-top-card-profile-picture__image--show, .profile-photo-edit img',
    )
    ?.getAttribute('src');
  return profileImage;
};
