import { debounce } from 'lodash-es';
import reactTriggerChange from 'react-trigger-change';
import { fetchFormDetails } from './meetings-widget.requests';
import { emailRegex } from './meetings-widget.utils';
import type { FormEnrichmentResponseData } from './meetings-widget.types';

const EXCLUDED_INPUT_TYPES = ['email', 'hidden', 'button', 'submit'];

const EXCLUDED_FORM_SHORTENING_INPUT_TYPES = [
  ...EXCLUDED_INPUT_TYPES,
  'checkbox',
  'radio',
  'select-one',
  'select-multiple',
];

// utils
const checkIfHubspotAPIExists = () => typeof window.HubSpotForms !== 'undefined';

const getDOMToUse = () => {
  if (checkIfHubspotAPIExists()) {
    const hubspotIframeDOM: HTMLIFrameElement | null = document.querySelector('.hs-form-iframe');
    if (hubspotIframeDOM?.contentDocument) {
      return hubspotIframeDOM.contentDocument;
    }
  }
  return document;
};

const getFormDOMToUse = () => {
  const { formEnrichmentElement } = window.ApolloMeetings?._appConfig ?? {};

  const formDOM = getDOMToUse();

  return formEnrichmentElement ?? formDOM.querySelector('form');
};

const getFormElements = () => {
  const formDOM = getFormDOMToUse();

  const allFormInputs:
    | NodeListOf<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    | undefined = formDOM?.querySelectorAll('input, textarea, select');

  return allFormInputs;
};

const toggleElementHide = (element: HTMLElement, hide: boolean = false): void => {
  /* eslint-disable no-param-reassign */

  const formDOM = getFormDOMToUse();

  const labelForInput = formDOM?.querySelector(`label[for="${element.id}"]`);

  // If the label is not found, try to find a label wrapping the input
  const labelWrappedInput = element.closest('label');

  // Use the found label
  const label = labelForInput ?? labelWrappedInput;

  if (hide) {
    element.style.display = 'none';
    if (label) {
      label.style.display = 'none';
    }
  } else {
    element.style.display = '';
    if (label) {
      label.style.display = '';
    }
  }
  reactTriggerChange(element);
};

// form handlers
const handleFormEnrichmentSuccess = (
  responseData: FormEnrichmentResponseData = { guestResponseMapping: [] },
  isFormShorteningEnabled: boolean = false,
) => {
  const formDOM = getFormDOMToUse();

  const mappingData = responseData.guestResponseMapping;

  const allFormInputs = getFormElements();

  allFormInputs?.forEach((input: HTMLInputElement) => {
    if (EXCLUDED_INPUT_TYPES.includes(input.type)) {
      return;
    }

    const fieldFromMapping = Object.values(mappingData).find(
      (data) => data.questionExternalField === input.name,
    );

    if (fieldFromMapping?.questionResponse) {
      if (['text', 'textarea'].includes(input.type)) {
        input.value = fieldFromMapping.questionResponse;
        reactTriggerChange(input);
      }

      if (['radio', 'checkbox'].includes(input.type)) {
        const allInputsWithSameName = formDOM?.querySelectorAll(
          `[name="${fieldFromMapping.questionExternalField}"]`,
        );

        allInputsWithSameName?.forEach((ip: HTMLInputElement) => {
          if (ip.checked) {
            ip.checked = false;
            reactTriggerChange(ip);
          }

          const isResponseArray = Array.isArray(fieldFromMapping.questionResponse);
          const valueReceived = isResponseArray
            ? fieldFromMapping.questionResponse
            : [fieldFromMapping.questionResponse];

          if (!ip.checked && valueReceived.includes(ip.value)) {
            ip.checked = true;
            // eslint-disable-next-line no-param-reassign, no-self-assign
            ip.value = ip.value;
            reactTriggerChange(ip);
          }
        });
      }

      if (input.tagName === 'SELECT') {
        const lowerCaseQuestionResponse = Array.isArray(fieldFromMapping.questionResponse)
          ? fieldFromMapping.questionResponse?.map((val) => val.toLowerCase())
          : fieldFromMapping.questionResponse?.toLowerCase();

        const hasValidOption = Array.from(input.querySelectorAll('option'))?.some(
          (option: HTMLOptionElement) =>
            Array.isArray(fieldFromMapping.questionResponse)
              ? lowerCaseQuestionResponse?.includes(option.value.toLowerCase())
              : option.value.toLowerCase() === lowerCaseQuestionResponse,
        );

        if (hasValidOption) {
          input.value = fieldFromMapping.questionResponse;
          reactTriggerChange(input);
        }
      }
    } else if (
      isFormShorteningEnabled &&
      !EXCLUDED_FORM_SHORTENING_INPUT_TYPES.includes(input.type)
    ) {
      // Hack: first input element label was not showing up again
      setTimeout(() => {
        toggleElementHide(input, false);
      }, 0);
    }
  });
};

const handleEmailChangeDebounced = debounce((event, isFormShorteningEnabled) => {
  const emailValue = event.target.value;

  if (!emailValue.match(emailRegex)) {
    return;
  }

  fetchFormDetails({
    email: emailValue,
    schedulingLink: window.ApolloMeetings?._appConfig.schedulingLink,
    onSuccess: (resp: FormEnrichmentResponseData) =>
      handleFormEnrichmentSuccess(resp, isFormShorteningEnabled),
  });
}, 300);

export const initFormEnrichment = ({ is_shortening_enabled: isFormShorteningEnabled = false }) => {
  const formDOM = getFormDOMToUse();
  const formElements = getFormElements();

  if (isFormShorteningEnabled && formElements?.length > 0) {
    formElements?.forEach((ele) => {
      if (EXCLUDED_FORM_SHORTENING_INPUT_TYPES.includes(ele.type)) {
        return;
      }
      toggleElementHide(ele, true);
    });
  }

  if (!formDOM) {
    return console.error('Could not find valid form element');
  }

  const emailInputDOM = formDOM.querySelector('input[type="email"]');

  if (emailInputDOM) {
    emailInputDOM.addEventListener('input', (event) =>
      handleEmailChangeDebounced(event, isFormShorteningEnabled),
    );
  } else {
    console.error('Could not find valid email input');
  }
};
