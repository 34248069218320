let loaded = false;

export function loadMaterialIconsStylesheet() {
  if (loaded) {
    return;
  }

  loaded = true;

  import(/* webpackChunkName: "materialdesignicons" */ '@mdi/font/scss/materialdesignicons.scss');
}
