import { Children } from 'react';

/**
 * Tells whether something a renderable React child
 * @param {unknown} child
 *
 */
function isRenderableChild(child: unknown): boolean {
  /**
   * https://reactjs.org/docs/jsx-in-depth.html#booleans-null-and-undefined-are-ignored
   */

  return !(
    child === undefined ||
    child === null ||
    typeof child === 'boolean' ||
    (typeof child === 'string' && child.trim() === '')
  );
}

/**
 * Tells whether the direct children are renderable
 * @param {unknown} children
 *
 */
export function hasRenderableDirectChildren(children: unknown): boolean {
  const childrenAsArray = Array.isArray(children) ? children : Children.toArray(children);

  return childrenAsArray.some((child) => {
    return isRenderableChild(child);
  });
}
