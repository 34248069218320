import { type ComponentProps, forwardRef } from 'react';
import * as Ariakit from '@ariakit/react';
import classNames from 'classnames';

import styles from './Tabs.module.scss';

export type TabListProps = ComponentProps<typeof Ariakit.TabList>;

/**
 * Style wrapper of TabList component from Ariakit Library
 * @see https://ariakit.org/reference/tab-list
 *
 * @example
 * ```html
      <TabList>
        <Tab>Tab 1</Tab>
        <Tab>Tab 2</Tab>
      </TabList>
   * ```
 */
export const TabList = forwardRef<HTMLDivElement, TabListProps>(function TabList(props, ref) {
  return (
    <Ariakit.TabList ref={ref} {...props} className={classNames(styles.tabs, props.className)} />
  );
});
