import { useState } from 'react';
import { Modal } from 'common/components/design-system/modal/Modal';
import { sanitizeHtmlString } from 'app/utils/HTMLUtils';

const IFRAME_STYLE = { border: 'none', width: '100%', height: '360px' };

export const RateLimitDialog = ({ html }: { html: string }) => {
  const [showModal, setShowModal] = useState(!!html);

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)} size="large" unmountOnHide>
      <Modal.Header />
      <Modal.Content>
        <iframe title="modal" srcDoc={sanitizeHtmlString(html)} style={IFRAME_STYLE} sandbox="" />
      </Modal.Content>
    </Modal>
  );
};
