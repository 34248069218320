/**
 * Figma: https://www.figma.com/file/HnYrd6FfB4O1VUV9GuuWe6/Components?type=design&node-id=7%3A101&mode=design&t=RHVXEqaqjOaJ5K7w-1
 */

import { type ComponentProps, type ElementType, forwardRef } from 'react';
import type { AriaButtonOptions } from 'react-aria';

// common components
import { UnstyledButton } from 'common/components/design-system/core/button/UnstyledButton';
import { TooltipRenderer } from 'common/components/design-system/tooltip/TooltipRenderer';
import { BadgeContent } from './BadgeContent';
import Icon from 'common/components/design-system/icon/Icon';

// types
import type { BadgeVariantType } from './types';
import type { AllIcons } from '$types/icon';
import classNames from 'classnames';

// styles
import styles from './Badge.module.scss';

export type { BadgeVariantType };

export type BadgeProps = {
  /**
   * Label for the badge.
   */
  label?: string;

  /**
   * Visual variant of the badge.
   *
   * @default primary
   */
  variant?: BadgeVariantType;

  /**
   * Optional left icon for the badge.
   *
   * @default undefined
   */
  leftIconName?: AllIcons;

  /**
   * Indicates whether to show a close button for an interactive badge.
   *
   * @default false
   */
  showCloseButton?: boolean;

  /**
   * A callback function to handle badge dismissal.
   *
   * @returns void
   */
  onRemove?: AriaButtonOptions<ElementType>['onPress'];

  /**
   * The destination link within the app (for interactive badges).
   *
   * @default undefined
   */
  to?: string;

  /**
   * The destination link outside the app (for interactive badges).
   *
   * @default undefined
   */
  href?: string;

  /**
   * A callback function to handle badge click events.
   *
   * @returns void
   */
  onClick?: ComponentProps<typeof UnstyledButton>['onClick'];

  /**
   * Tooltip content to display when hovering over the badge.
   *
   * @default undefined
   */
  tooltip?: ComponentProps<typeof TooltipRenderer>['tooltip'];

  /**
   * The count to display on the badge.
   *
   * @default undefined
   */
  count?: number | string;

  /**
   * Pass true for interactive badges
   *
   * @default false
   */
  interactive?: boolean;

  /**
   * Pass true for dot in badges
   *
   * @default false
   */
  showBadgeDot?: boolean;
  /**
   * The variant of the illustrative badge. Currently, only 'credits' is supported.
   * @default credits
   */
  illustrativeVariant?: 'credits';

  /**
   * The type of the illustrative badge. 'default' is the expanded version, 'count' is the condensed version.
   * @default default
   */
  illustrativeType?: 'default' | 'count';

  /**
   * The label of the illustrative badge.
   */
  illustrativeLabel?: string;
  /**
   * Disables text truncation for the badge.
   * @default false
   * */
  disableTextTruncate?: boolean;
} & Pick<ComponentProps<'div'>, 'id'>;

const getImage = (type: BadgeProps['illustrativeType']): AllIcons => {
  switch (type) {
    case 'count':
      return 'single-credit';
    default:
      return 'multi-credit';
  }
};

const getIconStyles = (illustrativeVariant: BadgeProps['illustrativeVariant']) => {
  switch (illustrativeVariant) {
    case 'credits':
      return styles.creditsIcon;
    default:
      return '';
  }
};

/**
 * A small visual element used to concisely and eye-catching display important information, such as categories, statuses, or notifications
 */
export const Badge = forwardRef<HTMLButtonElement | HTMLAnchorElement, BadgeProps>(function Badge(
  {
    label = '',
    variant = 'primary',
    leftIconName,
    showCloseButton = false,
    onRemove = () => {},
    onClick,
    to,
    href,
    tooltip = null,
    count,
    interactive = false,
    id,
    showBadgeDot = false,
    illustrativeVariant,
    illustrativeType,
    illustrativeLabel,
    disableTextTruncate = false,
    ...rest
  }: BadgeProps,
  ref,
) {
  if (illustrativeVariant && interactive) {
    throw new Error('Illustrative Badge cannot be interactive.');
  }

  const badgeContent = illustrativeVariant ? (
    <TooltipRenderer tooltip={tooltip}>
      <div className={classNames(styles.wrapper, styles[illustrativeVariant])}>
        <Icon icon={getImage(illustrativeType)} className={getIconStyles(illustrativeVariant)} />
        <span className={styles.label}>{illustrativeLabel}</span>
      </div>
    </TooltipRenderer>
  ) : (
    <BadgeContent
      label={label}
      variant={variant}
      leftIconName={leftIconName}
      showCloseButton={showCloseButton}
      interactive={interactive}
      count={count}
      onRemove={onRemove}
      tooltip={tooltip}
      showBadgeDot={showBadgeDot}
      disableTextTruncate={disableTextTruncate}
    />
  );

  return interactive ? (
    <UnstyledButton
      to={to}
      href={href}
      onClick={onClick}
      className={styles.badgeWrapper}
      ref={ref}
      id={id}
      {...rest}
    >
      {badgeContent}
    </UnstyledButton>
  ) : (
    badgeContent
  );
});
