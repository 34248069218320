// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_mE7no{align-items:center;background:var(--color-interactives-utility-background-3);border-radius:1em;color:var(--color-ui-text-base-secondary);display:inline-flex;gap:.3333333333em;justify-content:center;padding:0 .3333333333em;text-transform:uppercase}.zp_mE7no.zp_tCy0M{color:var(--color-ui-text-base-primary-inverse)}.zp_mE7no,.zp_mE7no[data-count-size=small]{font-size:10px;font-weight:var(--typeface-base-weight-semibold-600);line-height:12px;font-weight:var(--typeface-base-weight-regular-400);height:1.3333333333em;min-width:1.3333333333em}.zp_mE7no[data-count-size=medium]{font-size:12px;font-weight:var(--typeface-base-weight-semibold-600);line-height:14px;font-weight:var(--typeface-base-weight-regular-400);height:1.6666666667em;min-width:1.6666666667em}.zp_mE7no[data-count-size=large]{font-size:14px;font-weight:var(--typeface-base-weight-semibold-600);line-height:16px;font-weight:var(--typeface-base-weight-regular-400);gap:.2857142857em;height:1.7142857143em;min-width:1.7142857143em;padding:0 .5714285714em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"count": "zp_mE7no",
	"reversed": "zp_tCy0M"
};
export default ___CSS_LOADER_EXPORT___;
