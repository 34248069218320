/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable leadgenie/prevent-context-provider-for-design-system-components */
import React, {
  type ComponentProps,
  type PropsWithChildren,
  createContext,
  forwardRef,
} from 'react';

import type { To } from 'common/components/design-system/types/lib/react-router-dom.types';

type LinkProps = PropsWithChildren<
  ComponentProps<'a'> & {
    to?: To;
  }
>;

const DefaultLinkComponent = forwardRef<HTMLAnchorElement, LinkProps>(function DefaultLinkComponent(
  props,
  ref,
) {
  const { to, onClick, children, ...restProps } = props;
  return (
    <a
      {...restProps}
      ref={ref}
      href={to as string}
      target={to ? '_self' : '_blank'}
      rel="noreferrer"
      onClick={(e) => {
        if (typeof onClick === 'function') {
          onClick(e);
        }
        if (!e.defaultPrevented) {
          console.warn(
            `RouterProvider is not configured. Navigation will not be performed. Arguments sent: ${JSON.stringify(
              { to },
            )}`,
          );
        }
      }}
    >
      {children}
    </a>
  );
});

export const RouterProviderContext = createContext(DefaultLinkComponent);

type RouterContextType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Link: any;
};

type RouterProviderProps = PropsWithChildren<RouterContextType>;

/**
 *
 * The RouterProvider component accepts: Link. `Link` should be the link component provided by your router. This system allows the consumers to use any routing library.
 *
 * Component inspired from react-aria: https://react-spectrum.adobe.com/react-aria/routing.html
 *
 * @example
 * ```
 * function App() {
 *   return <RouterProvider Link={RouterLink}>...</RouterProvider>
 * }
 * ```
 *
 */
export function RouterProvider({ Link, children }: RouterProviderProps) {
  return <RouterProviderContext.Provider value={Link}>{children}</RouterProviderContext.Provider>;
}
