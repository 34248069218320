import { LATEST_EXTENSION_VERSION } from 'chrome-extension/constants';

export function isVersionNewerThanOrEqualTo(ver: string, extensionVersion?: string) {
  if (!extensionVersion) {
    return false;
  }

  const [major, minor, patch] = extensionVersion.split('.');
  const [major2, minor2, patch2] = ver.split('.');

  return (
    Number(major) >= Number(major2) &&
    Number(minor) >= Number(minor2) &&
    Number(patch) >= Number(patch2)
  );
}

export const isChromeExtension = () => Boolean(window.chrome && window.chrome.extension);

export const isIframeSidebar = () => {
  const location = window.location.href;
  return (
    location.includes('linkedin-sidebar') ||
    location.includes('sidebar-main') ||
    location.includes('salesforce-sidebar') ||
    location.includes('gcal-sidebar') ||
    location.includes('hubspot-sidebar')
  );
};

export const getExtensionContext = () => {
  if (typeof window === 'undefined') {
    return 'service_worker';
  }

  const { href } = window.location;
  if (isChromeExtension()) {
    if (href.startsWith('chrome') && !isIframeSidebar()) {
      return 'extension';
    } else if (/^https?/.test(href) || isIframeSidebar()) {
      return 'content';
    }
  }

  return null;
};

export const isExtensionVersionUpdated = (
  currentVersion?: string,
  expectedVersion = LATEST_EXTENSION_VERSION,
) => {
  // if there's no extension installed, no need to flag it as outdated
  if (!currentVersion || currentVersion === expectedVersion) {
    return true;
  }

  const currentVersionArrayForm = currentVersion.split('.').map(Number);
  const expectedVersionArrayForm = expectedVersion.split('.').map(Number);

  const length = Math.max(currentVersionArrayForm.length, expectedVersionArrayForm.length);

  for (let i = 0; i < length; i++) {
    const current = currentVersionArrayForm[i] ?? 0;
    const expected = expectedVersionArrayForm[i] ?? 0;

    if (current > expected) {
      return true;
    }
  }
  return false;
};

export const isExtensionServiceWorker = getExtensionContext() === 'service_worker';

export function getChromeExtensionVersion() {
  return process.env.CHROME_EXTENSION_VERSION ?? '';
}

export function encodeClassName(className: string, excludeDot?: boolean) {
  const encodedVersion = process.env.CHROME_EXTENSION_VERSION?.replace(/\./g, '-');
  return `${excludeDot ? '' : '.'}zp-${encodedVersion}-${className}`;
}

export function getExtensionUserCounter<T>(obj: T, key: string) {
  return obj[key as keyof T] as number;
}
