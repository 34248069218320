import * as Ariakit from '@ariakit/react';

export interface TabProviderProps<T>
  extends Omit<Ariakit.TabProviderProps, 'selectedId' | 'setSelectedId'> {
  selectedId?: T;
  setSelectedId?: (selectedId: T) => void;
}

/**
 * Re-export of TabProvider from Ariakit library
 * @see https://ariakit.org/reference/tab-provider
 */
export function TabProvider<T>(props: TabProviderProps<T>) {
  return (
    <Ariakit.TabProvider
      {...props}
      selectedId={props.selectedId as string}
      setSelectedId={(selectedId) => props.setSelectedId?.(selectedId as T)}
    />
  );
}
