export type ApolloIconsId =
  | '0-5x'
  | '1-circle'
  | '1-2x'
  | '1-5x'
  | '1-7x'
  | '1x'
  | '2-circle'
  | '2-2x'
  | '2-5x'
  | '2x'
  | '3-circle'
  | 'add-to-playlist'
  | 'ai-email'
  | 'ai-personalization'
  | 'ai-stars-filled'
  | 'ai-stars-outline'
  | 'ai-stars'
  | 'alert-bell'
  | 'alert-circle'
  | 'alert-triangle'
  | 'alexa'
  | 'angel-list'
  | 'apollo-ai'
  | 'apollo-full-logo'
  | 'apollo-logo'
  | 'apollo'
  | 'archive-box'
  | 'area-chart'
  | 'arrow-corner-up-right'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up-circle'
  | 'arrow-up'
  | 'arrows-merge'
  | 'associate'
  | 'association'
  | 'auto-flash'
  | 'ban'
  | 'bar-chart-axis-horizontal'
  | 'bar-chart-axis'
  | 'bar-chart-horz'
  | 'bell-slash'
  | 'bell'
  | 'bold'
  | 'book-open'
  | 'bookmark-filled'
  | 'bookmark-outline'
  | 'box-product'
  | 'briefcase'
  | 'bubble-question'
  | 'bug'
  | 'bulleted-list'
  | 'calendar-associate'
  | 'calendar-backwards'
  | 'calendar-check'
  | 'calendar-clock'
  | 'calendar-forward'
  | 'calendar-question'
  | 'calendar-rewind'
  | 'calendar-slash'
  | 'calendar'
  | 'camera-reel'
  | 'caret-down-small'
  | 'caret-left-small'
  | 'caret-left'
  | 'caret-right-small'
  | 'caret-right'
  | 'caret-up-small'
  | 'chart-line'
  | 'check-circle-fill'
  | 'check-circle-task'
  | 'check-circle'
  | 'check-square-fill'
  | 'check-square-task'
  | 'check-square'
  | 'check'
  | 'checkbox-alt1'
  | 'checkbox-alt2'
  | 'checkbox-list'
  | 'checklist'
  | 'chevron-arrow-down-circle'
  | 'chevron-arrow-down'
  | 'chevron-arrow-left'
  | 'chevron-arrow-right'
  | 'chevron-arrow-up'
  | 'circle-down-small'
  | 'circle'
  | 'clock-eight'
  | 'cloud-check'
  | 'cloud-computing'
  | 'cloud-data-tree'
  | 'cloud-download'
  | 'cloud-upload'
  | 'cockpit'
  | 'code-branch'
  | 'code'
  | 'cog-check'
  | 'cog'
  | 'coins'
  | 'column-chart'
  | 'combine'
  | 'command-v-alt'
  | 'command-v'
  | 'comment-add'
  | 'comment-slash'
  | 'comment'
  | 'company-check-colored'
  | 'company-cog'
  | 'company-download-colored'
  | 'company-error-colored'
  | 'company-info-colored'
  | 'company-multiple'
  | 'company-plus'
  | 'company-progress-colored'
  | 'company-question-colored'
  | 'company-remove'
  | 'company-star'
  | 'company-switch'
  | 'company'
  | 'contact-role'
  | 'copy'
  | 'credit-card-off'
  | 'credit-card-plus'
  | 'credit-card-search'
  | 'cross'
  | 'csv-doc'
  | 'cursor'
  | 'custom-fields'
  | 'dashboard-plus'
  | 'data-hourglass'
  | 'data-monitor'
  | 'data-sync'
  | 'desktop'
  | 'diagnose'
  | 'dialpad'
  | 'diamond'
  | 'dollar-circle'
  | 'dollar-square'
  | 'dollar'
  | 'donut-chart'
  | 'double-arrow-down'
  | 'double-arrow-left'
  | 'double-arrow-right'
  | 'double-arrow-up'
  | 'download'
  | 'drag-and-sort'
  | 'drag-reorder'
  | 'dropdown-large'
  | 'dropdown-up-large'
  | 'dynamic-variable'
  | 'ellipse'
  | 'email-check'
  | 'email-error'
  | 'email-info-colored'
  | 'email-manual'
  | 'email-progress'
  | 'email-question'
  | 'email-star-outline'
  | 'email-status'
  | 'emoji-annoyed-face'
  | 'emoji-happy-face'
  | 'emoji-sad-face'
  | 'enrichment-refresh'
  | 'exchange'
  | 'external-link'
  | 'eye-filled'
  | 'eye-on'
  | 'eye-slash'
  | 'facebook-square-filled'
  | 'facebook'
  | 'file-alt'
  | 'file-check'
  | 'file-edit'
  | 'file-lock'
  | 'file-upload'
  | 'file'
  | 'filter-slider'
  | 'filter'
  | 'flag'
  | 'folder-open'
  | 'folder-plus'
  | 'folder'
  | 'forward-10'
  | 'function'
  | 'funnel-chart'
  | 'generate-pipeline'
  | 'gift'
  | 'globe'
  | 'google-chrome'
  | 'google-logo'
  | 'google'
  | 'graduation-cap'
  | 'greenhouse'
  | 'grid-plus'
  | 'grid'
  | 'growth-down'
  | 'growth-up'
  | 'hand-waving'
  | 'heartbeat-status'
  | 'heatmap'
  | 'hide-arrow-left'
  | 'hide-arrow-right'
  | 'hierarchy-child'
  | 'hierarchy-parent-child'
  | 'hierarchy-parent'
  | 'history'
  | 'home'
  | 'hubspot-failed'
  | 'hubspot'
  | 'image-placeholder'
  | 'industry'
  | 'info-circle'
  | 'intent-data'
  | 'italic'
  | 'key-inclined'
  | 'language'
  | 'languages'
  | 'laptop-computer'
  | 'layer-group'
  | 'layout'
  | 'learn-more'
  | 'life-buoy'
  | 'lightbulb'
  | 'lightning'
  | 'link-off'
  | 'link-plus'
  | 'link-square-filled'
  | 'link'
  | 'linkedin-square-filled'
  | 'linkedin'
  | 'list-check'
  | 'list-checked'
  | 'list-deselect'
  | 'list-ordered'
  | 'list-plus'
  | 'list-times'
  | 'list-ui'
  | 'loading'
  | 'location'
  | 'log-in'
  | 'log-out'
  | 'magic-rewriting'
  | 'magic-wand'
  | 'magnet'
  | 'mail-block'
  | 'mail-download-colored'
  | 'mail-download'
  | 'mail-envelope-question'
  | 'mail-filled'
  | 'mail-info'
  | 'mail-lock'
  | 'mail-minus-colored'
  | 'mail-minus'
  | 'mail-open'
  | 'mail-plus-colored'
  | 'mail-search-colored-1'
  | 'mail-search-colored'
  | 'mail-star-colored'
  | 'mail-times-colored'
  | 'mail-times'
  | 'mail-upload-colored'
  | 'mail-upload'
  | 'mail'
  | 'mailgun'
  | 'mails'
  | 'mastercard-logo'
  | 'maximize-screen'
  | 'maximize'
  | 'medal'
  | 'meeting-source'
  | 'menu'
  | 'mic-on'
  | 'mic-slash'
  | 'minimize-screen'
  | 'minimize'
  | 'minus-circle'
  | 'minus'
  | 'mobile'
  | 'money-bag'
  | 'money-stack'
  | 'moon'
  | 'more-h'
  | 'mouse-pointer'
  | 'move'
  | 'multi-credit'
  | 'multi-line-text'
  | 'multi-split'
  | 'mute'
  | 'nested-circles'
  | 'news-filter'
  | 'no-video-camera'
  | 'notes-check'
  | 'notes-plus'
  | 'notes'
  | 'number'
  | 'ordered-list'
  | 'outreach'
  | 'padlock'
  | 'paperclip'
  | 'pause-circle'
  | 'pause'
  | 'pen'
  | 'persistent-checklist'
  | 'phone-check'
  | 'phone-decline'
  | 'phone-download-colored'
  | 'phone-download'
  | 'phone-error'
  | 'phone-in'
  | 'phone-info-colored'
  | 'phone-out'
  | 'phone-progress'
  | 'phone-question-colored'
  | 'phone-times'
  | 'phone'
  | 'picture-in-picture'
  | 'picture'
  | 'pie-chart-2'
  | 'pie-chart'
  | 'pipedrive-failed'
  | 'pipedrive'
  | 'pipeline'
  | 'play-circle'
  | 'play'
  | 'player-in-player'
  | 'plus-circle-filled'
  | 'plus-circle'
  | 'plus'
  | 'pointer'
  | 'powerup_ai'
  | 'powerup_icon'
  | 'puzzle'
  | 'question-circle-fill'
  | 'question-circle'
  | 'record'
  | 'redo'
  | 'refresh'
  | 'remove-from-playlist'
  | 'reorder'
  | 'repeat'
  | 'reply'
  | 'rewind-10'
  | 'rocket'
  | 'rotate-left'
  | 'rotate-right'
  | 'salesforce'
  | 'salesloft'
  | 'scissors'
  | 'score'
  | 'search-checked'
  | 'search-cross'
  | 'search-user'
  | 'search'
  | 'send-inclined'
  | 'sendgrid'
  | 'sequence-check'
  | 'sequence-error'
  | 'sequence-question'
  | 'sequence-sending-progress'
  | 'sequence'
  | 'server-database'
  | 'share'
  | 'shield'
  | 'sic-code'
  | 'sidebar-collapse'
  | 'sidebar-expand'
  | 'signal-1'
  | 'signal-2'
  | 'signal-3'
  | 'signals'
  | 'single-credit'
  | 'skip-forward'
  | 'sliders-settings'
  | 'sort-accending'
  | 'sort-ascending'
  | 'sort-decending'
  | 'sort-descending'
  | 'spot-create-alert'
  | 'star-empty'
  | 'star-filled'
  | 'status-activity'
  | 'stop-circle'
  | 'stopwatch'
  | 'strikethrough'
  | 'sum'
  | 'support'
  | 'syncsalesforce'
  | 'table-file'
  | 'tag-user'
  | 'tag'
  | 'talking'
  | 'text-title'
  | 'thumbs-down'
  | 'thumbs-up-filled'
  | 'thumbs-up'
  | 'thumbtack'
  | 'tiles'
  | 'times-circle-filled'
  | 'times-circle'
  | 'times'
  | 'toggle-list'
  | 'token'
  | 'traffic-light'
  | 'trash'
  | 'trello-board'
  | 'trigger'
  | 'trophy-filled'
  | 'trophy-sales'
  | 'trophy'
  | 'true-false'
  | 'twitter-square-filled'
  | 'twitter'
  | 'underline'
  | 'undo'
  | 'unflag'
  | 'up-down-caret'
  | 'upload'
  | 'user-bubble'
  | 'user-check'
  | 'user-circle'
  | 'user-download-colored'
  | 'user-exclamation'
  | 'user-minus'
  | 'user-plus'
  | 'user-question'
  | 'user-refresh'
  | 'user-square'
  | 'user-star'
  | 'user-talking'
  | 'user-times'
  | 'user-upload'
  | 'user'
  | 'users'
  | 'vector'
  | 'video-camera-off'
  | 'video-camera'
  | 'video-pause'
  | 'video-play'
  | 'view-grid-list'
  | 'view-list'
  | 'volume'
  | 'wallet'
  | 'waterfall'
  | 'website-visitors'
  | 'window-equal'
  | 'window-maximize'
  | 'window-minimize'
  | 'world'
  | 'wrench'
  | 'x'
  | 'yelp';

export type ApolloIconsKey =
  | 'i0_5x'
  | 'i1Circle'
  | 'i1_2x'
  | 'i1_5x'
  | 'i1_7x'
  | 'i1x'
  | 'i2Circle'
  | 'i2_2x'
  | 'i2_5x'
  | 'i2x'
  | 'i3Circle'
  | 'AddToPlaylist'
  | 'AiEmail'
  | 'AiPersonalization'
  | 'AiStarsFilled'
  | 'AiStarsOutline'
  | 'AiStars'
  | 'AlertBell'
  | 'AlertCircle'
  | 'AlertTriangle'
  | 'Alexa'
  | 'AngelList'
  | 'ApolloAi'
  | 'ApolloFullLogo'
  | 'ApolloLogo'
  | 'Apollo'
  | 'ArchiveBox'
  | 'AreaChart'
  | 'ArrowCornerUpRight'
  | 'ArrowDown'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'ArrowUpCircle'
  | 'ArrowUp'
  | 'ArrowsMerge'
  | 'Associate'
  | 'Association'
  | 'AutoFlash'
  | 'Ban'
  | 'BarChartAxisHorizontal'
  | 'BarChartAxis'
  | 'BarChartHorz'
  | 'BellSlash'
  | 'Bell'
  | 'Bold'
  | 'BookOpen'
  | 'BookmarkFilled'
  | 'BookmarkOutline'
  | 'BoxProduct'
  | 'Briefcase'
  | 'BubbleQuestion'
  | 'Bug'
  | 'BulletedList'
  | 'CalendarAssociate'
  | 'CalendarBackwards'
  | 'CalendarCheck'
  | 'CalendarClock'
  | 'CalendarForward'
  | 'CalendarQuestion'
  | 'CalendarRewind'
  | 'CalendarSlash'
  | 'Calendar'
  | 'CameraReel'
  | 'CaretDownSmall'
  | 'CaretLeftSmall'
  | 'CaretLeft'
  | 'CaretRightSmall'
  | 'CaretRight'
  | 'CaretUpSmall'
  | 'ChartLine'
  | 'CheckCircleFill'
  | 'CheckCircleTask'
  | 'CheckCircle'
  | 'CheckSquareFill'
  | 'CheckSquareTask'
  | 'CheckSquare'
  | 'Check'
  | 'CheckboxAlt1'
  | 'CheckboxAlt2'
  | 'CheckboxList'
  | 'Checklist'
  | 'ChevronArrowDownCircle'
  | 'ChevronArrowDown'
  | 'ChevronArrowLeft'
  | 'ChevronArrowRight'
  | 'ChevronArrowUp'
  | 'CircleDownSmall'
  | 'Circle'
  | 'ClockEight'
  | 'CloudCheck'
  | 'CloudComputing'
  | 'CloudDataTree'
  | 'CloudDownload'
  | 'CloudUpload'
  | 'Cockpit'
  | 'CodeBranch'
  | 'Code'
  | 'CogCheck'
  | 'Cog'
  | 'Coins'
  | 'ColumnChart'
  | 'Combine'
  | 'CommandVAlt'
  | 'CommandV'
  | 'CommentAdd'
  | 'CommentSlash'
  | 'Comment'
  | 'CompanyCheckColored'
  | 'CompanyCog'
  | 'CompanyDownloadColored'
  | 'CompanyErrorColored'
  | 'CompanyInfoColored'
  | 'CompanyMultiple'
  | 'CompanyPlus'
  | 'CompanyProgressColored'
  | 'CompanyQuestionColored'
  | 'CompanyRemove'
  | 'CompanyStar'
  | 'CompanySwitch'
  | 'Company'
  | 'ContactRole'
  | 'Copy'
  | 'CreditCardOff'
  | 'CreditCardPlus'
  | 'CreditCardSearch'
  | 'Cross'
  | 'CsvDoc'
  | 'Cursor'
  | 'CustomFields'
  | 'DashboardPlus'
  | 'DataHourglass'
  | 'DataMonitor'
  | 'DataSync'
  | 'Desktop'
  | 'Diagnose'
  | 'Dialpad'
  | 'Diamond'
  | 'DollarCircle'
  | 'DollarSquare'
  | 'Dollar'
  | 'DonutChart'
  | 'DoubleArrowDown'
  | 'DoubleArrowLeft'
  | 'DoubleArrowRight'
  | 'DoubleArrowUp'
  | 'Download'
  | 'DragAndSort'
  | 'DragReorder'
  | 'DropdownLarge'
  | 'DropdownUpLarge'
  | 'DynamicVariable'
  | 'Ellipse'
  | 'EmailCheck'
  | 'EmailError'
  | 'EmailInfoColored'
  | 'EmailManual'
  | 'EmailProgress'
  | 'EmailQuestion'
  | 'EmailStarOutline'
  | 'EmailStatus'
  | 'EmojiAnnoyedFace'
  | 'EmojiHappyFace'
  | 'EmojiSadFace'
  | 'EnrichmentRefresh'
  | 'Exchange'
  | 'ExternalLink'
  | 'EyeFilled'
  | 'EyeOn'
  | 'EyeSlash'
  | 'FacebookSquareFilled'
  | 'Facebook'
  | 'FileAlt'
  | 'FileCheck'
  | 'FileEdit'
  | 'FileLock'
  | 'FileUpload'
  | 'File'
  | 'FilterSlider'
  | 'Filter'
  | 'Flag'
  | 'FolderOpen'
  | 'FolderPlus'
  | 'Folder'
  | 'Forward_10'
  | 'Function'
  | 'FunnelChart'
  | 'GeneratePipeline'
  | 'Gift'
  | 'Globe'
  | 'GoogleChrome'
  | 'GoogleLogo'
  | 'Google'
  | 'GraduationCap'
  | 'Greenhouse'
  | 'GridPlus'
  | 'Grid'
  | 'GrowthDown'
  | 'GrowthUp'
  | 'HandWaving'
  | 'HeartbeatStatus'
  | 'Heatmap'
  | 'HideArrowLeft'
  | 'HideArrowRight'
  | 'HierarchyChild'
  | 'HierarchyParentChild'
  | 'HierarchyParent'
  | 'History'
  | 'Home'
  | 'HubspotFailed'
  | 'Hubspot'
  | 'ImagePlaceholder'
  | 'Industry'
  | 'InfoCircle'
  | 'IntentData'
  | 'Italic'
  | 'KeyInclined'
  | 'Language'
  | 'Languages'
  | 'LaptopComputer'
  | 'LayerGroup'
  | 'Layout'
  | 'LearnMore'
  | 'LifeBuoy'
  | 'Lightbulb'
  | 'Lightning'
  | 'LinkOff'
  | 'LinkPlus'
  | 'LinkSquareFilled'
  | 'Link'
  | 'LinkedinSquareFilled'
  | 'Linkedin'
  | 'ListCheck'
  | 'ListChecked'
  | 'ListDeselect'
  | 'ListOrdered'
  | 'ListPlus'
  | 'ListTimes'
  | 'ListUi'
  | 'Loading'
  | 'Location'
  | 'LogIn'
  | 'LogOut'
  | 'MagicRewriting'
  | 'MagicWand'
  | 'Magnet'
  | 'MailBlock'
  | 'MailDownloadColored'
  | 'MailDownload'
  | 'MailEnvelopeQuestion'
  | 'MailFilled'
  | 'MailInfo'
  | 'MailLock'
  | 'MailMinusColored'
  | 'MailMinus'
  | 'MailOpen'
  | 'MailPlusColored'
  | 'MailSearchColored_1'
  | 'MailSearchColored'
  | 'MailStarColored'
  | 'MailTimesColored'
  | 'MailTimes'
  | 'MailUploadColored'
  | 'MailUpload'
  | 'Mail'
  | 'Mailgun'
  | 'Mails'
  | 'MastercardLogo'
  | 'MaximizeScreen'
  | 'Maximize'
  | 'Medal'
  | 'MeetingSource'
  | 'Menu'
  | 'MicOn'
  | 'MicSlash'
  | 'MinimizeScreen'
  | 'Minimize'
  | 'MinusCircle'
  | 'Minus'
  | 'Mobile'
  | 'MoneyBag'
  | 'MoneyStack'
  | 'Moon'
  | 'MoreH'
  | 'MousePointer'
  | 'Move'
  | 'MultiCredit'
  | 'MultiLineText'
  | 'MultiSplit'
  | 'Mute'
  | 'NestedCircles'
  | 'NewsFilter'
  | 'NoVideoCamera'
  | 'NotesCheck'
  | 'NotesPlus'
  | 'Notes'
  | 'Number'
  | 'OrderedList'
  | 'Outreach'
  | 'Padlock'
  | 'Paperclip'
  | 'PauseCircle'
  | 'Pause'
  | 'Pen'
  | 'PersistentChecklist'
  | 'PhoneCheck'
  | 'PhoneDecline'
  | 'PhoneDownloadColored'
  | 'PhoneDownload'
  | 'PhoneError'
  | 'PhoneIn'
  | 'PhoneInfoColored'
  | 'PhoneOut'
  | 'PhoneProgress'
  | 'PhoneQuestionColored'
  | 'PhoneTimes'
  | 'Phone'
  | 'PictureInPicture'
  | 'Picture'
  | 'PieChart_2'
  | 'PieChart'
  | 'PipedriveFailed'
  | 'Pipedrive'
  | 'Pipeline'
  | 'PlayCircle'
  | 'Play'
  | 'PlayerInPlayer'
  | 'PlusCircleFilled'
  | 'PlusCircle'
  | 'Plus'
  | 'Pointer'
  | 'PowerupAi'
  | 'PowerupIcon'
  | 'Puzzle'
  | 'QuestionCircleFill'
  | 'QuestionCircle'
  | 'Record'
  | 'Redo'
  | 'Refresh'
  | 'RemoveFromPlaylist'
  | 'Reorder'
  | 'Repeat'
  | 'Reply'
  | 'Rewind_10'
  | 'Rocket'
  | 'RotateLeft'
  | 'RotateRight'
  | 'Salesforce'
  | 'Salesloft'
  | 'Scissors'
  | 'Score'
  | 'SearchChecked'
  | 'SearchCross'
  | 'SearchUser'
  | 'Search'
  | 'SendInclined'
  | 'Sendgrid'
  | 'SequenceCheck'
  | 'SequenceError'
  | 'SequenceQuestion'
  | 'SequenceSendingProgress'
  | 'Sequence'
  | 'ServerDatabase'
  | 'Share'
  | 'Shield'
  | 'SicCode'
  | 'SidebarCollapse'
  | 'SidebarExpand'
  | 'Signal_1'
  | 'Signal_2'
  | 'Signal_3'
  | 'Signals'
  | 'SingleCredit'
  | 'SkipForward'
  | 'SlidersSettings'
  | 'SortAccending'
  | 'SortAscending'
  | 'SortDecending'
  | 'SortDescending'
  | 'SpotCreateAlert'
  | 'StarEmpty'
  | 'StarFilled'
  | 'StatusActivity'
  | 'StopCircle'
  | 'Stopwatch'
  | 'Strikethrough'
  | 'Sum'
  | 'Support'
  | 'Syncsalesforce'
  | 'TableFile'
  | 'TagUser'
  | 'Tag'
  | 'Talking'
  | 'TextTitle'
  | 'ThumbsDown'
  | 'ThumbsUpFilled'
  | 'ThumbsUp'
  | 'Thumbtack'
  | 'Tiles'
  | 'TimesCircleFilled'
  | 'TimesCircle'
  | 'Times'
  | 'ToggleList'
  | 'Token'
  | 'TrafficLight'
  | 'Trash'
  | 'TrelloBoard'
  | 'Trigger'
  | 'TrophyFilled'
  | 'TrophySales'
  | 'Trophy'
  | 'TrueFalse'
  | 'TwitterSquareFilled'
  | 'Twitter'
  | 'Underline'
  | 'Undo'
  | 'Unflag'
  | 'UpDownCaret'
  | 'Upload'
  | 'UserBubble'
  | 'UserCheck'
  | 'UserCircle'
  | 'UserDownloadColored'
  | 'UserExclamation'
  | 'UserMinus'
  | 'UserPlus'
  | 'UserQuestion'
  | 'UserRefresh'
  | 'UserSquare'
  | 'UserStar'
  | 'UserTalking'
  | 'UserTimes'
  | 'UserUpload'
  | 'User'
  | 'Users'
  | 'Vector'
  | 'VideoCameraOff'
  | 'VideoCamera'
  | 'VideoPause'
  | 'VideoPlay'
  | 'ViewGridList'
  | 'ViewList'
  | 'Volume'
  | 'Wallet'
  | 'Waterfall'
  | 'WebsiteVisitors'
  | 'WindowEqual'
  | 'WindowMaximize'
  | 'WindowMinimize'
  | 'World'
  | 'Wrench'
  | 'X'
  | 'Yelp';

export enum ApolloIcons {
  i0_5x = '0-5x',
  i1Circle = '1-circle',
  i1_2x = '1-2x',
  i1_5x = '1-5x',
  i1_7x = '1-7x',
  i1x = '1x',
  i2Circle = '2-circle',
  i2_2x = '2-2x',
  i2_5x = '2-5x',
  i2x = '2x',
  i3Circle = '3-circle',
  AddToPlaylist = 'add-to-playlist',
  AiEmail = 'ai-email',
  AiPersonalization = 'ai-personalization',
  AiStarsFilled = 'ai-stars-filled',
  AiStarsOutline = 'ai-stars-outline',
  AiStars = 'ai-stars',
  AlertBell = 'alert-bell',
  AlertCircle = 'alert-circle',
  AlertTriangle = 'alert-triangle',
  Alexa = 'alexa',
  AngelList = 'angel-list',
  ApolloAi = 'apollo-ai',
  ApolloFullLogo = 'apollo-full-logo',
  ApolloLogo = 'apollo-logo',
  Apollo = 'apollo',
  ArchiveBox = 'archive-box',
  AreaChart = 'area-chart',
  ArrowCornerUpRight = 'arrow-corner-up-right',
  ArrowDown = 'arrow-down',
  ArrowLeft = 'arrow-left',
  ArrowRight = 'arrow-right',
  ArrowUpCircle = 'arrow-up-circle',
  ArrowUp = 'arrow-up',
  ArrowsMerge = 'arrows-merge',
  Associate = 'associate',
  Association = 'association',
  AutoFlash = 'auto-flash',
  Ban = 'ban',
  BarChartAxisHorizontal = 'bar-chart-axis-horizontal',
  BarChartAxis = 'bar-chart-axis',
  BarChartHorz = 'bar-chart-horz',
  BellSlash = 'bell-slash',
  Bell = 'bell',
  Bold = 'bold',
  BookOpen = 'book-open',
  BookmarkFilled = 'bookmark-filled',
  BookmarkOutline = 'bookmark-outline',
  BoxProduct = 'box-product',
  Briefcase = 'briefcase',
  BubbleQuestion = 'bubble-question',
  Bug = 'bug',
  BulletedList = 'bulleted-list',
  CalendarAssociate = 'calendar-associate',
  CalendarBackwards = 'calendar-backwards',
  CalendarCheck = 'calendar-check',
  CalendarClock = 'calendar-clock',
  CalendarForward = 'calendar-forward',
  CalendarQuestion = 'calendar-question',
  CalendarRewind = 'calendar-rewind',
  CalendarSlash = 'calendar-slash',
  Calendar = 'calendar',
  CameraReel = 'camera-reel',
  CaretDownSmall = 'caret-down-small',
  CaretLeftSmall = 'caret-left-small',
  CaretLeft = 'caret-left',
  CaretRightSmall = 'caret-right-small',
  CaretRight = 'caret-right',
  CaretUpSmall = 'caret-up-small',
  ChartLine = 'chart-line',
  CheckCircleFill = 'check-circle-fill',
  CheckCircleTask = 'check-circle-task',
  CheckCircle = 'check-circle',
  CheckSquareFill = 'check-square-fill',
  CheckSquareTask = 'check-square-task',
  CheckSquare = 'check-square',
  Check = 'check',
  CheckboxAlt1 = 'checkbox-alt1',
  CheckboxAlt2 = 'checkbox-alt2',
  CheckboxList = 'checkbox-list',
  Checklist = 'checklist',
  ChevronArrowDownCircle = 'chevron-arrow-down-circle',
  ChevronArrowDown = 'chevron-arrow-down',
  ChevronArrowLeft = 'chevron-arrow-left',
  ChevronArrowRight = 'chevron-arrow-right',
  ChevronArrowUp = 'chevron-arrow-up',
  CircleDownSmall = 'circle-down-small',
  Circle = 'circle',
  ClockEight = 'clock-eight',
  CloudCheck = 'cloud-check',
  CloudComputing = 'cloud-computing',
  CloudDataTree = 'cloud-data-tree',
  CloudDownload = 'cloud-download',
  CloudUpload = 'cloud-upload',
  Cockpit = 'cockpit',
  CodeBranch = 'code-branch',
  Code = 'code',
  CogCheck = 'cog-check',
  Cog = 'cog',
  Coins = 'coins',
  ColumnChart = 'column-chart',
  Combine = 'combine',
  CommandVAlt = 'command-v-alt',
  CommandV = 'command-v',
  CommentAdd = 'comment-add',
  CommentSlash = 'comment-slash',
  Comment = 'comment',
  CompanyCheckColored = 'company-check-colored',
  CompanyCog = 'company-cog',
  CompanyDownloadColored = 'company-download-colored',
  CompanyErrorColored = 'company-error-colored',
  CompanyInfoColored = 'company-info-colored',
  CompanyMultiple = 'company-multiple',
  CompanyPlus = 'company-plus',
  CompanyProgressColored = 'company-progress-colored',
  CompanyQuestionColored = 'company-question-colored',
  CompanyRemove = 'company-remove',
  CompanyStar = 'company-star',
  CompanySwitch = 'company-switch',
  Company = 'company',
  ContactRole = 'contact-role',
  Copy = 'copy',
  CreditCardOff = 'credit-card-off',
  CreditCardPlus = 'credit-card-plus',
  CreditCardSearch = 'credit-card-search',
  Cross = 'cross',
  CsvDoc = 'csv-doc',
  Cursor = 'cursor',
  CustomFields = 'custom-fields',
  DashboardPlus = 'dashboard-plus',
  DataHourglass = 'data-hourglass',
  DataMonitor = 'data-monitor',
  DataSync = 'data-sync',
  Desktop = 'desktop',
  Diagnose = 'diagnose',
  Dialpad = 'dialpad',
  Diamond = 'diamond',
  DollarCircle = 'dollar-circle',
  DollarSquare = 'dollar-square',
  Dollar = 'dollar',
  DonutChart = 'donut-chart',
  DoubleArrowDown = 'double-arrow-down',
  DoubleArrowLeft = 'double-arrow-left',
  DoubleArrowRight = 'double-arrow-right',
  DoubleArrowUp = 'double-arrow-up',
  Download = 'download',
  DragAndSort = 'drag-and-sort',
  DragReorder = 'drag-reorder',
  DropdownLarge = 'dropdown-large',
  DropdownUpLarge = 'dropdown-up-large',
  DynamicVariable = 'dynamic-variable',
  Ellipse = 'ellipse',
  EmailCheck = 'email-check',
  EmailError = 'email-error',
  EmailInfoColored = 'email-info-colored',
  EmailManual = 'email-manual',
  EmailProgress = 'email-progress',
  EmailQuestion = 'email-question',
  EmailStarOutline = 'email-star-outline',
  EmailStatus = 'email-status',
  EmojiAnnoyedFace = 'emoji-annoyed-face',
  EmojiHappyFace = 'emoji-happy-face',
  EmojiSadFace = 'emoji-sad-face',
  EnrichmentRefresh = 'enrichment-refresh',
  Exchange = 'exchange',
  ExternalLink = 'external-link',
  EyeFilled = 'eye-filled',
  EyeOn = 'eye-on',
  EyeSlash = 'eye-slash',
  FacebookSquareFilled = 'facebook-square-filled',
  Facebook = 'facebook',
  FileAlt = 'file-alt',
  FileCheck = 'file-check',
  FileEdit = 'file-edit',
  FileLock = 'file-lock',
  FileUpload = 'file-upload',
  File = 'file',
  FilterSlider = 'filter-slider',
  Filter = 'filter',
  Flag = 'flag',
  FolderOpen = 'folder-open',
  FolderPlus = 'folder-plus',
  Folder = 'folder',
  Forward_10 = 'forward-10',
  Function = 'function',
  FunnelChart = 'funnel-chart',
  GeneratePipeline = 'generate-pipeline',
  Gift = 'gift',
  Globe = 'globe',
  GoogleChrome = 'google-chrome',
  GoogleLogo = 'google-logo',
  Google = 'google',
  GraduationCap = 'graduation-cap',
  Greenhouse = 'greenhouse',
  GridPlus = 'grid-plus',
  Grid = 'grid',
  GrowthDown = 'growth-down',
  GrowthUp = 'growth-up',
  HandWaving = 'hand-waving',
  HeartbeatStatus = 'heartbeat-status',
  Heatmap = 'heatmap',
  HideArrowLeft = 'hide-arrow-left',
  HideArrowRight = 'hide-arrow-right',
  HierarchyChild = 'hierarchy-child',
  HierarchyParentChild = 'hierarchy-parent-child',
  HierarchyParent = 'hierarchy-parent',
  History = 'history',
  Home = 'home',
  HubspotFailed = 'hubspot-failed',
  Hubspot = 'hubspot',
  ImagePlaceholder = 'image-placeholder',
  Industry = 'industry',
  InfoCircle = 'info-circle',
  IntentData = 'intent-data',
  Italic = 'italic',
  KeyInclined = 'key-inclined',
  Language = 'language',
  Languages = 'languages',
  LaptopComputer = 'laptop-computer',
  LayerGroup = 'layer-group',
  Layout = 'layout',
  LearnMore = 'learn-more',
  LifeBuoy = 'life-buoy',
  Lightbulb = 'lightbulb',
  Lightning = 'lightning',
  LinkOff = 'link-off',
  LinkPlus = 'link-plus',
  LinkSquareFilled = 'link-square-filled',
  Link = 'link',
  LinkedinSquareFilled = 'linkedin-square-filled',
  Linkedin = 'linkedin',
  ListCheck = 'list-check',
  ListChecked = 'list-checked',
  ListDeselect = 'list-deselect',
  ListOrdered = 'list-ordered',
  ListPlus = 'list-plus',
  ListTimes = 'list-times',
  ListUi = 'list-ui',
  Loading = 'loading',
  Location = 'location',
  LogIn = 'log-in',
  LogOut = 'log-out',
  MagicRewriting = 'magic-rewriting',
  MagicWand = 'magic-wand',
  Magnet = 'magnet',
  MailBlock = 'mail-block',
  MailDownloadColored = 'mail-download-colored',
  MailDownload = 'mail-download',
  MailEnvelopeQuestion = 'mail-envelope-question',
  MailFilled = 'mail-filled',
  MailInfo = 'mail-info',
  MailLock = 'mail-lock',
  MailMinusColored = 'mail-minus-colored',
  MailMinus = 'mail-minus',
  MailOpen = 'mail-open',
  MailPlusColored = 'mail-plus-colored',
  MailSearchColored_1 = 'mail-search-colored-1',
  MailSearchColored = 'mail-search-colored',
  MailStarColored = 'mail-star-colored',
  MailTimesColored = 'mail-times-colored',
  MailTimes = 'mail-times',
  MailUploadColored = 'mail-upload-colored',
  MailUpload = 'mail-upload',
  Mail = 'mail',
  Mailgun = 'mailgun',
  Mails = 'mails',
  MastercardLogo = 'mastercard-logo',
  MaximizeScreen = 'maximize-screen',
  Maximize = 'maximize',
  Medal = 'medal',
  MeetingSource = 'meeting-source',
  Menu = 'menu',
  MicOn = 'mic-on',
  MicSlash = 'mic-slash',
  MinimizeScreen = 'minimize-screen',
  Minimize = 'minimize',
  MinusCircle = 'minus-circle',
  Minus = 'minus',
  Mobile = 'mobile',
  MoneyBag = 'money-bag',
  MoneyStack = 'money-stack',
  Moon = 'moon',
  MoreH = 'more-h',
  MousePointer = 'mouse-pointer',
  Move = 'move',
  MultiCredit = 'multi-credit',
  MultiLineText = 'multi-line-text',
  MultiSplit = 'multi-split',
  Mute = 'mute',
  NestedCircles = 'nested-circles',
  NewsFilter = 'news-filter',
  NoVideoCamera = 'no-video-camera',
  NotesCheck = 'notes-check',
  NotesPlus = 'notes-plus',
  Notes = 'notes',
  Number = 'number',
  OrderedList = 'ordered-list',
  Outreach = 'outreach',
  Padlock = 'padlock',
  Paperclip = 'paperclip',
  PauseCircle = 'pause-circle',
  Pause = 'pause',
  Pen = 'pen',
  PersistentChecklist = 'persistent-checklist',
  PhoneCheck = 'phone-check',
  PhoneDecline = 'phone-decline',
  PhoneDownloadColored = 'phone-download-colored',
  PhoneDownload = 'phone-download',
  PhoneError = 'phone-error',
  PhoneIn = 'phone-in',
  PhoneInfoColored = 'phone-info-colored',
  PhoneOut = 'phone-out',
  PhoneProgress = 'phone-progress',
  PhoneQuestionColored = 'phone-question-colored',
  PhoneTimes = 'phone-times',
  Phone = 'phone',
  PictureInPicture = 'picture-in-picture',
  Picture = 'picture',
  PieChart_2 = 'pie-chart-2',
  PieChart = 'pie-chart',
  PipedriveFailed = 'pipedrive-failed',
  Pipedrive = 'pipedrive',
  Pipeline = 'pipeline',
  PlayCircle = 'play-circle',
  Play = 'play',
  PlayerInPlayer = 'player-in-player',
  PlusCircleFilled = 'plus-circle-filled',
  PlusCircle = 'plus-circle',
  Plus = 'plus',
  Pointer = 'pointer',
  PowerupAi = 'powerup_ai',
  PowerupIcon = 'powerup_icon',
  Puzzle = 'puzzle',
  QuestionCircleFill = 'question-circle-fill',
  QuestionCircle = 'question-circle',
  Record = 'record',
  Redo = 'redo',
  Refresh = 'refresh',
  RemoveFromPlaylist = 'remove-from-playlist',
  Reorder = 'reorder',
  Repeat = 'repeat',
  Reply = 'reply',
  Rewind_10 = 'rewind-10',
  Rocket = 'rocket',
  RotateLeft = 'rotate-left',
  RotateRight = 'rotate-right',
  Salesforce = 'salesforce',
  Salesloft = 'salesloft',
  Scissors = 'scissors',
  Score = 'score',
  SearchChecked = 'search-checked',
  SearchCross = 'search-cross',
  SearchUser = 'search-user',
  Search = 'search',
  SendInclined = 'send-inclined',
  Sendgrid = 'sendgrid',
  SequenceCheck = 'sequence-check',
  SequenceError = 'sequence-error',
  SequenceQuestion = 'sequence-question',
  SequenceSendingProgress = 'sequence-sending-progress',
  Sequence = 'sequence',
  ServerDatabase = 'server-database',
  Share = 'share',
  Shield = 'shield',
  SicCode = 'sic-code',
  SidebarCollapse = 'sidebar-collapse',
  SidebarExpand = 'sidebar-expand',
  Signal_1 = 'signal-1',
  Signal_2 = 'signal-2',
  Signal_3 = 'signal-3',
  Signals = 'signals',
  SingleCredit = 'single-credit',
  SkipForward = 'skip-forward',
  SlidersSettings = 'sliders-settings',
  SortAccending = 'sort-accending',
  SortAscending = 'sort-ascending',
  SortDecending = 'sort-decending',
  SortDescending = 'sort-descending',
  SpotCreateAlert = 'spot-create-alert',
  StarEmpty = 'star-empty',
  StarFilled = 'star-filled',
  StatusActivity = 'status-activity',
  StopCircle = 'stop-circle',
  Stopwatch = 'stopwatch',
  Strikethrough = 'strikethrough',
  Sum = 'sum',
  Support = 'support',
  Syncsalesforce = 'syncsalesforce',
  TableFile = 'table-file',
  TagUser = 'tag-user',
  Tag = 'tag',
  Talking = 'talking',
  TextTitle = 'text-title',
  ThumbsDown = 'thumbs-down',
  ThumbsUpFilled = 'thumbs-up-filled',
  ThumbsUp = 'thumbs-up',
  Thumbtack = 'thumbtack',
  Tiles = 'tiles',
  TimesCircleFilled = 'times-circle-filled',
  TimesCircle = 'times-circle',
  Times = 'times',
  ToggleList = 'toggle-list',
  Token = 'token',
  TrafficLight = 'traffic-light',
  Trash = 'trash',
  TrelloBoard = 'trello-board',
  Trigger = 'trigger',
  TrophyFilled = 'trophy-filled',
  TrophySales = 'trophy-sales',
  Trophy = 'trophy',
  TrueFalse = 'true-false',
  TwitterSquareFilled = 'twitter-square-filled',
  Twitter = 'twitter',
  Underline = 'underline',
  Undo = 'undo',
  Unflag = 'unflag',
  UpDownCaret = 'up-down-caret',
  Upload = 'upload',
  UserBubble = 'user-bubble',
  UserCheck = 'user-check',
  UserCircle = 'user-circle',
  UserDownloadColored = 'user-download-colored',
  UserExclamation = 'user-exclamation',
  UserMinus = 'user-minus',
  UserPlus = 'user-plus',
  UserQuestion = 'user-question',
  UserRefresh = 'user-refresh',
  UserSquare = 'user-square',
  UserStar = 'user-star',
  UserTalking = 'user-talking',
  UserTimes = 'user-times',
  UserUpload = 'user-upload',
  User = 'user',
  Users = 'users',
  Vector = 'vector',
  VideoCameraOff = 'video-camera-off',
  VideoCamera = 'video-camera',
  VideoPause = 'video-pause',
  VideoPlay = 'video-play',
  ViewGridList = 'view-grid-list',
  ViewList = 'view-list',
  Volume = 'volume',
  Wallet = 'wallet',
  Waterfall = 'waterfall',
  WebsiteVisitors = 'website-visitors',
  WindowEqual = 'window-equal',
  WindowMaximize = 'window-maximize',
  WindowMinimize = 'window-minimize',
  World = 'world',
  Wrench = 'wrench',
  X = 'x',
  Yelp = 'yelp',
}

export const APOLLO_ICONS_CODEPOINTS: { [key in ApolloIcons]: string } = {
  [ApolloIcons.i0_5x]: '61697',
  [ApolloIcons.i1Circle]: '61698',
  [ApolloIcons.i1_2x]: '61699',
  [ApolloIcons.i1_5x]: '61700',
  [ApolloIcons.i1_7x]: '61701',
  [ApolloIcons.i1x]: '61702',
  [ApolloIcons.i2Circle]: '61703',
  [ApolloIcons.i2_2x]: '61704',
  [ApolloIcons.i2_5x]: '61705',
  [ApolloIcons.i2x]: '61706',
  [ApolloIcons.i3Circle]: '61707',
  [ApolloIcons.AddToPlaylist]: '61708',
  [ApolloIcons.AiEmail]: '61709',
  [ApolloIcons.AiPersonalization]: '61710',
  [ApolloIcons.AiStarsFilled]: '61711',
  [ApolloIcons.AiStarsOutline]: '61712',
  [ApolloIcons.AiStars]: '61713',
  [ApolloIcons.AlertBell]: '61714',
  [ApolloIcons.AlertCircle]: '61715',
  [ApolloIcons.AlertTriangle]: '61716',
  [ApolloIcons.Alexa]: '61717',
  [ApolloIcons.AngelList]: '61718',
  [ApolloIcons.ApolloAi]: '61719',
  [ApolloIcons.ApolloFullLogo]: '61720',
  [ApolloIcons.ApolloLogo]: '61721',
  [ApolloIcons.Apollo]: '61722',
  [ApolloIcons.ArchiveBox]: '61723',
  [ApolloIcons.AreaChart]: '61724',
  [ApolloIcons.ArrowCornerUpRight]: '61725',
  [ApolloIcons.ArrowDown]: '61726',
  [ApolloIcons.ArrowLeft]: '61727',
  [ApolloIcons.ArrowRight]: '61728',
  [ApolloIcons.ArrowUpCircle]: '61729',
  [ApolloIcons.ArrowUp]: '61730',
  [ApolloIcons.ArrowsMerge]: '61731',
  [ApolloIcons.Associate]: '61732',
  [ApolloIcons.Association]: '61733',
  [ApolloIcons.AutoFlash]: '61734',
  [ApolloIcons.Ban]: '61735',
  [ApolloIcons.BarChartAxisHorizontal]: '61736',
  [ApolloIcons.BarChartAxis]: '61737',
  [ApolloIcons.BarChartHorz]: '61738',
  [ApolloIcons.BellSlash]: '61739',
  [ApolloIcons.Bell]: '61740',
  [ApolloIcons.Bold]: '61741',
  [ApolloIcons.BookOpen]: '61742',
  [ApolloIcons.BookmarkFilled]: '61743',
  [ApolloIcons.BookmarkOutline]: '61744',
  [ApolloIcons.BoxProduct]: '61745',
  [ApolloIcons.Briefcase]: '61746',
  [ApolloIcons.BubbleQuestion]: '61747',
  [ApolloIcons.Bug]: '61748',
  [ApolloIcons.BulletedList]: '61749',
  [ApolloIcons.CalendarAssociate]: '61750',
  [ApolloIcons.CalendarBackwards]: '61751',
  [ApolloIcons.CalendarCheck]: '61752',
  [ApolloIcons.CalendarClock]: '61753',
  [ApolloIcons.CalendarForward]: '61754',
  [ApolloIcons.CalendarQuestion]: '61755',
  [ApolloIcons.CalendarRewind]: '61756',
  [ApolloIcons.CalendarSlash]: '61757',
  [ApolloIcons.Calendar]: '61758',
  [ApolloIcons.CameraReel]: '61759',
  [ApolloIcons.CaretDownSmall]: '61760',
  [ApolloIcons.CaretLeftSmall]: '61761',
  [ApolloIcons.CaretLeft]: '61762',
  [ApolloIcons.CaretRightSmall]: '61763',
  [ApolloIcons.CaretRight]: '61764',
  [ApolloIcons.CaretUpSmall]: '61765',
  [ApolloIcons.ChartLine]: '61766',
  [ApolloIcons.CheckCircleFill]: '61767',
  [ApolloIcons.CheckCircleTask]: '61768',
  [ApolloIcons.CheckCircle]: '61769',
  [ApolloIcons.CheckSquareFill]: '61770',
  [ApolloIcons.CheckSquareTask]: '61771',
  [ApolloIcons.CheckSquare]: '61772',
  [ApolloIcons.Check]: '61773',
  [ApolloIcons.CheckboxAlt1]: '61774',
  [ApolloIcons.CheckboxAlt2]: '61775',
  [ApolloIcons.CheckboxList]: '61776',
  [ApolloIcons.Checklist]: '61777',
  [ApolloIcons.ChevronArrowDownCircle]: '61778',
  [ApolloIcons.ChevronArrowDown]: '61779',
  [ApolloIcons.ChevronArrowLeft]: '61780',
  [ApolloIcons.ChevronArrowRight]: '61781',
  [ApolloIcons.ChevronArrowUp]: '61782',
  [ApolloIcons.CircleDownSmall]: '61783',
  [ApolloIcons.Circle]: '61784',
  [ApolloIcons.ClockEight]: '61785',
  [ApolloIcons.CloudCheck]: '61786',
  [ApolloIcons.CloudComputing]: '61787',
  [ApolloIcons.CloudDataTree]: '61788',
  [ApolloIcons.CloudDownload]: '61789',
  [ApolloIcons.CloudUpload]: '61790',
  [ApolloIcons.Cockpit]: '61791',
  [ApolloIcons.CodeBranch]: '61792',
  [ApolloIcons.Code]: '61793',
  [ApolloIcons.CogCheck]: '61794',
  [ApolloIcons.Cog]: '61795',
  [ApolloIcons.Coins]: '61796',
  [ApolloIcons.ColumnChart]: '61797',
  [ApolloIcons.Combine]: '61798',
  [ApolloIcons.CommandVAlt]: '61799',
  [ApolloIcons.CommandV]: '61800',
  [ApolloIcons.CommentAdd]: '61801',
  [ApolloIcons.CommentSlash]: '61802',
  [ApolloIcons.Comment]: '61803',
  [ApolloIcons.CompanyCheckColored]: '61804',
  [ApolloIcons.CompanyCog]: '61805',
  [ApolloIcons.CompanyDownloadColored]: '61806',
  [ApolloIcons.CompanyErrorColored]: '61807',
  [ApolloIcons.CompanyInfoColored]: '61808',
  [ApolloIcons.CompanyMultiple]: '61809',
  [ApolloIcons.CompanyPlus]: '61810',
  [ApolloIcons.CompanyProgressColored]: '61811',
  [ApolloIcons.CompanyQuestionColored]: '61812',
  [ApolloIcons.CompanyRemove]: '61813',
  [ApolloIcons.CompanyStar]: '61814',
  [ApolloIcons.CompanySwitch]: '61815',
  [ApolloIcons.Company]: '61816',
  [ApolloIcons.ContactRole]: '61817',
  [ApolloIcons.Copy]: '61818',
  [ApolloIcons.CreditCardOff]: '61819',
  [ApolloIcons.CreditCardPlus]: '61820',
  [ApolloIcons.CreditCardSearch]: '61821',
  [ApolloIcons.Cross]: '61822',
  [ApolloIcons.CsvDoc]: '61823',
  [ApolloIcons.Cursor]: '61824',
  [ApolloIcons.CustomFields]: '61825',
  [ApolloIcons.DashboardPlus]: '61826',
  [ApolloIcons.DataHourglass]: '61827',
  [ApolloIcons.DataMonitor]: '61828',
  [ApolloIcons.DataSync]: '61829',
  [ApolloIcons.Desktop]: '61830',
  [ApolloIcons.Diagnose]: '61831',
  [ApolloIcons.Dialpad]: '61832',
  [ApolloIcons.Diamond]: '61833',
  [ApolloIcons.DollarCircle]: '61834',
  [ApolloIcons.DollarSquare]: '61835',
  [ApolloIcons.Dollar]: '61836',
  [ApolloIcons.DonutChart]: '61837',
  [ApolloIcons.DoubleArrowDown]: '61838',
  [ApolloIcons.DoubleArrowLeft]: '61839',
  [ApolloIcons.DoubleArrowRight]: '61840',
  [ApolloIcons.DoubleArrowUp]: '61841',
  [ApolloIcons.Download]: '61842',
  [ApolloIcons.DragAndSort]: '61843',
  [ApolloIcons.DragReorder]: '61844',
  [ApolloIcons.DropdownLarge]: '61845',
  [ApolloIcons.DropdownUpLarge]: '61846',
  [ApolloIcons.DynamicVariable]: '61847',
  [ApolloIcons.Ellipse]: '61848',
  [ApolloIcons.EmailCheck]: '61849',
  [ApolloIcons.EmailError]: '61850',
  [ApolloIcons.EmailInfoColored]: '61851',
  [ApolloIcons.EmailManual]: '61852',
  [ApolloIcons.EmailProgress]: '61853',
  [ApolloIcons.EmailQuestion]: '61854',
  [ApolloIcons.EmailStarOutline]: '61855',
  [ApolloIcons.EmailStatus]: '61856',
  [ApolloIcons.EmojiAnnoyedFace]: '61857',
  [ApolloIcons.EmojiHappyFace]: '61858',
  [ApolloIcons.EmojiSadFace]: '61859',
  [ApolloIcons.EnrichmentRefresh]: '61860',
  [ApolloIcons.Exchange]: '61861',
  [ApolloIcons.ExternalLink]: '61862',
  [ApolloIcons.EyeFilled]: '61863',
  [ApolloIcons.EyeOn]: '61864',
  [ApolloIcons.EyeSlash]: '61865',
  [ApolloIcons.FacebookSquareFilled]: '61866',
  [ApolloIcons.Facebook]: '61867',
  [ApolloIcons.FileAlt]: '61868',
  [ApolloIcons.FileCheck]: '61869',
  [ApolloIcons.FileEdit]: '61870',
  [ApolloIcons.FileLock]: '61871',
  [ApolloIcons.FileUpload]: '61872',
  [ApolloIcons.File]: '61873',
  [ApolloIcons.FilterSlider]: '61874',
  [ApolloIcons.Filter]: '61875',
  [ApolloIcons.Flag]: '61876',
  [ApolloIcons.FolderOpen]: '61877',
  [ApolloIcons.FolderPlus]: '61878',
  [ApolloIcons.Folder]: '61879',
  [ApolloIcons.Forward_10]: '61880',
  [ApolloIcons.Function]: '61881',
  [ApolloIcons.FunnelChart]: '61882',
  [ApolloIcons.GeneratePipeline]: '61883',
  [ApolloIcons.Gift]: '61884',
  [ApolloIcons.Globe]: '61885',
  [ApolloIcons.GoogleChrome]: '61886',
  [ApolloIcons.GoogleLogo]: '61887',
  [ApolloIcons.Google]: '61888',
  [ApolloIcons.GraduationCap]: '61889',
  [ApolloIcons.Greenhouse]: '61890',
  [ApolloIcons.GridPlus]: '61891',
  [ApolloIcons.Grid]: '61892',
  [ApolloIcons.GrowthDown]: '61893',
  [ApolloIcons.GrowthUp]: '61894',
  [ApolloIcons.HandWaving]: '61895',
  [ApolloIcons.HeartbeatStatus]: '61896',
  [ApolloIcons.Heatmap]: '61897',
  [ApolloIcons.HideArrowLeft]: '61898',
  [ApolloIcons.HideArrowRight]: '61899',
  [ApolloIcons.HierarchyChild]: '61900',
  [ApolloIcons.HierarchyParentChild]: '61901',
  [ApolloIcons.HierarchyParent]: '61902',
  [ApolloIcons.History]: '61903',
  [ApolloIcons.Home]: '61904',
  [ApolloIcons.HubspotFailed]: '61905',
  [ApolloIcons.Hubspot]: '61906',
  [ApolloIcons.ImagePlaceholder]: '61907',
  [ApolloIcons.Industry]: '61908',
  [ApolloIcons.InfoCircle]: '61909',
  [ApolloIcons.IntentData]: '61910',
  [ApolloIcons.Italic]: '61911',
  [ApolloIcons.KeyInclined]: '61912',
  [ApolloIcons.Language]: '61913',
  [ApolloIcons.Languages]: '61914',
  [ApolloIcons.LaptopComputer]: '61915',
  [ApolloIcons.LayerGroup]: '61916',
  [ApolloIcons.Layout]: '61917',
  [ApolloIcons.LearnMore]: '61918',
  [ApolloIcons.LifeBuoy]: '61919',
  [ApolloIcons.Lightbulb]: '61920',
  [ApolloIcons.Lightning]: '61921',
  [ApolloIcons.LinkOff]: '61922',
  [ApolloIcons.LinkPlus]: '61923',
  [ApolloIcons.LinkSquareFilled]: '61924',
  [ApolloIcons.Link]: '61925',
  [ApolloIcons.LinkedinSquareFilled]: '61926',
  [ApolloIcons.Linkedin]: '61927',
  [ApolloIcons.ListCheck]: '61928',
  [ApolloIcons.ListChecked]: '61929',
  [ApolloIcons.ListDeselect]: '61930',
  [ApolloIcons.ListOrdered]: '61931',
  [ApolloIcons.ListPlus]: '61932',
  [ApolloIcons.ListTimes]: '61933',
  [ApolloIcons.ListUi]: '61934',
  [ApolloIcons.Loading]: '61935',
  [ApolloIcons.Location]: '61936',
  [ApolloIcons.LogIn]: '61937',
  [ApolloIcons.LogOut]: '61938',
  [ApolloIcons.MagicRewriting]: '61939',
  [ApolloIcons.MagicWand]: '61940',
  [ApolloIcons.Magnet]: '61941',
  [ApolloIcons.MailBlock]: '61942',
  [ApolloIcons.MailDownloadColored]: '61943',
  [ApolloIcons.MailDownload]: '61944',
  [ApolloIcons.MailEnvelopeQuestion]: '61945',
  [ApolloIcons.MailFilled]: '61946',
  [ApolloIcons.MailInfo]: '61947',
  [ApolloIcons.MailLock]: '61948',
  [ApolloIcons.MailMinusColored]: '61949',
  [ApolloIcons.MailMinus]: '61950',
  [ApolloIcons.MailOpen]: '61951',
  [ApolloIcons.MailPlusColored]: '61952',
  [ApolloIcons.MailSearchColored_1]: '61953',
  [ApolloIcons.MailSearchColored]: '61954',
  [ApolloIcons.MailStarColored]: '61955',
  [ApolloIcons.MailTimesColored]: '61956',
  [ApolloIcons.MailTimes]: '61957',
  [ApolloIcons.MailUploadColored]: '61958',
  [ApolloIcons.MailUpload]: '61959',
  [ApolloIcons.Mail]: '61960',
  [ApolloIcons.Mailgun]: '61961',
  [ApolloIcons.Mails]: '61962',
  [ApolloIcons.MastercardLogo]: '61963',
  [ApolloIcons.MaximizeScreen]: '61964',
  [ApolloIcons.Maximize]: '61965',
  [ApolloIcons.Medal]: '61966',
  [ApolloIcons.MeetingSource]: '61967',
  [ApolloIcons.Menu]: '61968',
  [ApolloIcons.MicOn]: '61969',
  [ApolloIcons.MicSlash]: '61970',
  [ApolloIcons.MinimizeScreen]: '61971',
  [ApolloIcons.Minimize]: '61972',
  [ApolloIcons.MinusCircle]: '61973',
  [ApolloIcons.Minus]: '61974',
  [ApolloIcons.Mobile]: '61975',
  [ApolloIcons.MoneyBag]: '61976',
  [ApolloIcons.MoneyStack]: '61977',
  [ApolloIcons.Moon]: '61978',
  [ApolloIcons.MoreH]: '61979',
  [ApolloIcons.MousePointer]: '61980',
  [ApolloIcons.Move]: '61981',
  [ApolloIcons.MultiCredit]: '61982',
  [ApolloIcons.MultiLineText]: '61983',
  [ApolloIcons.MultiSplit]: '61984',
  [ApolloIcons.Mute]: '61985',
  [ApolloIcons.NestedCircles]: '61986',
  [ApolloIcons.NewsFilter]: '61987',
  [ApolloIcons.NoVideoCamera]: '61988',
  [ApolloIcons.NotesCheck]: '61989',
  [ApolloIcons.NotesPlus]: '61990',
  [ApolloIcons.Notes]: '61991',
  [ApolloIcons.Number]: '61992',
  [ApolloIcons.OrderedList]: '61993',
  [ApolloIcons.Outreach]: '61994',
  [ApolloIcons.Padlock]: '61995',
  [ApolloIcons.Paperclip]: '61996',
  [ApolloIcons.PauseCircle]: '61997',
  [ApolloIcons.Pause]: '61998',
  [ApolloIcons.Pen]: '61999',
  [ApolloIcons.PersistentChecklist]: '62000',
  [ApolloIcons.PhoneCheck]: '62001',
  [ApolloIcons.PhoneDecline]: '62002',
  [ApolloIcons.PhoneDownloadColored]: '62003',
  [ApolloIcons.PhoneDownload]: '62004',
  [ApolloIcons.PhoneError]: '62005',
  [ApolloIcons.PhoneIn]: '62006',
  [ApolloIcons.PhoneInfoColored]: '62007',
  [ApolloIcons.PhoneOut]: '62008',
  [ApolloIcons.PhoneProgress]: '62009',
  [ApolloIcons.PhoneQuestionColored]: '62010',
  [ApolloIcons.PhoneTimes]: '62011',
  [ApolloIcons.Phone]: '62012',
  [ApolloIcons.PictureInPicture]: '62013',
  [ApolloIcons.Picture]: '62014',
  [ApolloIcons.PieChart_2]: '62015',
  [ApolloIcons.PieChart]: '62016',
  [ApolloIcons.PipedriveFailed]: '62017',
  [ApolloIcons.Pipedrive]: '62018',
  [ApolloIcons.Pipeline]: '62019',
  [ApolloIcons.PlayCircle]: '62020',
  [ApolloIcons.Play]: '62021',
  [ApolloIcons.PlayerInPlayer]: '62022',
  [ApolloIcons.PlusCircleFilled]: '62023',
  [ApolloIcons.PlusCircle]: '62024',
  [ApolloIcons.Plus]: '62025',
  [ApolloIcons.Pointer]: '62026',
  [ApolloIcons.PowerupAi]: '62027',
  [ApolloIcons.PowerupIcon]: '62028',
  [ApolloIcons.Puzzle]: '62029',
  [ApolloIcons.QuestionCircleFill]: '62030',
  [ApolloIcons.QuestionCircle]: '62031',
  [ApolloIcons.Record]: '62032',
  [ApolloIcons.Redo]: '62033',
  [ApolloIcons.Refresh]: '62034',
  [ApolloIcons.RemoveFromPlaylist]: '62035',
  [ApolloIcons.Reorder]: '62036',
  [ApolloIcons.Repeat]: '62037',
  [ApolloIcons.Reply]: '62038',
  [ApolloIcons.Rewind_10]: '62039',
  [ApolloIcons.Rocket]: '62040',
  [ApolloIcons.RotateLeft]: '62041',
  [ApolloIcons.RotateRight]: '62042',
  [ApolloIcons.Salesforce]: '62043',
  [ApolloIcons.Salesloft]: '62044',
  [ApolloIcons.Scissors]: '62045',
  [ApolloIcons.Score]: '62046',
  [ApolloIcons.SearchChecked]: '62047',
  [ApolloIcons.SearchCross]: '62048',
  [ApolloIcons.SearchUser]: '62049',
  [ApolloIcons.Search]: '62050',
  [ApolloIcons.SendInclined]: '62051',
  [ApolloIcons.Sendgrid]: '62052',
  [ApolloIcons.SequenceCheck]: '62053',
  [ApolloIcons.SequenceError]: '62054',
  [ApolloIcons.SequenceQuestion]: '62055',
  [ApolloIcons.SequenceSendingProgress]: '62056',
  [ApolloIcons.Sequence]: '62057',
  [ApolloIcons.ServerDatabase]: '62058',
  [ApolloIcons.Share]: '62059',
  [ApolloIcons.Shield]: '62060',
  [ApolloIcons.SicCode]: '62061',
  [ApolloIcons.SidebarCollapse]: '62062',
  [ApolloIcons.SidebarExpand]: '62063',
  [ApolloIcons.Signal_1]: '62064',
  [ApolloIcons.Signal_2]: '62065',
  [ApolloIcons.Signal_3]: '62066',
  [ApolloIcons.Signals]: '62067',
  [ApolloIcons.SingleCredit]: '62068',
  [ApolloIcons.SkipForward]: '62069',
  [ApolloIcons.SlidersSettings]: '62070',
  [ApolloIcons.SortAccending]: '62071',
  [ApolloIcons.SortAscending]: '62072',
  [ApolloIcons.SortDecending]: '62073',
  [ApolloIcons.SortDescending]: '62074',
  [ApolloIcons.SpotCreateAlert]: '62075',
  [ApolloIcons.StarEmpty]: '62076',
  [ApolloIcons.StarFilled]: '62077',
  [ApolloIcons.StatusActivity]: '62078',
  [ApolloIcons.StopCircle]: '62079',
  [ApolloIcons.Stopwatch]: '62080',
  [ApolloIcons.Strikethrough]: '62081',
  [ApolloIcons.Sum]: '62082',
  [ApolloIcons.Support]: '62083',
  [ApolloIcons.Syncsalesforce]: '62084',
  [ApolloIcons.TableFile]: '62085',
  [ApolloIcons.TagUser]: '62086',
  [ApolloIcons.Tag]: '62087',
  [ApolloIcons.Talking]: '62088',
  [ApolloIcons.TextTitle]: '62089',
  [ApolloIcons.ThumbsDown]: '62090',
  [ApolloIcons.ThumbsUpFilled]: '62091',
  [ApolloIcons.ThumbsUp]: '62092',
  [ApolloIcons.Thumbtack]: '62093',
  [ApolloIcons.Tiles]: '62094',
  [ApolloIcons.TimesCircleFilled]: '62095',
  [ApolloIcons.TimesCircle]: '62096',
  [ApolloIcons.Times]: '62097',
  [ApolloIcons.ToggleList]: '62098',
  [ApolloIcons.Token]: '62099',
  [ApolloIcons.TrafficLight]: '62100',
  [ApolloIcons.Trash]: '62101',
  [ApolloIcons.TrelloBoard]: '62102',
  [ApolloIcons.Trigger]: '62103',
  [ApolloIcons.TrophyFilled]: '62104',
  [ApolloIcons.TrophySales]: '62105',
  [ApolloIcons.Trophy]: '62106',
  [ApolloIcons.TrueFalse]: '62107',
  [ApolloIcons.TwitterSquareFilled]: '62108',
  [ApolloIcons.Twitter]: '62109',
  [ApolloIcons.Underline]: '62110',
  [ApolloIcons.Undo]: '62111',
  [ApolloIcons.Unflag]: '62112',
  [ApolloIcons.UpDownCaret]: '62113',
  [ApolloIcons.Upload]: '62114',
  [ApolloIcons.UserBubble]: '62115',
  [ApolloIcons.UserCheck]: '62116',
  [ApolloIcons.UserCircle]: '62117',
  [ApolloIcons.UserDownloadColored]: '62118',
  [ApolloIcons.UserExclamation]: '62119',
  [ApolloIcons.UserMinus]: '62120',
  [ApolloIcons.UserPlus]: '62121',
  [ApolloIcons.UserQuestion]: '62122',
  [ApolloIcons.UserRefresh]: '62123',
  [ApolloIcons.UserSquare]: '62124',
  [ApolloIcons.UserStar]: '62125',
  [ApolloIcons.UserTalking]: '62126',
  [ApolloIcons.UserTimes]: '62127',
  [ApolloIcons.UserUpload]: '62128',
  [ApolloIcons.User]: '62129',
  [ApolloIcons.Users]: '62130',
  [ApolloIcons.Vector]: '62131',
  [ApolloIcons.VideoCameraOff]: '62132',
  [ApolloIcons.VideoCamera]: '62133',
  [ApolloIcons.VideoPause]: '62134',
  [ApolloIcons.VideoPlay]: '62135',
  [ApolloIcons.ViewGridList]: '62136',
  [ApolloIcons.ViewList]: '62137',
  [ApolloIcons.Volume]: '62138',
  [ApolloIcons.Wallet]: '62139',
  [ApolloIcons.Waterfall]: '62140',
  [ApolloIcons.WebsiteVisitors]: '62141',
  [ApolloIcons.WindowEqual]: '62142',
  [ApolloIcons.WindowMaximize]: '62143',
  [ApolloIcons.WindowMinimize]: '62144',
  [ApolloIcons.World]: '62145',
  [ApolloIcons.Wrench]: '62146',
  [ApolloIcons.X]: '62147',
  [ApolloIcons.Yelp]: '62148',
};
